import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { isArray, isEmpty, isNull } from "lodash";
import { ArrowForward } from "@mui/icons-material";
import { styled } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import AboutJob from "../../../Components/Jobs/AboutJob";
import EmploymentType from "../../../Components/Jobs/EmploymentType";
import VideoRole from "../../../Components/Jobs/VideoRole";
import JobLocation from "../../../Components/Jobs/JobLocation";
import DescriptionRole from "../../../Components/Jobs/DescriptionRole";
import Skills from "../../../Components/Profile/Skills";
import Languages from "../../../Components/Profile/Languages";
import { Setting } from "../../../Utils/Setting";
import { getAPIProgressData, getApiData } from "../../../Utils/APIHelper";
import { color } from "../../../Config/theme";
import { newJobPostArray } from "../../../Config/Static_Data";
import useStyles from "./styles";
import { toast } from "react-toastify";

export default function PostJob() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = !isNull(location.state);
  const [pageLoad, setPageLoad] = useState(false);

  const { token } = useSelector((state) => state.auth);

  const header = { Authorization: `Bearer ${token}` };
  const [loader, setLoader] = useState(false);
  const [jobPostData, setJobPostData] = useState({});
  const [editJobData, setEditJobData] = useState({});

  const isJobAdded = !isEmpty(jobPostData);

  const [aboutJob, setAboutjob] = useState({});
  const [employmentType, setEmploymentType] = useState({});
  const [videoRole, setVideoRole] = useState({});
  const [jobLocation, setJobLocation] = useState({});
  const [descriptionRole, setDescriptionRole] = useState({});
  const [skills, setSkills] = useState({});
  const [language, setLanguage] = useState([]);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const CustomSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: "58px !important",
    height: "36px !important",
    padding: "6px !important",
    "& .MuiSwitch-switchBase": {
      padding: 1,
      left: 5,
      top: 4,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(21px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: color.primary,
          opacity: 1,
          border: "none",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: color.primary,
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      color: "#d4dbe5",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      border: "1px solid #d4dbe5",
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
  }));

  useEffect(() => {
    if (isEdit) {
      getJobDetail();
    }
    document.title = Setting.page_name.EMPLOYER_POST_JOB;
    window.scrollTo(0, 0);
  }, []);

  // this function for get job detail
  async function getJobDetail() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.employerJobDetail}?job_post_id=${location.state.jobId}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      console.log("response ====>>>>>", response);
      if (response.status) {
        setEditJobData(response?.data);
        setPageLoad(false);
      }
    } catch (error) {
      console.log("error ===>>", error);

      setPageLoad(false);
    }
  }

  // this function for scroll
  function redirect(type) {
    if (document) {
      let section = null;

      if (type === "job") {
        section = document.querySelector("#job");
      } else if (type === "emptype") {
        section = document.querySelector("#emptype");
      } else if (type === "role") {
        section = document.querySelector("#role");
      } else if (type === "salary") {
        section = document.querySelector("#salary");
      } else if (type === "datetime") {
        section = document.querySelector("#datetime");
      } else if (type === "joblocation") {
        section = document.querySelector("#joblocation");
      } else if (type === "descrole") {
        section = document.querySelector("#descrole");
      } else if (type === "skills") {
        section = document.querySelector("#skills");
      } else if (type === "language") {
        section = document.querySelector("#language");
      }

      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }

  function checkValidation() {
    let valid = true;

    if (isEmpty(aboutJob)) {
      valid = false;
      toast.error("Please complete about your job");
    } else if (isEmpty(employmentType)) {
      valid = false;
      toast.error("Please complete employement type");
    }
    // else if (isEmpty(videoRole)) {
    //   valid = false;
    //   toast.error("Please complete video for your role");
    // }
    else if (isEmpty(jobLocation)) {
      valid = false;
      toast.error("Please complete job location");
    } else if (isEmpty(descriptionRole)) {
      valid = false;
      toast.error("Please complete description of role");
    } else if (isEmpty(skills)) {
      valid = false;
      toast.error("Please complete skills");
    } else if (isEmpty(language)) {
      valid = false;
      toast.error("Please complete language required");
    }

    if (valid) {
      addNewJob();
    }
  }

  async function addNewJob() {
    setLoader(true);
    const datet = moment(aboutJob?.closeDate).format("yyyy-MM-DD");
    const addjobData = {
      "JobPost[sector_id]": aboutJob?.sector,
      "JobPost[job_id]": aboutJob?.job_role_id,
      "JobPost[vacancies]": aboutJob?.vacancies,
      "JobPost[title]": aboutJob?.jobTitle,
      "JobPost[expired_date]": datet,
      "JobPost[role_description]": descriptionRole,
      "JobPost[job_role_video]": videoRole?.videoFile,
      "JobPost[job_role_type]": employmentType?.jobType,
      "JobPost[employement_description]": employmentType?.data,
      "JobPost[is_remote]": jobLocation?.remoteOnly ? 1 : 0,
    };

    if (isEdit) {
      addjobData["JobPost[id]"] = editJobData?.id;
    }

    if (!jobLocation?.remoteOnly) {
      addjobData["JobPost[lat]"] = "12000";
      addjobData["JobPost[long]"] = "23";
    }

    if (employmentType?.jobType === "Full-Time") {
      addjobData["JobPost[charge_per_year]"] = employmentType?.perYear;
    } else if (employmentType?.jobType === "Part-Time") {
      addjobData["JobPost[charge_per_hour]"] = employmentType?.perHour;
    } else if (employmentType?.jobType === "Both") {
      addjobData["JobPost[charge_per_year]"] = employmentType?.bothPerYear;
      addjobData["JobPost[charge_per_hour]"] = employmentType?.bothPerHour;
    }

    skills.map((item, index) => {
      addjobData[`JobPost[skills][${index}]`] = item.value;
    });

    language.map((item, index) => {
      addjobData[`language[${item?.value}]`] = item?.preference;
    });
    console.log("addjobData ====>>>>>", addjobData);

    try {
      let endPoint = Setting.endpoints.employerAddJob;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        addjobData,
        header
      );
      if (response?.status) {
        toast.success(response?.message);
        setLoader(false);
        if (isEdit) {
          navigate(-1);
        } else {
          setJobPostData(response?.data);
        }
      } else {
        //display error msg here
        toast.error(response?.message);
        setLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setLoader(false);
    }
  }

  function renderQuiz() {
    return (
      <Grid
        item
        xs={12}
        className={[
          classes.shadowViewStyle,
          classes.paddingStyle,
          classes.marginDiv,
        ]}
      >
        <Typography className={classes.sectionTitle}>
          Fill a quiz to get match your candidate
        </Typography>
        <Typography className={classes.sectionDesc}>
          To show your profile in candidate list you need to complete the skills
          quiz.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForward />}
          style={{ marginTop: 10 }}
          onClick={() => {
            navigate("/company/question", {
              state: {
                jobPostData: isEdit ? editJobData : jobPostData,
                addedJob: isEdit ? true : false,
              },
            });
          }}
        >
          {isEdit ? "Edit skill quiz" : "START SKILL QUIZ"}
        </Button>
      </Grid>
    );
  }

  function renderRemain() {
    return (
      <>
        <AboutJob
          setData={(data) => {
            setAboutjob(data);
          }}
          data={editJobData}
        />
        <EmploymentType
          setData={(data) => {
            setEmploymentType(data);
          }}
          data={editJobData}
        />
        <VideoRole
          setData={(data) => {
            setVideoRole(data);
          }}
          data={editJobData}
        />
        <JobLocation
          setData={(data) => {
            setJobLocation(data);
          }}
          data={editJobData}
        />
        <DescriptionRole
          setData={(data) => {
            setDescriptionRole(data);
          }}
          data={editJobData}
        />
        <Skills
          setData={(data) => {
            setSkills(data);
          }}
          data={aboutJob}
          data2={editJobData}
          employer
        />
        <Languages
          from="postjob"
          setData={(data) => {
            setLanguage(data);
          }}
          data2={editJobData}
          employer
        />
        <Button
          variant="contained"
          endIcon={loader ? null : <ArrowForward />}
          style={{ width: sm ? "100%" : 300 }}
          onClick={() => {
            checkValidation();
          }}
        >
          {loader ? (
            <CircularProgress style={{ color: "#fff" }} size={26} />
          ) : isEdit ? (
            "Edit"
          ) : (
            "SAVE"
          )}
        </Button>
      </>
    );
  }

  return (
    <Grid container className={classes.container}>
      {pageLoad ? (
        <div className={classes.loaderView}>
          <CircularProgress size={50} />
        </div>
      ) : (
        <>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.shadowViewStyle}>
              {isArray(newJobPostArray) &&
                newJobPostArray.length > 0 &&
                newJobPostArray.map((item, index) => {
                  const lIndex = newJobPostArray.length - 1;
                  return (
                    <div
                      key={`${item.title}_index`}
                      className={classes.singleViewStyle}
                      onClick={() => redirect(item.key)}
                      style={
                        item?.type === "switch"
                          ? {
                              borderBottom:
                                lIndex === index ? "none" : "1px solid #34567b",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }
                          : {
                              borderBottom:
                                lIndex === index ? "none" : "1px solid #34567b",
                              alignItems: "center",
                            }
                      }
                    >
                      {item?.icon && (
                        <img
                          src={item.icon}
                          alt="logo"
                          style={{
                            height: 25,
                            width: 25,
                            marginRight: 15,
                          }}
                        />
                      )}
                      <Typography
                        style={{
                          color: color.primary,
                          fontFamily: "PoppinsRegular",
                        }}
                      >
                        {item?.title || ""}
                      </Typography>
                      {item.type === "switch" && (
                        <FormControlLabel
                          control={<CustomSwitch defaultChecked />}
                          label=""
                          style={{ marginRight: 0 }}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Container className={classes.detailContainer}>
              {isEdit ? (
                <>
                  {renderQuiz()}
                  {renderRemain()}
                </>
              ) : isJobAdded ? (
                renderQuiz()
              ) : (
                renderRemain()
              )}
            </Container>
          </Grid>
        </>
      )}
    </Grid>
  );
}
