/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Grid,
  Box,
  Button,
  Checkbox,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import MChange from "../../../Components/Modal/ChangePassModal";
import { Setting } from "../../../Utils/Setting";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import authActions from "../../../Redux/reducers/auth/actions";
import { getAPIProgressData, getApiData } from "../../../Utils/APIHelper";
import { empCommunicationData } from "../../../Config/Static_Data";
import useStyles from "./styles";

const { setCommunication } = authActions;

export default function Account() {
  const classes = useStyles();
  const { token, candidateProfile, communicationPreference, userdata } =
    useSelector((state) => state.auth);
  const isSocial = userdata?.is_social ? true : false;
  const [communicationArr, setCommunicationArr] =
    useState(empCommunicationData);
  const [chnagePassModal, setChnagePassModal] = useState(false);

  const header = { Authorization: `Bearer ${token}` };
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    document.title = Setting.page_name.CANDIDATE_ACCOUNT;
  }, []);

  useEffect(() => {
    setCheckBox();
  }, [communicationPreference]);

  function err(message) {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      progress: undefined,
    });
  }

  function setCheckBox() {
    const dummy_arr = [...communicationArr];

    dummy_arr.map((ii) => {
      if (ii?.id === 2) {
        ii.email =
          Number(communicationPreference?.profile_reminder_email) === 1
            ? true
            : false;
        ii.phone =
          Number(communicationPreference?.profile_reminder_push) === 1
            ? true
            : false;
      } else if (ii?.id === 3) {
        ii.email =
          Number(communicationPreference?.news_about_email) === 1
            ? true
            : false;
        ii.phone =
          Number(communicationPreference?.news_about_push) === 1 ? true : false;
      }
    });
    setCommunicationArr(dummy_arr);
  }

  async function changeCommumication(type, value) {
    try {
      let endPoint = `${
        Setting.endpoints.employeeCommunication
      }?type=${type}&value=${value ? 1 : 0}`;
      const response = await getApiData(endPoint, "GET", null, header);
      if (response && response.status && response.status === true) {
        toast.success(response?.message);
        dispatch(setCommunication(response?.data));
      } else {
        console.log("err==>>");
        err(response?.message);
      }
    } catch (err) {
      console.log("Catch Part", err);
      err("Something_went_wrong");
    }
  }

  function clickCheckBox(data, str) {
    const dummy_arr = [...communicationArr];
    {
      dummy_arr.map((ii) => {
        if (ii?.id === data?.id) {
          if (str === "e") {
            ii.email = !ii.email;
            if (ii?.id === 2) {
              changeCommumication("profile_reminder_email", ii.email);
            } else if (ii?.id === 3) {
              changeCommumication("news_about_email", ii.email);
            }
          } else {
            ii.phone = !ii.phone;
            if (ii?.id === 2) {
              changeCommumication("profile_reminder_push", ii.phone);
            } else if (ii?.id === 3) {
              changeCommumication("news_about_push", ii.phone);
            }
          }
        } else {
          console.log("err");
        }
      });
    }
    setCommunicationArr(dummy_arr);
  }

  async function ChangePassword(data) {
    setLoader(true);
    const chnagePassData = {
      "ChangePasswordForm[password]": data?.newPass,
      "ChangePasswordForm[cpassword]": data?.confirmPass,
    };

    try {
      let endPoint = Setting.endpoints.change_password;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        chnagePassData,
        header
      );
      if (response?.status) {
        setLoader(false);
        toast.success(response?.message);
        setChnagePassModal(false);
      } else {
        //display error msg here
        err(response?.message);
        setLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setLoader(false);
    }
  }

  function renderFirstCard() {
    return (
      <Grid container className={classes.paperstyle}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography className="mediumsemiboldfont primarycolor">
            Company Email Address
          </Typography>
          {/* <div
            className={classes.editBtnStyle}
            // onClick={() => setModal(true)}
          >
            <img alt="error" src={Images.edit} style={{ width: 14 }} />
          </div> */}
        </Grid>
        <Typography
          className="lightsilvercolor regulerfont"
          style={{ marginTop: 10 }}
        >
          {candidateProfile?.email}
        </Typography>
      </Grid>
    );
  }

  function renderSecond() {
    return (
      <Grid container className={classes.paperstyle} direction="column">
        <Typography className="mediumsemiboldfont primarycolor">
          Password
        </Typography>
        <Typography
          className="lightsilvercolor largerregulerfont"
          style={{ marginTop: 10 }}
        >
          ********
        </Typography>
        <Button
          variant="contained"
          endIcon={<ArrowForward />}
          onClick={() => {
            setChnagePassModal(true);
          }}
          style={{ maxWidth: sm ? "100%" : 300 }}
        >
          change password
        </Button>
      </Grid>
    );
  }

  function renderThird() {
    return (
      <Grid container className={classes.paperstyle} direction="column">
        <Typography className="mediumsemiboldfont primarycolor">
          Select the ways in which you're happy for us to contact you about the
          following things.
        </Typography>
        <Typography
          className="lightsilvercolor regulerfont"
          style={{ marginBottom: 20 }}
        >
          Please note, it may take a few hours after you change your preferences
          until our records are fully updated.
        </Typography>
        {_.isArray(communicationArr) && !_.isEmpty(communicationArr)
          ? communicationArr.map((item, index) => {
              if (item?.id === 2) {
                return null;
              }
              return (
                <Grid
                  container
                  alignItems="center"
                  style={{ marginTop: 20 }}
                  key={index}
                >
                  <Grid item xs={12} sm={8} md={7}>
                    <Typography className="mediumfont">{item?.name}</Typography>
                    <Typography
                      className="regulerfont lightsilvercolor"
                      style={{ marginTop: sm ? 10 : 0 }}
                    >
                      {item?.detail}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    sm={4}
                    md={5}
                    alignItems="center"
                    style={{ marginTop: sm ? 20 : 0 }}
                    justifyContent={sm ? "unset" : "space-evenly"}
                  >
                    <div style={{ display: "flex", width: "50%" }}>
                      <Checkbox
                        checked={item?.email}
                        disabled={item?.disable}
                        onChange={() => {
                          clickCheckBox(item, "e");
                        }}
                      />
                      <Typography
                        className="regulerfont"
                        style={{
                          color: item?.disable ? "#575757" : "#000000",
                        }}
                      >
                        Email
                      </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        checked={item?.phone}
                        disabled={item?.disable}
                        onChange={() => {
                          clickCheckBox(item, "p");
                        }}
                      />
                      <Typography
                        className="regulerfont"
                        style={{
                          color: item?.disable ? "#575757" : "#000000",
                        }}
                      >
                        Phone
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              );
            })
          : null}
      </Grid>
    );
  }

  function renderMain() {
    return (
      <Box sx={{ flexGrow: 1, paddingTop: 4 }}>
        <Grid container>
          <Typography className="fontsize2" style={{ marginBottom: 20 }}>
            Company details
          </Typography>
          {renderFirstCard()}
          {isSocial ? null : renderSecond()}
          <Typography
            className="fontsize2"
            style={{ marginBottom: 20, marginTop: 40 }}
          >
            Communication preferences
          </Typography>
          {renderThird()}
        </Grid>
        <MChange
          visible={chnagePassModal}
          handleModal={() => {
            setChnagePassModal(false);
          }}
          saveClick={(data) => {
            ChangePassword(data);
          }}
          loader={loader}
        />
      </Box>
    );
  }

  return <div>{renderMain()}</div>;
}
