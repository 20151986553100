import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { isEmpty } from "lodash";
import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import { htmlContent } from "../../../Config/Static_Data";
import useStyles from "../styles";
import MOverview from "../Modal/MOverview";

function COverview(props) {
  const {
    isCandidate = false,
    from = "",
    overview = "",
    updateData = () => null,
  } = props;
  const [overviewModal, setOverviewModal] = useState(false);
  const classes = useStyles();

  if (from === "" && isEmpty(overview)) {
    return null;
  }

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div
        id="coverview"
        className={`${classes.splitViewStyle} ${classes.mb20}`}
      >
        <Typography className={classes.sectionTitle}>Overview</Typography>
        {isCandidate || from === "preview" ? null : overview !== "" ? (
          <div
            className={classes.editBtnStyle}
            onClick={() => {
              setOverviewModal(true);
            }}
          >
            <img src={Images.edit} style={{ width: 14 }} />
          </div>
        ) : (
          <div
            onClick={() => {
              setOverviewModal(true);
            }}
          >
            <AddCircle color={color.primary} className={classes.addIcon} />
          </div>
        )}
      </div>
      {isCandidate || from === "" || from === "preview" ? (
        <>
          <div
            className={htmlContent().style}
            dangerouslySetInnerHTML={{
              __html: overview || "",
            }}
          />
        </>
      ) : (
        <Typography className={classes.sectionDesc}>Add overview</Typography>
      )}
      <MOverview
        visible={overviewModal}
        overviewDetail={overview}
        handleModal={(data, type) => {
          setOverviewModal(false);
          if (type === "update") {
            updateData(data, "company_overview");
          }
        }}
      />
    </Grid>
  );
}
export default COverview;
