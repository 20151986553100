import React, { useEffect, useState, useRef } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import useStyles from "./styles";
import Images from "../../Config/Images";

function LinkedinLoginButton(props) {
  const { fullWidth = false } = props;
  const classes = useStyles();

  function linkdinResponse(response) {
    // const platForm = isAndroid ? "Android" : isIOS ? "Ios" : "Android";
    console.log("responseGoogle ===============>", response);
    // console.log("platForm ===============>", platForm);
  }

  return (
    <LinkedIn
      clientId="86hrc64ftfle8g"
      redirectUri="https://expertise.teamgroovy.com/"
      onSuccess={(code) => {
        console.log("code ===", code);
        linkdinResponse();
      }}
      onError={(error) => {
        console.log("onError ===============>", error);
      }}
      children={(renderProps) => (
        <Grid
          container
          className={`${classes.button_main_con} ${
            fullWidth ? classes.fullWidthDiv : ""
          }`}
          onClick={() => {
            console.log("<=============== Linkdin On Click ===============>");
            renderProps.linkedInLogin();
          }}
        >
          <img
            alt="linkedIn_logo"
            src={Images.linkedin_logo}
            style={{ width: "30px", objectFit: "contain", marginRight: 10 }}
          />
          <Typography className={classes.button_name}>
            Sign up with LinkedIn
          </Typography>
        </Grid>
      )}
    />
  );
}

LinkedinLoginButton.propTypes = {};

LinkedinLoginButton.defaultProps = {};

export default LinkedinLoginButton;
