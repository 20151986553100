import React, { useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Link,
  CircularProgress,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ConfirmDialog from "../../ConfirmDialog";
import { Setting } from "../../../Utils/Setting";
import { getApiData } from "../../../Utils/APIHelper";
import useStyles from "../styles";
import { isNull } from "lodash";

function MyJob(props) {
  const {
    data = {},
    jobType = false,
    updateList = () => null,
    isHidden = {},
  } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [btnLoad, setBtnLoad] = useState(false);
  const [jobBtn, setJobBtn] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const isFull = isNull(data?.charge_per_hour);
  const isPart = isNull(data?.charge_per_year);

  // this function redirects to job page for edit detail
  function editJob(id) {
    navigate("/employer/jobs/post-job", { state: { jobId: id } });
    window.scrollTo(0, 0);
  }

  // this function redirects to matching jobs list page
  function redirectToPage(id) {
    navigate("/employer/jobs/matching-job", { state: { jobId: id } });
    window.scrollTo(0, 0);
  }

  // this function for update job status
  async function changeStatus(id, status, bool = false) {
    if (status === 2) {
      setJobBtn(true);
    } else {
      setBtnLoad(true);
    }
    try {
      const response = await getApiData(
        `${Setting.endpoints.employerChangeJobStatus}?job_post_id=${id}&job_status=${status}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        toast.success(response.message);
        if (bool) {
          setConfirmDialog(false);
        }
        updateList(status === 1 ? 0 : status === 2 ? null : 1);
      } else {
        toast.error(response.message);
      }
      setBtnLoad(false);
      setJobBtn(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setBtnLoad(false);
      setJobBtn(false);
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid xs={12} sm={9}>
        <Grid container>
          <Grid xs={12} style={{ marginBottom: 15 }}>
            <Typography className={classes.sectionTitle}>
              {data?.title || ""}
            </Typography>
            <Typography style={{ fontFamily: "PoppinsMedium" }}>
              {data?.sector || ""}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
            className={`${classes.singleDetail} ${classes.mb20}`}
          >
            <Typography className={classes.sectionDesc}>Salary</Typography>
            {isFull ? (
              <Typography className={classes.sectionSubDetail}>
                {`Full-Time:- ${data?.charge_per_year}` || "0"}
              </Typography>
            ) : isPart ? (
              <Typography className={classes.sectionSubDetail}>
                {`Part-Time:- ${data?.charge_per_hour}` || "0"}
              </Typography>
            ) : (
              <>
                <Typography className={classes.sectionSubDetail}>
                  {`Full-Time:- ${data?.charge_per_year}`}
                </Typography>
                <Typography className={classes.sectionSubDetail}>
                  {`Part-Time:- ${data?.charge_per_hour}`}
                </Typography>
              </>
            )}
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={9}
            className={`${classes.singleDetail} ${classes.mb20}`}
          >
            <Typography className={classes.sectionDesc}>Start</Typography>
            <Typography className={classes.sectionSubDetail}>
              {data?.start || "-"}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={3}
            className={`${classes.singleDetail} ${classes.mb20}`}
          >
            <Typography className={classes.sectionDesc}>Vacancies</Typography>
            <Typography className={classes.sectionSubDetail}>
              {data?.vacancies || ""}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={3}
            className={`${classes.singleDetail} ${classes.mb20}`}
          >
            <Typography className={classes.sectionDesc}>Posted on</Typography>
            <Typography className={classes.sectionSubDetail}>
              {data?.posted_on || ""}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={3}
            className={`${classes.singleDetail} ${classes.mb20}`}
          >
            <Typography className={classes.sectionDesc}>
              Last day to apply
            </Typography>
            <Typography className={classes.sectionSubDetail}>
              {data?.last_day_to_apply || ""}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} sm={3}>
        {jobType && (
          <Button
            variant={btnLoad ? "outlined" : "contained"}
            color="primary"
            endIcon={btnLoad ? null : <ArrowForward />}
            fullWidth
            style={{ marginBottom: 15 }}
            onClick={() => changeStatus(data?.id, 1)}
            disabled={btnLoad || jobBtn}
          >
            {btnLoad ? <CircularProgress size={28} /> : "Live"}
          </Button>
        )}
        {!jobType && (
          <Button
            variant="outlined"
            color="primary"
            endIcon={btnLoad ? null : <ArrowForward />}
            fullWidth
            style={{ marginBottom: 15 }}
            onClick={() => changeStatus(data?.id, 0)}
            disabled={btnLoad || jobBtn}
          >
            {btnLoad ? <CircularProgress size={28} /> : "Hidden"}
          </Button>
        )}
        <Button
          variant="outlined"
          color="primary"
          endIcon={<ArrowForward />}
          fullWidth
          style={{ marginBottom: 15 }}
          onClick={() => setConfirmDialog(true)}
          disabled={btnLoad || jobBtn}
        >
          Close Job
        </Button>
      </Grid>
      <Grid xs={12}>
        <Grid container>
          {isHidden ? null : (
            <>
              <Grid xs={12} sm={5} md={4}>
                <Button
                  variant={btnLoad || jobBtn ? "outlined" : "contained"}
                  color="primary"
                  endIcon={<ArrowForward />}
                  fullWidth
                  className={classes.jobBtnStyle}
                  onClick={() => {
                    editJob(data?.id);
                  }}
                  disabled={btnLoad || jobBtn}
                >
                  Edit Job
                </Button>
              </Grid>
              <div style={{ width: 20 }} />
            </>
          )}
          <Grid xs={12} sm={5} md={4}>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<ArrowForward />}
              fullWidth
              disabled={btnLoad || jobBtn}
              onClick={() => redirectToPage(data?.id)}
            >
              View Matching
            </Button>
          </Grid>
          {/* <Grid xs={12} sm={3.8}>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<ArrowForward />}
              fullWidth
            >
              Offers
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
      <ConfirmDialog
        title="Are you sure you want to close this job?"
        visible={confirmDialog}
        btnLoad={jobBtn}
        handleModal={(bool) => {
          if (bool) {
            changeStatus(data?.id, 2, bool);
          } else {
            setConfirmDialog(false);
          }
        }}
      />
    </Grid>
  );
}

export default MyJob;
