import React, { useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import Images from "../../../../Config/Images";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { Setting } from "../../../../Utils/Setting";
import useStyles from "../../styles";

function MCoverImg(props) {
  const { visible = false, handleModal = () => null } = props;
  const { token } = useSelector((state) => state.auth);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [btnLoad, setBtnLoad] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : md ? 500 : 600,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  function uploadItem(file) {
    const displayUrl = URL.createObjectURL(file);

    setImageUrl(displayUrl);
    setSelectedFile(file);
  }

  function resetFormData(type = "", data = {}) {
    setImageUrl("");
    setSelectedFile({});
    if (type === "close" && handleModal) {
      handleModal();
    } else if (type === "update") {
      handleModal(data, type, "cover_image");
    }
  }

  // this function for update cover image
  async function updateImage() {
    if (imageUrl !== "") {
      setBtnLoad(true);
      try {
        const response = await getAPIProgressData(
          Setting.endpoints.employerAddCoverImage,
          "POST",
          {
            "EmployerCompany[cover_image]": selectedFile,
          },
          {
            Authorization: `Bearer ${token}`,
          }
        );

        if (response.status) {
          toast.success(response.message);
          resetFormData("update", response.data);
        } else {
          toast.error(response.message);
        }
        setBtnLoad(false);
      } catch (error) {
        console.log("error ===>>>", error);
        setBtnLoad(false);
      }
    } else {
      toast.error("Please select cover image");
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => (btnLoad ? null : resetFormData("close"))}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography variant="subtitle1" component="h2">
              Add Cover image
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => (btnLoad ? null : resetFormData("close"))}
            />
          </div>
          <div className={classes.imgMain3} style={{ marginBottom: 25 }}>
            {isEmpty(imageUrl) ? (
              <Button component="label" className={classes.addimage}>
                <Typography className="lightsilvercolor regulerlargefont">
                  Add cover image
                </Typography>
                <input
                  type="file"
                  onChange={(e) => {
                    uploadItem(e.target.files[0]);
                  }}
                  accept="image/jpeg, image/png, image/jpg"
                  hidden
                />
              </Button>
            ) : (
              <img
                src={imageUrl}
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: 4,
                  objectFit: "cover",
                }}
                alt="coverimage"
              />
            )}
          </div>
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: sm ? "space-between" : "unset",
            }}
          >
            <Grid xs={5.8} sm={4} md={3} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                startIcon={<ArrowBack />}
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
                disabled={btnLoad}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8} sm={4} md={3}>
              <Button
                variant={btnLoad ? "outlined" : "contained"}
                endIcon={btnLoad ? null : <ArrowForward />}
                style={{ width: "100%" }}
                onClick={updateImage}
                disabled={btnLoad}
              >
                {btnLoad ? <CircularProgress size={28} /> : "SAVE"}
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MCoverImg;
