import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import useStyles from "../../styles";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import Images from "../../../../Config/Images";
import _, { isEmpty } from "lodash";

function MVideorole(props) {
  const {
    visible = false,
    handleModal = () => null,
    onSave = () => null,
    editData = {},
  } = props;

  const [VideoUrl, setVideoUrl] = useState("");
  const [videoLoad, setVideoLoad] = useState(false);
  const [videoFile, setVideFile] = useState({});

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : md ? 500 : 700,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (visible) {
      setVideoUrl(editData?.VideoUrl || "");
    }
    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  function uploadItem(file) {
    const displayUrl = URL.createObjectURL(file);
    setVideoLoad(true);

    setTimeout(() => {
      setVideoUrl(displayUrl);
      setVideoLoad(false);
    }, 1000);
  }

  function checkValidation() {
    let valid = true;

    if (isEmpty(VideoUrl)) {
      valid = false;
      toast.error("Please select video");
    }

    if (valid) {
      const videoData = {
        VideoUrl: VideoUrl,
        videoFile: videoFile,
      };

      onSave(videoData);
    }
  }

  function resetFormData(type = "") {
    setVideoUrl("");
    setVideoLoad(false);
    if (type === "close" && handleModal) {
      handleModal("close");
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle1"
              component="h2"
              className={classes.modalTitle}
            >
              Add video for your role
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => {
                resetFormData("close");
              }}
            />
          </div>
          <div
            className={classes.imgMain3}
            style={{
              height: 300,
              position: "relative",
            }}
          >
            {_.isEmpty(VideoUrl) ? (
              <Button component="label" className={classes.addimage}>
                <Typography className="lightsilvercolor regulerlargefont">
                  Add video
                </Typography>
                <input
                  type="file"
                  onChange={(e) => {
                    uploadItem(e.target.files[0]);
                    setVideFile(e.target.files[0]);
                  }}
                  accept="video/mp4, video/webm"
                  hidden
                />
              </Button>
            ) : (
              <video width="100%" height={300} controls>
                <source src={VideoUrl} type="video/mp4" />
              </video>
            )}
            {videoLoad && (
              <CircularProgress
                style={{ position: "absolute", top: "46%", left: "48%" }}
              />
            )}
          </div>
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: sm ? "space-between" : "unset",
            }}
          >
            <Grid xs={5.8} sm={4} md={3} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                startIcon={<ArrowBack />}
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8} sm={4} md={3}>
              <Button
                variant="contained"
                endIcon={<ArrowForward />}
                style={{ width: "100%" }}
                onClick={() => {
                  checkValidation();
                }}
              >
                SAVE
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MVideorole;
