import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { isArray, isEmpty } from "lodash";
import moment from "moment";
import MExperience from "../Modal/MExperience";
import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import { useSelector } from "react-redux";
import useStyles from "../styles";
import { Setting } from "../../../Utils/Setting";
import { toast } from "react-toastify";
import { getAPIProgressData, getApiData } from "../../../Utils/APIHelper";

function Experience(props) {
  const { from = "", data = {}, getProfileData = () => null } = props;
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [itemData, setItemData] = useState({});

  useEffect(() => {
    if (!modal) {
      setItemData({});
    }
  }, [modal]);

  const isModify = !isEmpty(itemData);
  const header = { Authorization: `Bearer ${token}` };

  async function userExperience(data) {
    const datet = moment(data?.endDate).format("yyyy-MM-DD");
    const datef = moment(data?.startDate).format("yyyy-MM-DD");
    setLoader(true);
    const experienceData = {
      "CandidateExperience[company_name]": data?.companyName,
      "CandidateExperience[company_website]": data?.website,
      "CandidateExperience[from_date]": datef,
      "CandidateExperience[to_date]": datet,
      "CandidateExperience[currently_working]": data?.currentlyWork ? 1 : 0,
      "CandidateExperience[description]": data?.description,
    };

    if (isModify) {
      experienceData["CandidateExperience[id]"] = itemData?.id;
    }

    try {
      let endPoint = Setting.endpoints.employeeAddExperience;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        experienceData,
        header
      );
      console.log("response ====>>>>>", response);
      if (response?.status) {
        setModal(false);
        getProfileData();
        toast.success(response?.message);
        setLoader(false);
      } else {
        //display error msg here
        toast.error(response?.message);
        setLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setLoader(false);
    }
  }

  async function deleteExperience(item) {
    try {
      let endPoint = `${Setting.endpoints.employeeDeleteExperience}?id=${item?.id}`;
      const response = await getApiData(endPoint, "GET", null, header);
      if (response && response.status && response.status === true) {
        getProfileData();
        toast.success(response?.message);
      } else {
        console.log("err==>>");
        toast.error(response?.message);
      }
    } catch (err) {
      console.log("Catch Part", err);
      err("Something_went_wrong");
    }
  }

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="experience" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>Experience</Typography>
        {from === "employee" ? null : (
          <AddCircle
            color={color.primary}
            className={classes.addIcon}
            onClick={() => setModal(true)}
          />
        )}
      </div>
      {isArray(data?.user_experience) && data?.user_experience.length > 0 ? (
        data?.user_experience.map((item) => {
          return (
            <div key={`key_${item.company_website}`}>
              <div
                className={`${classes.splitViewStyle} ${classes.contentDetailContainer}`}
              >
                <Typography style={{ fontFamily: "PoppinsMedium" }}>
                  {item?.company_name || ""}
                </Typography>
                {from === "employee" ? null : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className={classes.editBtnStyle}
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        setItemData(item);
                        setModal(true);
                      }}
                    >
                      <img src={Images.edit} style={{ width: 14 }} />
                    </div>
                    <div
                      className={`${classes.editBtnStyle} ${classes.deleteBtnStyle}`}
                      onClick={() => {
                        deleteExperience(item);
                      }}
                    >
                      <img src={Images.delete} style={{ width: 14 }} />
                    </div>
                  </div>
                )}
              </div>
              <Typography className={classes.sectionDesc}>
                {item?.company_website || ""}
              </Typography>
              {item?.from_date && item.to_date && (
                <Typography className={classes.sectionDesc}>
                  {`${moment(item.from_date).format("MMMM YYYY")} - ${moment(
                    item.to_date
                  ).format("MMMM YYYY")}`}
                </Typography>
              )}
              <Typography className={classes.sectionDesc}>
                {item?.description || ""}
              </Typography>
            </div>
          );
        })
      ) : (
        <Typography className={classes.sectionDesc}>
          {from === "employee"
            ? "Fresher"
            : "Tell employers about your most recent employment experience."}
        </Typography>
      )}
      <MExperience
        visible={modal}
        handleModal={() => setModal(false)}
        onSave={(data) => {
          userExperience(data);
        }}
        loader={loader}
        itemData={itemData}
      />
    </Grid>
  );
}
export default Experience;
