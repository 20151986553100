import { makeStyles } from "@mui/styles";
import { color } from "../../Config/theme";

const useStyles = makeStyles((theme) => ({
  splitViewStyle: {
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 14,
    marginBottom: 10,
  },
  dividerline: {
    borderBottom: "1px solid #1f4771",
    width: "44%",
  },
}));

export default useStyles;
