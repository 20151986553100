import { ArrowForward } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
  TextField,
} from "@mui/material";
import useStyles from "../styles";
import { Setting } from "../../../Utils/Setting";
import { useNavigate } from "react-router-dom";
import FBLoginButton from "../../Social_Login/FBLoginButton";
import GoogleLoginButton from "../../Social_Login/GoogleLoginButton";
import LinkedinLoginButton from "../../Social_Login/LinkedinLoginButton";

const errorObj = {
  emailErr: false,
  passwordErr: false,
  emailMsg: "",
  passwordMsg: "",
};

function MLogin(props) {
  const {
    visible = false,
    handleModal = () => null,
    forgotClick = () => null,
    loginClick = () => null,
    loader = false,
    isEmployerHome = false,
  } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  const emailRegex = Setting.JS_Regex.email_Regex;
  const passRegex = Setting.JS_Regex.pwd1_Regex;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errObj, setErrObj] = useState(errorObj);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    resetFormData();
  }, [visible]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  // this function for reset form data
  function resetFormData(type = "") {
    setEmail("");
    setPassword("");
    setErrObj(errorObj);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(email) || !emailRegex.test(email)) {
      valid = false;
      error.emailErr = true;
      error.emailMsg = "Please enter valid email";
    } else if (isEmpty(password)) {
      valid = false;
      error.passwordErr = true;
      error.passwordMsg = "Please enter Password";
    } else if (!passRegex.test(password)) {
      valid = false;
      error.passwordErr = true;
      error.passwordMsg = "Password length between 8 to 15";
    }

    setErrObj(error);

    if (valid) {
      const login_detail = {
        email: email,
        password: password,
      };
      loginClick(login_detail);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle}>
            <Typography variant="subtitle1" component="h2">
              Login
            </Typography>
            <TextField
              placeholder="Email"
              value={email}
              error={errObj.emailErr}
              onChange={(v) => {
                setEmail(v.target.value);
                setErrObj({ ...errObj, emailErr: false, emailMsg: "" });
              }}
              type="text"
              helperText={errObj.emailErr ? errObj.emailMsg : null}
              style={{ marginTop: 10 }}
            />
            <TextField
              placeholder="Password"
              value={password}
              error={errObj.passwordErr}
              onChange={(v) => {
                setPassword(v.target.value);
                setErrObj({ ...errObj, passwordErr: false, passwordMsg: "" });
              }}
              type="password"
              autoComplete="new-password"
              style={{ marginTop: 10 }}
              helperText={errObj.passwordErr ? errObj.passwordMsg : null}
            />
            <div
              style={{ display: "flex" }}
              onClick={() => {
                forgotClick();
              }}
            >
              <Typography
                className="primarycolor regulerfont"
                style={{ cursor: "pointer", marginTop: 8 }}
              >
                Forgot Password?
              </Typography>
            </div>
            <Button
              variant="contained"
              endIcon={loader ? null : <ArrowForward />}
              style={{ marginTop: 14 }}
              onClick={() => {
                checkValidation();
              }}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={26} />
              ) : (
                "Log in"
              )}
            </Button>
            <div className={classes.divider}>
              <div className={classes.dividerline} />
              <Typography className="primarycolor">OR</Typography>
              <div className={classes.dividerline} />
            </div>

            <GoogleLoginButton
              from="Login"
              onCloseModal={() => {
                handleModal();
              }}
            />
            <FBLoginButton
              from="Login"
              onCloseModal={() => {
                handleModal();
              }}
            />
            <LinkedinLoginButton />

            <Typography
              className="primarycolor regulerfont"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 50,
              }}
            >
              Don’t have a Direct Expertise account?{" "}
              <Typography
                className="smallsemiboldfont"
                style={{ cursor: "pointer", width: 70 }}
                onClick={() => {
                  if (isEmployerHome) {
                    resetFormData("close");
                  } else {
                    navigate("/candidate/signup");
                    resetFormData("close");
                  }
                }}
              >
                <u>Sign up</u>
              </Typography>
            </Typography>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MLogin;
