import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import moment from "moment";
import { isArray, isEmpty } from "lodash";
import MEducation from "../Modal/MEducation";
import { useSelector } from "react-redux";
import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import useStyles from "../styles";
import { toast } from "react-toastify";
import { Setting } from "../../../Utils/Setting";
import { getAPIProgressData, getApiData } from "../../../Utils/APIHelper";

function Education(props) {
  const { from = "", data = {}, getProfileData = () => null } = props;
  const { token } = useSelector((state) => state.auth);
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [itemData, setItemData] = useState({});

  const header = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    if (!modal) {
      setItemData({});
    }
  }, [modal]);

  async function userEducation(data) {
    const datef = moment(data?.startDate).format("yyyy-MM-DD");
    const datet = moment(data?.endDate).format("yyyy-MM-DD");
    setLoader(true);
    const educationData = {
      "CandidateEducation[institute_name]": data?.institution,
      "CandidateEducation[course_title]": data?.courseTitle,
      "CandidateEducation[level]": data?.level,
      "CandidateEducation[result]": data?.result,
      "CandidateEducation[currently_studying]": data?.study ? 1 : 0,
      "CandidateEducation[from_date]": datef,
    };

    if (!data?.study) {
      educationData["CandidateEducation[to_date]"] = datet;
    }

    // if (isModify) {
    //   educationData["CandidateEducation[id]"] = itemData?.id;
    // }

    console.log("educationData ====>>>>>", educationData);
    try {
      let endPoint = Setting.endpoints.employeeAddEducation;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        educationData,
        header
      );
      console.log("response ====>>>>>", response);
      if (response?.status) {
        setModal(false);
        getProfileData();
        toast.success(response?.message);
        setLoader(false);
      } else {
        //display error msg here
        toast.error(response?.message);
        setLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setLoader(false);
    }
  }

  async function deleteEducation(item) {
    try {
      let endPoint = `${Setting.endpoints.employeeDeleteEducation}?id=${item?.id}`;
      const response = await getApiData(endPoint, "GET", null, header);
      console.log("response ====>>>>>", response);
      if (response && response.status && response.status === true) {
        getProfileData();
        toast.success(response?.message);
      } else {
        console.log("err==>>");
        toast.error(response?.message);
      }
    } catch (err) {
      console.log("Catch Part", err);
      err("Something_went_wrong");
    }
  }

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="education" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>Education</Typography>
        {from === "employee" ? null : (
          <AddCircle
            color={color.primary}
            className={classes.addIcon}
            onClick={() => setModal(true)}
          />
        )}
      </div>
      {isArray(data?.user_education) && data?.user_education.length > 0 ? (
        data?.user_education.map((item) => {
          return (
            <div key={`education_${item.created_at}`}>
              <div
                className={`${classes.splitViewStyle} ${classes.contentDetailContainer}`}
              >
                <Typography style={{ fontFamily: "PoppinsMedium" }}>
                  {item.course_title || ""}
                </Typography>
                {from === "employee" ? null : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className={classes.editBtnStyle}
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        setModal(true);
                        setItemData(item);
                      }}
                    >
                      <img src={Images.edit} style={{ width: 14 }} />
                    </div>
                    <div
                      className={`${classes.editBtnStyle} ${classes.deleteBtnStyle}`}
                      onClick={() => {
                        deleteEducation(item);
                      }}
                    >
                      <img src={Images.delete} style={{ width: 14 }} />
                    </div>
                  </div>
                )}
              </div>
              <Typography className={classes.sectionDesc}>
                {item.institute_name || ""}
              </Typography>
              <Typography className={classes.sectionDesc}>
                {`${moment(item.from_date).format("MMMM YYYY")} - ${moment(
                  item.to_date
                ).format("MMMM YYYY")}`}
              </Typography>
              {/* <Typography className={classes.sectionDesc}>
                {item.qualification || ""}
              </Typography> */}
              <Typography className={classes.sectionDesc}>
                {`${item.course_title || ""} - ${item.result || ""}`}
              </Typography>
            </div>
          );
        })
      ) : (
        <Typography className={classes.sectionDesc}>
          {from === "employee"
            ? "-"
            : "Put your hard-won qualifications to good use."}
        </Typography>
      )}
      <MEducation
        visible={modal}
        handleModal={() => setModal(false)}
        onSave={(data) => {
          userEducation(data);
        }}
        itemData={itemData}
        loader={loader}
      />
    </Grid>
  );
}
export default Education;
