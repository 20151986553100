import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Select,
  MenuItem,
  FormControl,
  Modal,
  Button,
  TextField,
  InputAdornment,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { ArrowForward, ArrowBackOutlined, Close } from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { Setting } from "../../../../Utils/Setting";
import Images from "../../../../Config/Images";
import useStyles from "../../styles";

const errorObj = {
  birthDate: false,
  gender: false,
  address: false,
  hometown: false,
  description: false,
  birthDateMsg: "",
  genderMsg: "",
  addressMsg: "",
  hometownMsg: "",
  descriptionMsg: "",
};

function MBio(props) {
  const { visible = false, handleModal = () => null, data = {} } = props;
  const classes = useStyles();
  const { token } = useSelector((state) => state.auth);
  const [birthDate, setBirthDate] = useState(null);
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [hometown, setHometown] = useState("");
  const [picker, setPicker] = useState(false);
  const [description, setDescription] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const [errObj, setErrObj] = useState(errorObj);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (visible) {
      setBirthDate(data?.birth_date || "");
      setGender(data?.gender || "");
      setHometown(data?.hometown || "");
      setAddress(data?.permanent_address || "");
      setDescription(data?.bio_description || "");
    }
  }, [visible]);

  // this function for reset form data
  function resetFormData(type = "", data = {}) {
    setBirthDate(null);
    setGender("");
    setAddress("");
    setHometown("");
    setDescription("");
    if (type === "close" && handleModal) {
      handleModal(data, "close");
    } else if (type === "update" && handleModal) {
      handleModal(data, "update");
    }
  }

  // this function for validate required fields
  function validation() {
    const error = { ...errObj };
    let valid = true;

    if (birthDate === "") {
      valid = false;
      error.birthDate = true;
      error.addressMsg = "Please select/enter birthdate";
    }
    if (gender === "") {
      valid = false;
      error.gender = true;
      error.genderMsg = "Please select gender";
    }
    if (description === "") {
      valid = false;
      error.description = true;
      error.descriptionMsg = "Please enter description";
    }
    if (address === "") {
      valid = false;
      error.address = true;
      error.addressMsg = "Please enter address";
    }
    if (hometown === "") {
      valid = false;
      error.hometown = true;
      error.hometownMsg = "Please enter hometown";
    }

    setErrObj(error);

    if (valid) {
      submitData();
    }
  }

  // this function for update bio to server
  async function submitData() {
    setBtnLoad(true);
    const obj = {
      "UserBioForm[birth_date]": moment(birthDate).format("YYYY-MM-DD"),
      "UserBioForm[gender]": gender,
      "UserBioForm[bio_description]": description,
      "UserBioForm[permanent_address]": address,
      "UserBioForm[hometown]": hometown,
    };
    try {
      const response = await getAPIProgressData(
        Setting.endpoints.employeeAddBio,
        "POST",
        obj,
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        toast.success(response.message);
        resetFormData("update", response?.data);
      } else {
        toast.error(response.message);
      }
      setBtnLoad(false);
    } catch (error) {
      console.log("error ===>>", error);
      setBtnLoad(false);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle}>
            <Typography variant="subtitle1" component="h2">
              Add Bio
            </Typography>
            <img
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className={classes.formDivStyle}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={birthDate}
                onChange={(newValue) => {
                  setBirthDate(newValue);
                  setPicker(!picker);
                  setErrObj({ ...errObj, birthDateMsg: "", birthDate: false });
                }}
                open={picker}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    className={classes.inputFieldStyle}
                    {...params}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            className={`${classes.cViewStyle} ${classes.pickerStyle}`}
                            onClick={() => setPicker(!picker)}
                          >
                            <img src={Images.picker} style={{ width: 24 }} />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    error={errObj.birthDate}
                    helperText={errObj.birthDate ? errObj.birthDateMsg : null}
                  />
                )}
              />
            </LocalizationProvider>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.inputFieldStyle}
              error={errObj.gender}
            >
              <Select
                IconComponent={() => (
                  <img src={Images.angle} className={classes.angleBtnStyle} />
                )}
                onChange={(e) => {
                  setGender(e.target.value);
                  setErrObj({ ...errObj, genderMsg: "", gender: false });
                }}
                value={gender}
                displayEmpty
                style={gender === "" ? { color: "#A2A2A2" } : {}}
              >
                <MenuItem value="" disabled>
                  Select gender
                </MenuItem>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
              <FormHelperText>{errObj.genderMsg}</FormHelperText>
            </FormControl>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter description"
              className={classes.inputFieldStyle}
              multiline
              minRows={4}
              maxRows={4}
              style={{ padding: "0px !important" }}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setErrObj({
                  ...errObj,
                  descriptionMsg: "",
                  description: false,
                });
              }}
              error={errObj.description}
              helperText={errObj.description ? errObj.descriptionMsg : null}
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter permanent address"
              className={classes.inputFieldStyle}
              multiline
              minRows={4}
              maxRows={4}
              style={{ padding: "0px !important" }}
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
                setErrObj({ ...errObj, addressMsg: "", address: false });
              }}
              error={errObj.address}
              helperText={errObj.address ? errObj.addressMsg : null}
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter hometown"
              className={classes.inputFieldStyle}
              value={hometown}
              onChange={(e) => {
                setHometown(e.target.value);
                setErrObj({ ...errObj, hometownMsg: "", hometown: false });
              }}
              error={errObj.hometown}
              helperText={errObj.hometown ? errObj.hometownMsg : null}
            />
          </div>
          <div className={classes.splitViewStyle}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ArrowBackOutlined />}
              className={classes.modalBtnStyle}
              onClick={resetFormData}
              disabled={btnLoad}
            >
              Reset
            </Button>
            <Button
              variant={btnLoad ? "outlined" : "contained"}
              color="primary"
              endIcon={btnLoad ? null : <ArrowForward />}
              className={classes.modalBtnStyle}
              onClick={validation}
              disabled={btnLoad}
            >
              {btnLoad ? <CircularProgress size={28} /> : "Save"}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default MBio;
