import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Avatar,
  Checkbox,
  useMediaQuery,
  useTheme,
  TextField,
  CircularProgress,
} from "@mui/material";
import Slider from "react-slick";
import {
  ArrowForward,
  ArrowDownward,
  KeyboardArrowRight,
  KeyboardArrowLeft,
} from "@mui/icons-material";
import "./styles.css";
import Images from "../../../Config/Images";
import { Setting } from "../../../Utils/Setting";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import { isEmpty, isObject } from "lodash";
import MUserDetail from "../../../Components/Profile/Modal/MUserDetail";
import GoogleLoginButton from "../../../Components/Social_Login/GoogleLoginButton";

const errorObj = {
  companyNameErr: false,
  emailErr: false,
  phoneErr: false,
  passwordErr: false,
  companyNameMsg: "",
  emailMsg: "",
  phoneMsg: "",
  passwordMsg: "",
};

export default function Home() {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const email_Regex = Setting.JS_Regex.email_Regex;
  const passRegex = Setting.JS_Regex.pwd1_Regex;
  const { reviewdata } = useSelector((state) => state.auth);

  const candidateReview = reviewdata?.user_review || [];
  const employerReview = reviewdata?.employer_review || [];

  const isCandidateEmpty = isEmpty(candidateReview);
  const isEmployerEmpty = isEmpty(employerReview);

  const isOne = candidateReview.length === 1;

  const navigate = useNavigate();
  const sliderRef = useRef();
  const firstSliderRef = useRef();
  const firstSliderImgRef = useRef();

  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errObj, setErrObj] = useState(errorObj);

  const [loader, setLoader] = useState(false);
  const [agree, setAgree] = useState(false);

  const [modalSaveBtnLoader, setModalSaveBtnLoader] = useState(false);
  const [socialUserInfo, setSocialUserInfo] = useState({});
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    document.title = Setting.page_name.EMPLOYER_HOME;
    window.scrollTo(0, 0);
  }, []);

  function resetData() {
    setCompanyName("");
    setEmail("");
    setPhone("");
    setPassword("");
    setAgree(false);
    setErrObj(errorObj);
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(companyName)) {
      valid = false;
      error.companyNameErr = true;
      error.companyNameMsg = "Please enter company name";
    } else if (isEmpty(email) || !email_Regex.test(email)) {
      valid = false;
      error.emailErr = true;
      error.emailMsg = "Please enter valid email name";
    } else if (isEmpty(phone)) {
      valid = false;
      error.phoneErr = true;
      error.phoneMsg = "Please enter company name";
    } else if (isEmpty(password)) {
      valid = false;
      error.passwordErr = true;
      error.passwordMsg = "Please enter company name";
    } else if (!passRegex.test(password)) {
      valid = false;
      error.passwordErr = true;
      error.passwordMsg = "Password length between 8 to 15";
    } else if (!agree) {
      valid = false;
      toast.error("please select terms & condition");
    }

    setErrObj(error);

    if (valid) {
      companySignupProcess();
      setLoader(true);
    }
  }

  async function companySignupProcess(data = {}) {
    const isSocialSignUp = isObject(data) && !isEmpty(data);

    const signUpData = {
      "EmployerSignup[company_name]": isSocialSignUp
        ? data?.firstName
        : companyName,
      "EmployerSignup[email]": isSocialSignUp ? data?.email : email,
      "EmployerSignup[phone]": isSocialSignUp ? data?.phone : phone,
      "EmployerSignup[terms_condition]": agree ? 1 : 0,
    };

    if (!isSocialSignUp) {
      signUpData["EmployerSignup[password]"] = password;
    } else {
      signUpData["EmployerSignup[provider_type]"] =
        socialUserInfo?.provider_type;
      signUpData["EmployerSignup[provider_key]"] = socialUserInfo?.provider_key;
    }

    try {
      let endPoint = Setting.endpoints.employer_signup;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        signUpData,
        true
      );
      console.log("response ====>>>>>", response);
      if (response?.status) {
        if (isSocialSignUp) {
          setOpenModal(false);
          setModalSaveBtnLoader(false);
        }
        toast.success(response?.message);
        setLoader(false);
        resetData();
      } else {
        //display error msg here
        toast.error(response?.message);
        setModalSaveBtnLoader(false);
        setLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      toast.error("Something_went_wrong");
      setModalSaveBtnLoader(false);
      setLoader(false);
    }
  }

  const setting = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: isOne ? 1 : 2,
    slidesToScroll: 1,
    swipe: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
          dots: false,
          arrows: false,
          swipe: false,
        },
      },
    ],
  };

  const firstSetting = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    arrows: false,
  };

  function renderFirst() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={6}
            alignItems="center"
            justifyContent="center"
          >
            {sm ? null : (
              <img
                alt="Error"
                src={Images?.heroimg2}
                style={{ height: "100%", width: "100%" }}
              />
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={6}
            direction="column"
            justifyContent={"center"}
            style={{ paddingLeft: sm ? 0 : md ? 30 : 100 }}
          >
            <Typography className="fontsize primarycolor">
              Employer Sign up
            </Typography>
            <Typography className="primarycolor" style={{ marginBottom: 16 }}>
              Want to sign up as candidate?{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/candidate/signup");
                }}
              >
                <u>Click here</u>
              </span>
            </Typography>
            <TextField
              variant="outlined"
              placeholder="Company name*"
              value={companyName}
              error={errObj.companyNameErr}
              helperText={errObj.companyNameErr ? errObj.companyNameMsg : null}
              onChange={(v) => {
                setCompanyName(v.target.value);
                setErrObj({
                  ...errObj,
                  companyNameErr: false,
                  companyNameMsg: "",
                });
              }}
              style={{ maxWidth: sm ? "100%" : 310, marginBottom: 10 }}
            />
            <TextField
              variant="outlined"
              placeholder="Email*"
              value={email}
              error={errObj.emailErr}
              helperText={errObj.emailErr ? errObj.emailMsg : null}
              onChange={(v) => {
                setEmail(v.target.value);
                setErrObj({
                  ...errObj,
                  emailErr: false,
                  emailMsg: "",
                });
              }}
              style={{ maxWidth: sm ? "100%" : 310, marginBottom: 10 }}
            />
            <TextField
              variant="outlined"
              placeholder="Phone*"
              value={phone}
              type="number"
              error={errObj.phoneErr}
              helperText={errObj.phoneErr ? errObj.phoneMsg : null}
              onChange={(v) => {
                setPhone(v.target.value);
                setErrObj({
                  ...errObj,
                  phoneErr: false,
                  phoneMsg: "",
                });
              }}
              style={{ maxWidth: sm ? "100%" : 310, marginBottom: 10 }}
            />
            <TextField
              variant="outlined"
              placeholder="Password*"
              value={password}
              error={errObj.passwordErr}
              helperText={errObj.passwordErr ? errObj.passwordMsg : null}
              onChange={(v) => {
                setPassword(v.target.value);
                setErrObj({
                  ...errObj,
                  passwordErr: false,
                  passwordMsg: "",
                });
              }}
              autoComplete="new-password"
              type="password"
              style={{ maxWidth: sm ? "100%" : 310, marginBottom: 10 }}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={agree}
                onChange={() => {
                  setAgree(!agree);
                }}
                style={{ width: 40, marginLeft: -9 }}
              />
              <Typography
                className="lightsilvercolor"
                style={{ color: "#1F47714D" }}
              >
                I agree to the{" "}
                <span className="primarycolor" style={{ cursor: "pointer" }}>
                  <u>T&Cs</u>
                </span>{" "}
                and{" "}
                <span className="primarycolor" style={{ cursor: "pointer" }}>
                  <u>Privacy Policy</u>
                </span>
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              endIcon={loader ? null : <ArrowForward />}
              style={{ width: sm ? "100%" : 310, marginTop: 10 }}
              onClick={() => {
                checkValidation();
              }}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={26} />
              ) : (
                "sign up"
              )}
            </Button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                width: sm ? "100%" : 310,
              }}
            >
              <div className="divider1" />
              <Typography
                className="primarycolor"
                style={{ marginLeft: 8, marginRight: 8 }}
              >
                OR
              </Typography>
              <div className="divider1" />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: sm ? "100%" : 310,
                marginTop: 10,
              }}
            >
              <GoogleLoginButton
                from="SignUp"
                type="employer"
                onGetUserInfo={(v) => {
                  setSocialUserInfo(v);
                  setOpenModal(true);
                }}
              />
              <img
                src={Images?.linkedinTheme}
                alt="error"
                style={{ height: 30, width: 30, cursor: "pointer" }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderSecond() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 5 }}>
        <Grid
          container
          style={{
            padding: 20,
            backgroundColor: "#f5f9ff",
            borderRadius: 8,
            paddingBottom: 30,
          }}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              How our service works
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 30 }}
          >
            <Grid item xs={12} sm={3.6}>
              <div className="secondGrid1">
                <Typography
                  style={{
                    marginBottom: 6,
                  }}
                  className="primarycolor mediumsemiboldfont"
                >
                  Find new customers
                </Typography>
                <Typography className="mediumfont lightsilvercolor">
                  Choose the location you want to work in, and get a list of
                  homeowners who need your help.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={0.5} justifyContent="center">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {sm ? (
                  <ArrowDownward
                    style={{ color: "#D6AC85", marginTop: 4, marginBottom: 4 }}
                  />
                ) : (
                  <ArrowForward style={{ color: "#D6AC85" }} />
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={3.6}>
              <div className="secondGrid1">
                <Typography
                  style={{ marginBottom: 6 }}
                  className="primarycolor mediumsemiboldfont"
                >
                  Pick your job leads
                </Typography>
                <Typography className="mediumfont lightsilvercolor">
                  Access contact details for a small fee taken off your credit.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={0.5} justifyContent="center">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {sm ? (
                  <ArrowDownward
                    style={{ color: "#D6AC85", marginTop: 4, marginBottom: 4 }}
                  />
                ) : (
                  <ArrowForward style={{ color: "#D6AC85" }} />
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={3.6}>
              <div className="secondGrid1">
                <Typography
                  style={{ marginBottom: 6 }}
                  className="primarycolor mediumsemiboldfont"
                >
                  Rate and review
                </Typography>
                <Typography className="mediumfont lightsilvercolor">
                  Get your business reviewed. We’ll add ratings to your profile,
                  where homeowners can find you in Google.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderThird() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10 }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <Typography
              className="fontsize"
              style={{ textAlign: "center", marginBottom: 20 }}
            >
              More than just job leads
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="thirdmain">
            <div style={{ display: "flex" }}>
              <div className="imgmain2">
                <img src={Images?.icon1} alt="error" className="imageicon" />
              </div>
              <div className="textmain2">
                <Typography
                  className="primarycolor mediumsemiboldfont"
                  style={{ marginBottom: 10 }}
                >
                  Make direct contact
                </Typography>
                <Typography className="lightsilvercolor mediumfont">
                  Immediate access to homeowner details to give a quote.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="thirdmain">
            <div style={{ display: "flex" }}>
              <div className="imgmain2">
                <img src={Images?.icon2} alt="error" className="imageicon" />
              </div>
              <div className="textmain2">
                <Typography
                  className="primarycolor mediumsemiboldfont"
                  style={{ marginBottom: 10 }}
                >
                  Job management app
                </Typography>
                <Typography className="lightsilvercolor mediumfont">
                  Chat, call, find more work - all in one place.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="thirdmain">
            <div style={{ display: "flex" }}>
              <div className="imgmain2">
                <img src={Images?.icon3} alt="error" className="imageicon" />
              </div>
              <div className="textmain2">
                <Typography
                  className="primarycolor mediumsemiboldfont"
                  style={{ marginBottom: 10 }}
                >
                  Lead tracker software
                </Typography>
                <Typography className="lightsilvercolor mediumfont">
                  Free access to tools that can cost hundreds elsewhere.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="thirdmain">
            <div style={{ display: "flex" }}>
              <div className="imgmain2">
                <img src={Images?.icon4} alt="error" className="imageicon" />
              </div>
              <div className="textmain2">
                <Typography
                  className="primarycolor mediumsemiboldfont"
                  style={{ marginBottom: 10 }}
                >
                  Member discounts
                </Typography>
                <Typography className="lightsilvercolor mediumfont">
                  On tools, fuel, insurance and loads more.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="thirdmain">
            <div style={{ display: "flex" }}>
              <div className="imgmain2">
                <img src={Images?.icon5} alt="error" className="imageicon" />
              </div>
              <div className="textmain2">
                <Typography
                  className="primarycolor mediumsemiboldfont"
                  style={{ marginBottom: 10 }}
                >
                  Profile badges
                </Typography>
                <Typography className="lightsilvercolor mediumfont">
                  Milestone badges to build your profile and attract more work.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="thirdmain">
            <div style={{ display: "flex" }}>
              <div className="imgmain2">
                <img src={Images?.icon6} alt="error" className="imageicon" />
              </div>
              <div className="textmain2">
                <Typography
                  className="primarycolor mediumsemiboldfont"
                  style={{ marginBottom: 10 }}
                >
                  Account managers
                </Typography>
                <Typography className="lightsilvercolor mediumfont">
                  Dedicated support for premium customers.
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderFourth() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10 }}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: 20 }}
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              Words from our employers
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={7.5}>
              <Slider ref={firstSliderImgRef} {...firstSetting}>
                {employerReview.map((ii, index) => {
                  return (
                    <>
                      <div key={index} style={{ display: "flex" }}>
                        <img alt="Error" src={ii.photo} className="sliderimg" />
                      </div>
                    </>
                  );
                })}
              </Slider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4.5}
              style={{ position: "relative", marginTop: sm ? 20 : 0 }}
            >
              <div className="maindiv">
                {sm ? null : <div className="divdesign" />}
                <div className="remaindiv">
                  <div className="arrowmaindiv">
                    <div
                      className="arrowdiv"
                      onClick={() => {
                        firstSliderRef.current.slickPrev();
                        firstSliderImgRef.current.slickPrev();
                      }}
                    >
                      <KeyboardArrowLeft
                        style={{ color: "#fff", fontSize: sm ? 30 : 40 }}
                      />
                      <div
                        className="arrowdiv arrowdiv2"
                        onClick={() => {
                          firstSliderRef.current.slickNext();
                          firstSliderImgRef.current.slickNext();
                        }}
                      >
                        <KeyboardArrowRight
                          style={{ color: "#fff", fontSize: sm ? 30 : 40 }}
                        />
                      </div>
                    </div>
                  </div>
                  <Slider ref={firstSliderRef} {...firstSetting}>
                    {employerReview.map((ii, index) => {
                      return (
                        <>
                          <div key={index} className="messsagefromclien">
                            <div className="messageheder">
                              <Typography className="primarycolor mediumlargefont">
                                {ii?.name}
                              </Typography>
                              <div className="underline" />
                            </div>
                            <div
                              className="sliderscroll"
                              style={{
                                margin: 20,
                                maxHeight: md ? 240 : 320,
                              }}
                            >
                              <Typography className="regulerfont">
                                {ii?.description}
                              </Typography>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderFifth() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: sm ? 10 : 20 }}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ padding: 20 }}
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              3 steps to start your work adventure
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              direction="column"
              justifyContent="center"
            >
              <Typography
                className="primarycolor fontsize2"
                style={{ marginBottom: 6 }}
              >
                <span className="shadow">1.</span> Create profile
              </Typography>
              <Typography
                className="lightsilvercolor"
                style={{ lineHeight: 2 }}
              >
                Sign up for free and create a company profile with all the
                things that make your company a great place to work. Then post
                your role with the skills and preferences you are looking for,
                so candidates know what you’re all about. The more detail you
                give, the better our technology will match you to the right
                candidates.
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              alignItems="center"
              justifyContent="center"
            >
              <img
                alt="Error"
                src={Images?.estep1}
                style={{ height: "100%", width: "100%" }}
              />
            </Grid>
            <Grid container xs={12} direction="row-reverse">
              <Grid
                container
                xs={12}
                sm={6}
                md={6}
                direction="column"
                justifyContent="center"
              >
                <Typography
                  className="primarycolor fontsize2"
                  style={{ marginBottom: 6 }}
                >
                  <span className="shadow">2.</span> Get matched
                </Typography>
                <Typography
                  className="lightsilvercolor"
                  style={{ lineHeight: 2 }}
                >
                  Our smart tech matches you to candidates instantly, based on
                  experience, skills, preferences and more. With detailed
                  candidate profiles including videos and ratings you get all
                  the information you want, from the get-go. Shortlist, request
                  more information and invite people to interviews directly
                  within the platform.
                </Typography>
              </Grid>
              <Grid
                container
                xs={12}
                sm={6}
                md={6}
                alignItems="center"
                justifyContent="center"
              >
                <img
                  alt="Error"
                  src={Images?.estep2}
                  style={{ height: "100%", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              direction="column"
              justifyContent="center"
            >
              <Typography
                className="primarycolor fontsize2"
                style={{ marginBottom: 6 }}
              >
                <span className="shadow">3.</span> Make your hire
              </Typography>
              <Typography
                className="lightsilvercolor"
                style={{ lineHeight: 2 }}
              >
                The Direct Expertise platform lets you to run a smooth,
                candidate-friendly hiring process. From screening, messaging and
                video interviews all the way through to offers, everything is
                managed in one place. For temp hires, we even do all the back
                office admin and payroll, so you don’t have to.
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              alignItems="center"
              justifyContent="center"
            >
              <img
                alt="Error"
                src={Images?.estep3}
                style={{ height: "100%", width: "100%" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderSixth() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10, marginBottom: 10 }}>
        <Grid
          container
          style={{ padding: 20, backgroundColor: "#f5f9ff", borderRadius: 8 }}
        >
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            style={{ marginBottom: 20 }}
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              What candidates says about us
            </Typography>
          </Grid>
          <Grid container xs={12} alignItems="center" justifyContent="center">
            <Grid item xs={1}>
              <Button
                variant="outlined"
                className="buttun"
                onClick={() => {
                  sliderRef.current.slickPrev();
                }}
              >
                <KeyboardArrowLeft style={{ fontSize: 20 }} />
              </Button>
            </Grid>
            <Grid item xs={10}>
              <Slider ref={sliderRef} {...setting} className="carousal">
                {candidateReview.map((ii, index) => {
                  return (
                    <Grid item container xs={12} key={index}>
                      <div className="slider12">
                        <div className="msg-icn">
                          <Typography
                            className="lightsilvercolor regulersmallfont"
                            style={{ marginBottom: 16 }}
                          >
                            {ii?.description}
                          </Typography>
                          <Typography className="primarycolor smallsemiboldfont">
                            {ii?.name}
                          </Typography>
                        </div>
                      </div>
                      <div className="profilecmt">
                        <Avatar
                          className="avatarUI"
                          alt="error"
                          src={ii?.photo}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Slider>
            </Grid>
            <Grid item xs={1}>
              <div
                style={{
                  display: sm ? "unset" : "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  className="buttun"
                  onClick={() => {
                    sliderRef.current.slickNext();
                  }}
                >
                  <KeyboardArrowRight style={{ fontSize: 20 }} />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderSeventh() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10, marginBottom: 5 }}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            style={{ marginBottom: 26 }}
          >
            <Typography className="fontsize" style={{ textAlign: "center" }}>
              Get our PWA app on Android and iPhone and share on
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="iconstyle">
                <img
                  alt="Error"
                  src={Images?.install}
                  style={{ height: "60%", width: "60%" }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="iconstyle">
                <img
                  alt="Error"
                  src={Images?.faceBook}
                  style={{ height: "60%", width: "60%" }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="iconstyle">
                <img
                  alt="Error"
                  src={Images?.insta}
                  style={{ height: "60%", width: "60%" }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="iconstyle">
                <img
                  alt="Error"
                  src={Images?.linkedin}
                  style={{ height: "60%", width: "60%" }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="iconstyle">
                <img
                  alt="Error"
                  src={Images?.twitter}
                  style={{ height: "60%", width: "60%" }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <div>
      {renderFirst()}
      {renderSecond()}
      {renderThird()}
      {isEmployerEmpty ? null : renderFourth()}
      {renderFifth()}
      {isCandidateEmpty ? null : renderSixth()}
      {renderSeventh()}
      <MUserDetail
        visible={openModal}
        from="employer"
        handleModal={() => {
          setOpenModal(false);
          setModalSaveBtnLoader(false);
        }}
        socialUserInfo={socialUserInfo}
        modalSaveBtnLoader={modalSaveBtnLoader}
        onSignUpAction={(v) => {
          companySignupProcess(v);
          setModalSaveBtnLoader(true);
        }}
      />
    </div>
  );
}
