import _ from 'lodash';
import { store } from "../Redux/store/configureStore";

export function isUserLogin() {
  const {
    auth: { userdata },
  } = store.getState();

  if (_.isObject(userdata) && !_.isEmpty(userdata)) {
    return true;
  } else {
    return false;
  }
}