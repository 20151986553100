import types from "./actions";

const initialState = {
  useruuid: "",
  userdata: {},
  dropdowndata: {},
  token: "",
  isNotifiy: false,
  reviewdata: {},
  candidateProfile: {},
  communicationPreference: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_DATA:
      localStorage.setItem("userData", JSON.stringify(action.userdata));
      return {
        ...state,
        userdata: action.userdata,
      };

    case types.SET_DROPDOWN_DATA:
      localStorage.setItem("dropDownData", action.dropdowndata);
      return {
        ...state,
        dropdowndata: action.dropdowndata,
      };

    case types.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    case types.SET_USER_UUID:
      return {
        ...state,
        useruuid: action.useruuid,
      };

    case types.SET_DISPLAY_NOTIFICATION_POP_UP:
      return {
        ...state,
        isNotifiy: action.isNotifiy,
      };

    case types.SET_NOTI_DATA:
      return {
        ...state,
        notiData: action.notiData,
      };

    case types.SET_REVIEW:
      localStorage.setItem("reviewdata", action.reviewdata);
      return {
        ...state,
        reviewdata: action.reviewdata,
      };

    case types.SET_CANDIDATEPROFILE:
      localStorage.setItem("candidateProfile", action.candidateProfile);
      return {
        ...state,
        candidateProfile: action.candidateProfile,
      };

    case types.SET_COMMUNICATION:
      localStorage.setItem(
        "communicationPreference",
        action.communicationPreference
      );
      return {
        ...state,
        communicationPreference: action.communicationPreference,
      };

    case types.CLEAR_ALL_STORAGE_DATA:
      // localStorage.clear();
      localStorage.removeItem("userData");
      return {
        ...state,
        userdata: {},
        isNotifiy: false,
      };

    default:
      return state;
  }
}
