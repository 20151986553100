import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useMediaQuery,
  useTheme,
  CircularProgress,
  TextField,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import useStyles from "../styles";
import { isEmpty } from "lodash";
import { Setting } from "../../../Utils/Setting";

function MForgot(props) {
  const {
    visible = false,
    handleModal = () => null,
    resetPass = () => null,
    loader = false,
  } = props;
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const email_regex = Setting.JS_Regex.email_Regex;

  useEffect(() => {
    resetFormData();
  }, [visible]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  // this function for reset form data
  function resetFormData(type = "") {
    setEmail("");
    setEmailErr(false);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    let valid = true;

    if (isEmpty(email) || !email_regex.test(email)) {
      valid = false;
      setEmailErr(true);
    }

    const email_data = {
      email: email,
    };
    if (valid) {
      setEmailErr(false);
      resetPass(email_data);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style} className={classes.boxmain}>
          <div className={classes.splitViewStyle}>
            <Typography
              className="primarycolor fontsize2"
              style={{ marginBottom: 14 }}
            >
              Forgot your password?
            </Typography>
            <Typography
              className="primarycolor regulerfont"
              style={{ marginBottom: 18 }}
            >
              Don't worry if you've forgotten your password. We can easily reset
              it for you.
            </Typography>
            <TextField
              placeholder="Email"
              value={email}
              onChange={(v) => {
                setEmail(v.target.value);
                setEmailErr(false);
              }}
              style={{ marginBottom: 30 }}
              error={emailErr}
              helperText={emailErr ? "please enter valid email" : null}
            />
            <Button
              variant="contained"
              endIcon={loader ? null : <ArrowForward />}
              onClick={() => {
                checkValidation();
              }}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={24} />
              ) : (
                "RESET MY PASSWORD"
              )}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MForgot;
