import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Add, ArrowForward, Close } from "@mui/icons-material";
import { isArray, isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MJob from "../../../Components/Jobs/MJob";
import { Setting } from "../../../Utils/Setting";
import { getApiData } from "../../../Utils/APIHelper";
import useStyles from "./styles";

export default function MatchingJob() {
  const classes = useStyles();
  const location = useLocation();
  const { token } = useSelector((state) => state.auth);
  const jobId = location?.state?.jobId || null;
  const [filter, setFilter] = useState(false);
  const [selectedTags, setSelectedTags] = useState(["Excel", "Xerox"]);
  const [jobs, setJobs] = useState({
    data: [],
    pageData: {},
  });
  const [pageLoad, setPageLoad] = useState(true);

  useEffect(() => {
    document.title = Setting.page_name.EMPLOYER_MATCHING_JOB;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (jobId) {
      getMatchingJobList();
    } else {
      setPageLoad(false);
    }
  }, [jobId]);

  // this function for get matching job list
  async function getMatchingJobList() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.jobGetSortedCandidate}?job_post_id=${jobId}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data.rows)) {
          setJobs({
            data: response?.data?.rows || [],
            pageData: response?.data?.pagination,
          });
        }
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  }

  // this function renders single tag view
  function singleTag(text = "", type = "") {
    return (
      <div className={classes.tagViewStyle}>
        <Typography variant="body2" style={{ color: "#5A5A5A" }}>
          {text || "-"}
        </Typography>
        <div className={classes.closeIconDivStyle}>
          {type === "close" ? (
            <Close className={classes.closeIconStyle} />
          ) : (
            <Add className={classes.closeIconStyle} />
          )}
        </div>
      </div>
    );
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.titleSection}>
        <Typography className={classes.sectionTitle}>View Matching</Typography>
        <Button
          variant={filter ? "contained" : "outlined"}
          color="primary"
          endIcon={<ArrowForward />}
          onClick={() => setFilter(!filter)}
        >
          Refine List
        </Button>
      </div>
      {filter && (
        <Grid
          item
          xs={12}
          className={[
            classes.shadowViewStyle,
            classes.paddingStyle,
            classes.marginDiv,
          ]}
        >
          <Typography className={classes.sectionTitle}>
            Boost matches based on specific search terms:
          </Typography>
          <Typography style={{ fontFamily: "PoppinsMedium" }}>
            Add max 4 search terms{" "}
            <span className={classes.sectionDesc}>(2 search terms left)</span>
          </Typography>
          <div className={classes.searchTagDiv}>
            <TextField
              variant="outlined"
              placeholder="Search"
              className={classes.inputDivStyle}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div className={classes.cViewStyle}>Add</div>
                  </InputAdornment>
                ),
              }}
            />
            <div
              className={classes.skillDivStyle}
              style={{ marginTop: 0, marginLeft: 30 }}
            >
              {isArray(selectedTags) &&
                selectedTags.length > 0 &&
                selectedTags.map((item) => {
                  return singleTag(item, "close");
                })}
            </div>
          </div>
          <Typography className={classes.sectionDesc}>
            Suggestions generated from your job posting
          </Typography>
          <div className={classes.skillDivStyle}>
            {singleTag("MS Word")}
            {singleTag("Xerox")}
          </div>
        </Grid>
      )}
      {pageLoad ? (
        <div className={classes.loaderView}>
          <CircularProgress size={50} />
        </div>
      ) : isArray(jobs?.data) && jobs?.data.length > 0 ? (
        jobs?.data.map((item) => {
          return <MJob data={item} />;
        })
      ) : (
        <div className={classes.noDataView}>
          <Typography className={classes.sectionTitle}>
            No Jobs available
          </Typography>
        </div>
      )}
    </Grid>
  );
}
