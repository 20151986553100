import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Select,
  MenuItem,
  FormControl,
  Modal,
  TextField,
  Button,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { ArrowForward, ArrowBackOutlined } from "@mui/icons-material";
import Images from "../../../../Config/Images";
import useStyles from "../../styles";
import { isEmpty } from "lodash";

const errorObj = {
  firstNameErr: false,
  lastNameErr: false,
  emailErr: false,
  phoneErr: false,
  cityErr: false,
  firstNameMsg: "",
  lastNameMsg: "",
  emailMsg: "",
  phoneMsg: "",
  cityMsg: "",
};

function BasicInfo(props) {
  const {
    visible = false,
    handleModal = () => null,
    profileDetail = {},
    DataSave = () => null,
    loader = false,
  } = props;

  const classes = useStyles();
  const { dropdowndata } = useSelector((state) => state.auth);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [city, setCity] = useState("");
  const [errObj, setErrObj] = useState(errorObj);

  useEffect(() => {
    if (visible) {
      setFirstName(profileDetail?.firstname || "");
      setLastName(profileDetail?.lastname || "");
      setEmail(profileDetail?.email || "");
      setNumber(profileDetail?.phone || "");
      setCity(profileDetail?.city_id || "");
    }
  }, [visible]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  // this function for reset form data
  function resetFormData(type = "") {
    setFirstName("");
    setLastName("");
    setEmail("");
    setNumber("");
    setCity("");
    setErrObj(errorObj);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(firstName)) {
      valid = false;
      error.firstNameErr = true;
      error.firstNameMsg = "Please enter first name";
    } else if (isEmpty(lastName)) {
      valid = false;
      error.lastNameErr = true;
      error.lastNameMsg = "Please enter last name";
    } else if (isEmpty(email)) {
      valid = false;
      error.emailErr = true;
      error.emailMsg = "Please enter email";
    } else if (isEmpty(number)) {
      valid = false;
      error.phoneErr = true;
      error.phoneMsg = "Please enter phone number";
    } else if (isEmpty(city.toString())) {
      valid = false;
      error.cityErr = true;
      error.cityMsg = "Please select city";
    }

    setErrObj(error);

    if (valid) {
      const infoData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        number: number,
        city: city,
      };
      DataSave(infoData);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle}>
            <Typography
              variant="subtitle1"
              component="h2"
              className={classes.modalTitle}
            >
              Add Basic Details
            </Typography>
            <img
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className={classes.formDivStyle}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter First name"
              error={errObj.firstNameErr}
              helperText={errObj.firstNameErr ? errObj.firstNameMsg : null}
              className={classes.inputFieldStyle}
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                setErrObj({ ...errObj, firstNameErr: false, firstNameMsg: "" });
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Last name"
              error={errObj.lastNameErr}
              helperText={errObj.lastNameErr ? errObj.lastNameMsg : null}
              className={classes.inputFieldStyle}
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setErrObj({ ...errObj, lastNameErr: false, lastNameMsg: "" });
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter email address"
              error={errObj.emailErr}
              helperText={errObj.emailErr ? errObj.emailMsg : null}
              className={classes.inputFieldStyle}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrObj({ ...errObj, emailErr: false, emailMsg: "" });
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter mobile number"
              error={errObj.phoneErr}
              helperText={errObj.phoneErr ? errObj.phoneMsg : null}
              className={classes.inputFieldStyle}
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
                setErrObj({ ...errObj, phoneErr: false, phoneMsg: "" });
              }}
            />
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.inputFieldStyle}
            >
              <Select
                IconComponent={() => (
                  <img src={Images.angle} className={classes.angleBtnStyle} />
                )}
                error={errObj.cityErr}
                onChange={(e) => {
                  setCity(e.target.value);
                  setErrObj({ ...errObj, cityErr: false, cityMsg: "" });
                }}
                value={city}
                displayEmpty
                style={city === "" ? { color: "#A2A2A2" } : {}}
              >
                <MenuItem value="" disabled>
                  Select your city
                </MenuItem>
                {dropdowndata.city.map((data) => (
                  <MenuItem key={`city_${data?.value}`} value={data.value}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>

              {errObj.cityErr ? (
                <FormHelperText style={{ color: "red" }}>
                  {errObj.cityMsg}
                </FormHelperText>
              ) : null}
            </FormControl>
          </div>
          <div className={classes.splitViewStyle}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ArrowBackOutlined />}
              className={classes.modalBtnStyle}
              onClick={() => resetFormData()}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              endIcon={loader ? null : <ArrowForward />}
              className={classes.modalBtnStyle}
              onClick={() => {
                checkValidation();
              }}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={26} />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default BasicInfo;
