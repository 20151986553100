import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import useStyles from "../styles";
import Images from "../../../Config/Images";
import MEmployment from "../../Profile/Modal/MEmployment";
import { isEmpty } from "lodash";

function EmploymentType(props) {
  const { setData = () => null, data = {} } = props;
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState({});

  const isComplete = !isEmpty(editData);
  const isDataEmpty = !isEmpty(data);

  useEffect(() => {
    setData(editData);
  }, [editData]);

  useEffect(() => {
    if (isDataEmpty) {
      setEditData(
        {
          jobType: data?.job_role_type,
          perYear: data?.charge_per_year,
          perHour: data?.charge_per_hour,
          bothPerYear: data?.charge_per_year,
          bothPerHour: data?.charge_per_hour,
          data: data?.employement_description,
        } || {}
      );
    }
  }, [data]);

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="emptype" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>
          Employment type
        </Typography>
        {isComplete ? (
          <div
            className={classes.editBtnStyle}
            onClick={() => setOpenModal(true)}
          >
            <img src={Images.edit} style={{ width: 14 }} />
          </div>
        ) : (
          <AddCircle
            className={classes.addIcon}
            onClick={() => {
              setOpenModal(true);
            }}
          />
        )}
      </div>
      {isComplete ? (
        <div style={{ marginTop: 10 }}>
          {editData?.jobType === "Full-Time" ? (
            <Typography className="regulerfont primarycolor">
              {`Full-Time :- £${editData?.perYear} per year`}
            </Typography>
          ) : editData?.jobType === "Part-Time" ? (
            <Typography className="regulerfont primarycolor">
              {`Part-Time :- £${editData?.perHour} per hour`}
            </Typography>
          ) : (
            <>
              <Typography className="regulerfont primarycolor">
                {`Full-Time :- £${editData?.bothPerYear} per year`}
              </Typography>
              <Typography className="regulerfont primarycolor">
                {`Part-Time :- £${editData?.bothPerHour} per hour`}
              </Typography>
            </>
          )}
          <div
            style={{ marginTop: 10 }}
            dangerouslySetInnerHTML={{
              __html: editData?.data || "",
            }}
          />
        </div>
      ) : (
        <Typography className={classes.sectionDesc}>Add data</Typography>
      )}
      <MEmployment
        visible={openModal}
        handleModal={() => {
          setOpenModal(false);
        }}
        onSave={(data) => {
          setEditData(data);
          setOpenModal(false);
        }}
        editData={editData}
      />
    </Grid>
  );
}

export default EmploymentType;
