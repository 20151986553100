import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import MJobType from "../Modal/MJobType";
import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import useStyles from "../styles";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Setting } from "../../../Utils/Setting";
import { getAPIProgressData } from "../../../Utils/APIHelper";

function JobType(props) {
  const { from = "", data = {}, getProfileData = () => {} } = props;
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const { token } = useSelector((state) => state.auth);

  const header = { Authorization: `Bearer ${token}` };

  async function userJobType(data) {
    setLoader(true);
    const jobTypeData = {
      "JobTypeForm[job_role_type]": data?.jobType,
      "JobTypeForm[charge_per_year]": data?.yearlySalary,
      "JobTypeForm[charge_per_hour]": data?.hourlySalary,
    };

    try {
      let endPoint = Setting.endpoints.employeeJobType;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        jobTypeData,
        header
      );
      if (response?.status) {
        setModal(false);
        getProfileData();
        toast.success(response?.message);
        setLoader(false);
      } else {
        //display error msg here
        toast.error(response?.message);
        setLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setLoader(false);
    }
  }

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="jobtype" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>Job Type</Typography>
        {from === "employee" ? null : data?.job_role_type !== "" ? (
          <div className={classes.editBtnStyle} onClick={() => setModal(true)}>
            <img src={Images.edit} style={{ width: 14 }} />
          </div>
        ) : (
          <AddCircle
            color={color.primary}
            className={classes.addIcon}
            onClick={() => setModal(true)}
          />
        )}
      </div>
      {data?.job_role_type === "Full-Time" ? (
        <Typography className={classes.sectionDesc}>
          Full-time{" "}
          <span style={{ marginLeft: 50 }}>
            £{data?.charge_per_year || 0} per year
          </span>
        </Typography>
      ) : data?.job_role_type === "Part-Time" ? (
        <Typography className={classes.sectionDesc}>
          Part-time{" "}
          <span style={{ marginLeft: 50 }}>
            £{data?.charge_per_hour || 0} per Hour
          </span>
        </Typography>
      ) : data?.job_role_type === "Both" ? (
        <>
          <Typography className={classes.sectionDesc}>
            Full-time{" "}
            <span style={{ marginLeft: 50 }}>
              £{data?.charge_per_year || 0} per year
            </span>
          </Typography>
          <Typography className={classes.sectionDesc}>
            Part-time{" "}
            <span style={{ marginLeft: 50 }}>
              £{data?.charge_per_hour || 0} per Hour
            </span>
          </Typography>
        </>
      ) : (
        <Typography className={classes.sectionDesc}>Add data</Typography>
      )}
      <MJobType
        visible={modal}
        handleModal={() => setModal(false)}
        data={data}
        onSave={(data) => {
          console.log("data ====>>>>>", data);
          userJobType(data);
        }}
        loader={loader}
      />
    </Grid>
  );
}
export default JobType;
