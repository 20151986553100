import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "2% !important",
    marginBottom: "50px !important",
  },
  buttonStyle: {
    width: "300px",
    "@media (max-width:640px)": {
      width: "280px",
    },
    "@media (max-width:600px)": {
      width: "240px",
    },
    "@media (max-width:525px)": {
      width: "200px",
    },
    "@media (max-width:425px)": {
      width: "180px",
    },
    "@media (max-width:375px)": {
      width: "160px",
    },
  },
  fullWidth: {
    "@media (max-width:425px)": {
      width: "100%",
    },
  },
  bottomView: {
    "@media (max-width:768px)": {
      position: "absolute",
      bottom: 80,
      left: 0,
      right: 0,
      padding: "0px 16px",
    },
  },
  loaderView: {
    width: "100%",
    minHeight: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
