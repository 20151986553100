import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { find, isArray, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import MSkills from "../Modal/MSkills";
import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import { getApiData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import useStyles from "../styles";

function Skills(props) {
  const {
    from = "",
    data = {},
    data2 = {},
    updateProfileState = () => null,
    employer = false,
    setData = () => null,
  } = props;
  const { token } = useSelector((state) => state.auth);
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [skillsList, setSkillsList] = useState([]);
  const header = { Authorization: `Bearer ${token}` };

  const [editData, setEditData] = useState({});
  const isEmplyee = from === "employee";
  const isComplete = !isEmpty(editData);
  const isDataEmpty = !isEmpty(data2);
  const isRole_id = !isEmpty(data);

  useEffect(() => {
    if (isDataEmpty) {
      setSkills();
    }
  }, [data2, skillsList]);

  useEffect(() => {
    setData(editData);
  }, [editData]);

  useEffect(() => {
    if (token !== "" && isRole_id) {
      getSkillsList();
    }
  }, [token, data]);

  function setSkills() {
    const dummyArr = [];
    skillsList.map((ii) => {
      data2?.skills.map((ii2) => {
        if (Number(ii?.value) === Number(ii2)) {
          dummyArr.push(ii);
        }
      });
    });
    setEditData(dummyArr);
  }

  // this function for get skills list
  async function getSkillsList() {
    let endPoint = `${Setting.endpoints.employeeSkills}?job_role_id=${data?.job_role_id}`;
    try {
      const response = await getApiData(endPoint, "GET", null, header);

      if (response.status) {
        if (
          !isEmpty(response.data) &&
          isArray(response?.data?.skills) &&
          response?.data?.skills.length > 0
        ) {
          setSkillsList(response?.data?.skills);
        }
      }
    } catch (error) {
      console.log("error ===>>", error);
    }
  }

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="skills" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>Skills</Typography>
        {from === "employee" ? null : (isArray(data?.skills) &&
            data?.skills.length > 0) ||
          isComplete ? (
          <div className={classes.editBtnStyle} onClick={() => setModal(true)}>
            <img src={Images.edit} style={{ width: 14 }} />
          </div>
        ) : (
          <AddCircle
            color={color.primary}
            className={classes.addIcon}
            onClick={() => setModal(true)}
          />
        )}
      </div>
      {(isArray(data?.skills) && data?.skills.length > 0) || isComplete ? (
        <div className={classes.contentDetailContainer}>
          <div className={classes.skillDivStyle}>
            {employer
              ? editData.map((item) => {
                  const value = find(
                    skillsList,
                    (key) => Number(key.value) === Number(item.value)
                  );
                  if (value) {
                    return (
                      <div
                        key={`skill${item.label}_s`}
                        className={classes.tagViewStyle}
                      >
                        <Typography
                          variant="body2"
                          style={{ color: "#5A5A5A" }}
                        >
                          {value?.label}
                        </Typography>
                      </div>
                    );
                  }
                })
              : data?.skills.map((item) => {
                  const value = find(
                    skillsList,
                    (key) => Number(key.value) === Number(item)
                  );
                  if (value || isEmplyee) {
                    return (
                      <div
                        key={`skill${item.label}_s`}
                        className={classes.tagViewStyle}
                      >
                        <Typography
                          variant="body2"
                          style={{ color: "#5A5A5A" }}
                        >
                          {isEmplyee ? item : value?.label}
                        </Typography>
                      </div>
                    );
                  }
                })}
          </div>
        </div>
      ) : (
        <Typography className={classes.sectionDesc}>
          {from === "employee"
            ? "-"
            : "Strengthen your profile by adding skills."}
        </Typography>
      )}
      <MSkills
        visible={modal}
        list={skillsList}
        data={employer && isComplete ? editData : data?.skills || []}
        handleModal={(detail, type) => {
          if (type === "update" && updateProfileState) {
            updateProfileState(detail, "skills");
          }
          setModal(false);
        }}
        onSave={(data) => {
          setEditData(data);
          setModal(false);
        }}
        isDataEmpty={isDataEmpty}
        employer={employer}
      />
    </Grid>
  );
}
export default Skills;
