import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { Button, CircularProgress } from "@mui/material";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { Setting } from "../../Utils/Setting";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      marginBottom: 20,
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function CheckoutForm(props) {
  const { handleModal = () => null, type = "", btnLoad = false } = props;
  const { token } = useSelector((state) => state.auth);
  const stripe = useStripe();
  const elements = useElements();
  const [btnLoading, setBtnLoading] = useState(false);

  function handleCard(event) {
    if (event.error) {
      let error = event.error.message;
      if (event.error.code) {
        if (event.error.code === "invalid_number") {
          error = "Your card number is not valid. Please check and try again.";
        } else if (event.error.code === "invalid_expiry_year_past") {
          error =
            "The card expiry date you have entered has already passed. Please check and try again.";
        }
      }
      if (event.error.code !== "incomplete_number") {
        toast.error(error);
      }
    }
  }

  // Function tfor get card token from stripe
  async function getCardToken() {
    const result = await stripe.createToken(elements.getElement(CardElement));
    // Handle result.error or result.token
    if (!isEmpty(result) && !isEmpty(result.token) && result.token.id) {
      return result.token.id;
    }
    toast.error(
      result.error && result.error.message
        ? result.error.message
        : "Something went wrong."
    );
    return "";
  }

  // Function for handel form submit action - add new card
  const handleNewCard = async (event) => {
    setBtnLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    try {
      const cardToken = await getCardToken();

      if (!isEmpty(cardToken)) {
        if (type === "payment") {
          handleModal("success", cardToken);
        } else {
          const data = {
            token: cardToken,
          };
          const response = await getAPIProgressData(
            Setting.endpoints.paymentCreateCard,
            "POST",
            data,
            {
              Authorization: `Bearer ${token}`,
            }
          );

          if (response.status) {
            handleModal("success", response?.data?.card_token);
          } else {
            toast.error(response.message);
          }
        }
      } else {
        toast.error("Card token is empty");
      }
      setBtnLoading(false);
    } catch (error) {
      console.log("add new card err ===", error);
      toast.error(error || "something went wrong");
      setBtnLoading(false);
    }
  };

  return (
    <form onSubmit={handleNewCard} style={{ width: "100%" }}>
      <div
        style={{ border: "1px solid #34567b", padding: 12, borderRadius: 3 }}
      >
        <CardElement
          options={CARD_ELEMENT_OPTIONS}
          onChange={(event) => handleCard(event)}
        />
      </div>
      {btnLoad ? null : (
        <div
          style={{
            marginTop: 18,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={handleModal}
            disabled={btnLoading}
          >
            Cancel
          </Button>
          <Button
            variant={btnLoading ? "outlined" : "contained"}
            disabled={!stripe || btnLoading}
            type="submit"
          >
            {btnLoading ? <CircularProgress size={24} /> : "Add card details"}
          </Button>
        </div>
      )}
    </form>
  );
}
