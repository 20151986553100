import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Select,
  MenuItem,
  FormControl,
  Modal,
  Button,
  CircularProgress,
} from "@mui/material";
import { ArrowForward, ArrowBackOutlined, Close } from "@mui/icons-material";
import { isArray, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { Setting } from "../../../../Utils/Setting";
import Images from "../../../../Config/Images";
import useStyles from "../../styles";

function MSkills(props) {
  const {
    visible = false,
    handleModal = () => null,
    list = [],
    data = [],
    employer = false,
    onSave = () => null,
  } = props;
  const { token } = useSelector((state) => state.auth);
  const classes = useStyles();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [btnLoad, setBtnLoad] = useState(false);
  const isListFill = !isEmpty(list);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (!isListFill && visible) {
      toast.error("Please fill job details first");
    }
  }, [visible]);

  useEffect(() => {
    const arr = [];
    if ((visible && isArray(data) && data.length > 0) || employer) {
      data.map((item) => {
        if (isArray(list) && list.length > 0) {
          list.map((key) => {
            if (Number(employer ? item.value : item) === Number(key.value)) {
              arr.push(key);
            }
          });
        }
      });
    }
    setSelectedSkills(arr);
  }, [visible]);

  function checkValidation() {
    let valid = true;

    if (isEmpty(selectedSkills)) {
      valid = false;
      toast.error("Please select skills");
    }

    if (valid) {
      onSave(selectedSkills);
    }
  }

  // this function for remove skill from selected skill
  function removeSkill(index) {
    const arr = [...selectedSkills];
    arr.splice(index, 1);
    setSelectedSkills(arr);
  }

  // this function for reset form data
  function resetFormData(type = "", data = []) {
    setSelectedSkills([]);
    if (type === "close" && handleModal) {
      handleModal(data, "close");
    } else if (type === "update" && handleModal) {
      handleModal(data, "update");
    }
  }

  // this function for submit data to server
  async function submitData() {
    if (isArray(selectedSkills) && selectedSkills.length > 0) {
      setBtnLoad(true);
      const obj = {};
      selectedSkills.map((item, index) => {
        obj[`SkillForm[skills][${index}]`] = item.value;
      });

      try {
        const response = await getAPIProgressData(
          Setting.endpoints.employeeAddSkills,
          "POST",
          obj,
          { Authorization: `Bearer ${token}` }
        );

        if (response.status) {
          toast.success(response.message);
          resetFormData("update", response?.data?.skills);
        } else {
          toast.error(response.message);
        }
        setBtnLoad(false);
      } catch (error) {
        console.log("error ===>>", error);
        setBtnLoad(false);
      }
    } else {
      toast.error("Please select skills");
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle}>
            <Typography variant="subtitle1" component="h2">
              Add Skills
            </Typography>
            <img
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className={classes.formDivStyle}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.inputFieldStyle}
            >
              <Select
                IconComponent={() => (
                  <img src={Images.angle} className={classes.angleBtnStyle} />
                )}
                displayEmpty
                // style={selectedSkills === "" ? { color: "#A2A2A2" } : {}}
                onChange={(e) => setSelectedSkills(e.target.value)}
                value={selectedSkills}
                multiple
              >
                <MenuItem disabled value="">
                  Select skills
                </MenuItem>
                {isArray(list) &&
                  list.length > 0 &&
                  list.map((item) => (
                    <MenuItem key={`skill_${item.value}`} value={item}>
                      {item?.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.skillDivStyle}>
            {isArray(selectedSkills) &&
              selectedSkills.length > 0 &&
              selectedSkills.map((item, index) => {
                if (item.value === "") {
                  return null;
                }
                return (
                  <div
                    key={`select_skill_${item.value}`}
                    className={classes.tagViewStyle}
                  >
                    <Typography variant="body2" style={{ color: "#5A5A5A" }}>
                      {item.label}
                    </Typography>
                    <div
                      className={classes.closeIconDivStyle}
                      onClick={() => removeSkill(index)}
                    >
                      <Close className={classes.closeIconStyle} />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={classes.splitViewStyle}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackOutlined />}
              className={classes.modalBtnStyle}
              onClick={resetFormData}
              disabled={btnLoad}
            >
              Reset
            </Button>
            <Button
              variant={btnLoad ? "outlined" : "contained"}
              endIcon={btnLoad ? null : <ArrowForward />}
              className={classes.modalBtnStyle}
              onClick={() => {
                employer ? checkValidation() : submitData();
              }}
              disabled={btnLoad}
            >
              {btnLoad ? <CircularProgress size={28} /> : "Save"}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default MSkills;
