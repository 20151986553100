import { makeStyles } from "@mui/styles";
import { color } from "../../Config/theme";

const useStyles = makeStyles((theme) => ({
	footer_main_con: {
		display: "flex",
		padding: "25px 0px",
		flexDirection: "column",
		backgroundColor: "#34567b",
	},
	footer_header_title: {
		color: color.white,
		fontSize: "15px !important",
		letterSpacing: "0.5px !important",
		lineHeight: "25px !important",
		fontFamily: "PoppinsSemiBold !important",
	},
	footer_sub_title: {
		color: color.offWhite,
		fontSize: "12px !important",
		fontWeight: 300,
		letterSpacing: "0.5px !important",
		lineHeight: "25px !important",
		fontFamily: "PoppinsLight !important",
	},
	footer_copy_right: {
		fontSize: "13px !important",
	},
	anchor_titles: {
		cursor: "pointer",
		textDecoration: "none",
	},
	icons_con: {
		padding: '0px',
		width: '35px',
		height: '35px',
		display: 'flex',
		borderWidth: 0.5,
		borderStyle: "solid",
		borderRadius: 5,
		borderColor: color.offWhite,
	},
	ML10: {
		marginLeft: '10px !important',
	},
	MT10: {
		marginTop: '10px !important',
	},
	MT20: {
		marginTop: '20px !important',
	},
	icons_color: {
		color: `${color.offWhite} !important`,
	},
}));

export default useStyles;
