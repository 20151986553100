import React from "react";
import { Typography, Button, Grid, CircularProgress } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { isEqual } from "lodash";
import useStyles from "../styles";

function JobDetail(props) {
  const {
    data = {},
    activeTab = "1",
    updateStatus = () => null,
    interestBtnLoad = {},
  } = props;
  const classes = useStyles();

  const btnLoad = isEqual(data, interestBtnLoad);
  return (
    <Grid container className={classes.container}>
      <Grid xs={12} style={{ marginBottom: 15 }}>
        <Typography className={classes.sectionTitle}>
          {data?.company_name || data?.employer_details?.company_name || ""}
        </Typography>
        <Typography style={{ fontFamily: "PoppinsMedium" }}>
          {data?.title || ""}
        </Typography>
      </Grid>
      <Grid xs={12} sm={3} className={classes.singleDetail}>
        <Typography className={classes.sectionDesc}>
          {data?.rate ? "Rate" : "Salary"}
        </Typography>
        <Typography className={classes.sectionSubDetail}>
          {data?.rate ? data?.rate : data?.charge_per_year || "0"}
        </Typography>
      </Grid>
      <Grid xs={12} sm={2} md={3} className={classes.singleDetail}>
        <Typography className={classes.sectionDesc}>Location</Typography>
        <Typography className={classes.sectionSubDetail}>
          {data?.location || "-"}
        </Typography>
      </Grid>
      <Grid xs={12} sm={2} className={classes.singleDetail}>
        <Typography className={classes.sectionDesc}>Start</Typography>
        <Typography className={classes.sectionSubDetail}>
          {data?.start_at || "-"}
        </Typography>
      </Grid>
      <Grid xs={12} sm={2} className={classes.singleDetail}>
        <Typography className={classes.sectionDesc}>Duration</Typography>
        <Typography className={classes.sectionSubDetail}>
          {data?.job_role_type === "Full-Time"
            ? "Permanent"
            : "Part Time" || "-"}
        </Typography>
      </Grid>
      <Grid xs={12} sm={3} md={2} className={classes.centeredDetail}>
        <Button
          variant={btnLoad ? "outlined" : "contained"}
          color="primary"
          endIcon={btnLoad ? null : <ArrowForward />}
          fullWidth
          className={`${classes.btnStyle} ${
            activeTab === "2" ? classes.respBtn : ""
          }`}
          onClick={() => updateStatus(data, activeTab)}
          disabled={btnLoad}
        >
          {btnLoad ? (
            <CircularProgress size={28} />
          ) : activeTab === "2" ? (
            "Not Interested"
          ) : (
            "Interested"
          )}
        </Button>
      </Grid>
    </Grid>
  );
}

export default JobDetail;
