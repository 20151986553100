import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Grid,
  Typography,
  Button,
  Radio,
  RadioGroup,
  CircularProgress,
} from "@mui/material";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { find, findIndex, isArray, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CStepper from "../../../Components/CStepper";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles";

export default function Quiz() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { userdata, token } = useSelector((state) => state.auth);
  const [activeStep, setActiveStep] = useState(0);
  const [finalAnswers, setFinalAnswers] = useState([]);
  const [selectedQue, setSelectedQue] = useState({});
  const [btnLoad, setBtnLoad] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [pageLoad, setPageLoad] = useState(true);
  const lastIndex = questionList.length - 1;

  useEffect(() => {
    document.title = Setting.page_name.QUIZ;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getQuestionList();
  }, []);

  // this function for get questions
  async function getQuestionList() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.jobQuestionList}?job_id=${userdata?.job_role_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response?.status) {
        if (isArray(response.data) && response.data.length > 0) {
          setQuestionList(response.data);
        }
      } else {
        toast.error(response.message || "Oops! Something went wrong");
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>", error);
      toast.error(error.toString());
      setPageLoad(false);
    }
  }

  // this function for submit quiz
  async function submitData() {
    setBtnLoad(true);
    const obj = {};

    if (isArray(finalAnswers) && finalAnswers.length > 0) {
      finalAnswers.map((item) => {
        obj[item.key] = item.ans;
      });
    }

    try {
      const response = await getAPIProgressData(
        Setting.endpoints.jobStoreResult,
        "POST",
        obj,
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        toast.success(response.message);
        navigate(-1);
      } else {
        toast.error(response.message || "Oops! Something went wrong");
      }
      setBtnLoad(false);
    } catch (error) {
      console.log("error ===>>", error);
      toast.error(error.toString());
      setBtnLoad(false);
    }
  }

  // this function for handle steps
  function handleSteps(data = {}) {
    if (isEmpty(data)) {
      toast.error("Please select answer first");
      return true;
    } else {
      const nArr = [...finalAnswers];

      if (isArray(nArr) && nArr.length > 0) {
        const nIndex = findIndex(
          nArr,
          (item) => item.question === data.question
        );

        if (nIndex > -1) {
          nArr[nIndex].ans = data.ans;
        } else {
          nArr.push(data);
        }
        setFinalAnswers(nArr);
      } else {
        setFinalAnswers([data]);
      }
    }

    if (lastIndex === activeStep) {
      submitData();
    } else {
      setActiveStep((step) => step + 1);
      setSelectedQue({});
    }
  }

  // this function for handle back button
  function handleBack() {
    const nIndex = activeStep - 1;
    if (nIndex >= 0) {
      const nData = find(
        finalAnswers,
        (item) => item.qId === questionList[nIndex].id
      );
      if (!isEmpty(nData)) {
        setSelectedQue(nData);
      }
    }
    setActiveStep((step) => step - 1);
  }

  // this function renders questions
  function renderQuestionDiv() {
    if (isArray(questionList) && questionList.length > 0) {
      return questionList.map((item, key) => {
        if (activeStep === key) {
          const obj = {
            key: `Question[${item.id}]`,
            qId: item.id,
            question: item.question,
            ans: null,
          };
          return (
            <>
              <Grid className={classes.fullWidth}>
                <Typography
                  className="primarycolor fontsize"
                  style={{ marginBottom: 20 }}
                >
                  {item.question || ""}
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  {isArray(item?.options) &&
                    item.options.length > 0 &&
                    item.options.map((opt, index) => {
                      const optVal = opt.id; // `op_${index + 1}`;

                      const checked =
                        !isEmpty(selectedQue) &&
                        selectedQue?.question === item.question &&
                        selectedQue?.ans === optVal;

                      return (
                        <div
                          className="radiomain"
                          style={{ marginBottom: 15, padding: "0px 15px" }}
                        >
                          <FormControlLabel
                            value={optVal}
                            control={<Radio />}
                            // label={`${opt[`op_${index + 1}`]}`}
                            label={opt.value}
                            onChange={() => {
                              obj.ans = opt.id; // `op_${index + 1}`;
                              setSelectedQue(obj);
                            }}
                            checked={checked}
                          />
                        </div>
                      );
                    })}
                </RadioGroup>
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent={activeStep === 0 ? "flex-end" : "space-between"}
                style={{ marginTop: 30 }}
                className={classes.bottomView}
              >
                {activeStep === 0 ? null : (
                  <Button
                    onClick={handleBack}
                    startIcon={<ArrowBack />}
                    variant="outlined"
                    className={classes.buttonStyle}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  onClick={() => {
                    handleSteps(selectedQue);
                  }}
                  endIcon={btnLoad ? null : <ArrowForward />}
                  variant={btnLoad ? "outlined" : "contained"}
                  className={classes.buttonStyle}
                  disabled={btnLoad}
                >
                  {btnLoad ? (
                    <CircularProgress size={28} />
                  ) : lastIndex === activeStep ? (
                    "Submit"
                  ) : (
                    "next"
                  )}
                </Button>
              </Grid>
            </>
          );
        }
      });
    }
  }

  return (
    <Grid container className={classes.container}>
      {pageLoad ? (
        <div className={classes.loaderView}>
          <CircularProgress size={50} />
        </div>
      ) : isArray(questionList) && questionList.length === 0 ? (
        <div className={classes.loaderView}>
          <Typography className={classes.sectionTitle}>
            No Result Found
          </Typography>
        </div>
      ) : (
        <>
          <Grid item xs={12} style={{ marginBottom: 30 }}>
            <CStepper data={questionList} activeStep={activeStep} />
          </Grid>
          {renderQuestionDiv()}
        </>
      )}
    </Grid>
  );
}
