import React, { useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  OutlinedInput,
  Grid,
} from "@mui/material";
import useStyles from "../../styles";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import Images from "../../../../Config/Images";
import CDropDown from "../../../CDropDown";
import { dummy_data } from "../../../../Config/Static_Data";
import _ from "lodash";

function MDetail(props) {
  const { visible = false, handleModal = () => null } = props;
  const { dropdowndata } = useSelector((state) => state.auth);
  const [companySize, setCompanySize] = useState("");
  const [culture, setCulture] = useState("");
  const [sector, setSector] = useState("");
  const [website, setWebsite] = useState("");

  const [fbUrl, setFbUrl] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: 700,
  };

  function resetFormData(type = "") {
    setCompanySize("");
    setCulture("");
    setSector("");
    setWebsite("");
    setFbUrl("");
    setInstaUrl("");
    setLinkedInUrl("");
    setTwitterUrl("");
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography variant="subtitle1" component="h2">
              Edit Details
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className="sliderscroll" style={{ height: 500 }}>
            <CDropDown
              data={dropdowndata?.company_size || []}
              value={companySize}
              onChange={(v) => {
                setCompanySize(v.target.value);
              }}
              placeholder="Select company size"
              label="Select the company size"
              style={{ width: "100%" }}
            />
            <CDropDown
              data={dropdowndata?.company_culture || []}
              value={culture}
              onChange={(v) => {
                setCulture(v.target.value);
              }}
              placeholder="Select culture"
              label="Select the culture"
              style={{ width: "100%" }}
            />
            <CDropDown
              data={dropdowndata?.sectors || []}
              value={sector}
              onChange={(v) => {
                setSector(v.target.value);
              }}
              placeholder="Select sector or industry"
              label="Select the sector or industry"
              style={{ width: "100%" }}
            />
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Company website
            </Typography>
            <OutlinedInput
              placeholder="Website URL"
              value={website}
              onChange={(v) => {
                setWebsite(v.target.value);
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Social media link
            </Typography>
            <OutlinedInput
              placeholder="Facebook URL"
              value={fbUrl}
              onChange={(v) => {
                setFbUrl(v.target.value);
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
            <OutlinedInput
              placeholder="Instagram URL"
              value={instaUrl}
              onChange={(v) => {
                setInstaUrl(v.target.value);
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
            <OutlinedInput
              placeholder="Linkedin URL"
              value={linkedInUrl}
              onChange={(v) => {
                setLinkedInUrl(v.target.value);
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
            <OutlinedInput
              placeholder="Twitter URL"
              value={twitterUrl}
              onChange={(v) => {
                setTwitterUrl(v.target.value);
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
          </div>
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid xs={5.8} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                startIcon={<ArrowBack />}
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8}>
              <Button
                variant="contained"
                endIcon={<ArrowForward />}
                style={{ width: "100%" }}
              >
                SAVE
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MDetail;
