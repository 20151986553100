import React, { useState } from "react";
import { Typography, Button, Grid } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { color } from "../../../Config/theme";
import useStyles from "../styles";
import MPurchase from "../../Profile/Modal/MPurchase";

function MJob(props) {
  const { data = {} } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  // this function for redirect to profile page
  function viewProfile() {
    navigate("/candidate/profile", { state: { from: "employee" } });
    window.scrollTo(0, 0);
  }

  return (
    <Grid container className={classes.container}>
      <Grid xs={12} sm={3} md={2}>
        <img src={data.image} className={classes.cJobImgStyle} />
      </Grid>
      <Grid xs={12} sm={9} md={10} className={classes.mJobContainerStyle}>
        <Grid container style={{ height: "100%" }}>
          <Grid xs={12}>
            <Typography className={classes.sectionTitle}>
              {data?.name || "-"}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Grid container>
              <Grid xs={12} sm={7} md={9}>
                <Grid container>
                  <Grid
                    xs={12}
                    sm={12}
                    md={6}
                    className={classes.matchSingleJobDetail}
                  >
                    <Typography className={classes.sectionDesc}>
                      Expected Salary
                    </Typography>
                    <Typography className={classes.sectionSubDetail}>
                      £35,000 - £40,000
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={6}
                    className={classes.matchSingleJobDetail}
                  >
                    <Typography className={classes.sectionDesc}>
                      Availability
                    </Typography>
                    <Typography className={classes.sectionSubDetail}>
                      Now
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography
                      className={classes.sectionDesc}
                      style={{ color: "#000" }}
                    >
                      Recent Experience
                    </Typography>
                    <Typography
                      className={classes.sectionDesc}
                      style={{ color: color.primary }}
                    >
                      EVENT HOST
                    </Typography>
                    <Typography className={classes.sectionDesc}>
                      Bingo Lingo Jan-2016 to Oct-2021
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} sm={5} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForward />}
                  fullWidth
                  style={{ marginBottom: 15 }}
                  onClick={viewProfile}
                >
                  View Profile
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<ArrowForward />}
                  fullWidth
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Unlock Profile
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MPurchase
        visible={openModal}
        handleModal={() => {
          setOpenModal(false);
        }}
      />
    </Grid>
  );
}

export default MJob;
