import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import MAvailability from "../Modal/MAvailability";
import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import useStyles from "../styles";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Setting } from "../../../Utils/Setting";
import { getAPIProgressData } from "../../../Utils/APIHelper";

function Availability(props) {
  const { from = "", data = {}, getProfileData = () => {} } = props;
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const { token } = useSelector((state) => state.auth);

  const header = { Authorization: `Bearer ${token}` };

  async function userJobType(data) {
    const datet = moment(data?.date).format("yyyy-MM-DD");
    setLoader(true);
    const availableData = {
      "AvailablityForm[availablity_type]": data?.availability,
      "AvailablityForm[availablity_date]": datet,
    };

    try {
      let endPoint = Setting.endpoints.employeeAvailablity;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        availableData,
        header
      );
      console.log("response ====>>>>>", response);
      if (response?.status) {
        setModal(false);
        getProfileData();
        toast.success(response?.message);
        setLoader(false);
      } else {
        //display error msg here
        toast.error(response?.message);
        setLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setLoader(false);
    }
  }

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="availability" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>Availability</Typography>
        {from === "employee" ? null : data?.availablity_type !== "" ? (
          <div className={classes.editBtnStyle} onClick={() => setModal(true)}>
            <img src={Images.edit} style={{ width: 14 }} />
          </div>
        ) : (
          <AddCircle
            color={color.primary}
            className={classes.addIcon}
            onClick={() => setModal(true)}
          />
        )}
      </div>
      <Typography className={classes.sectionDesc}>
        {from === "employee"
          ? data?.availablity
          : data?.availablity_type === "now"
          ? "I'm available now"
          : data?.availablity_type === "from"
          ? `I'm available from ${data?.availablity_date}`
          : "Not available"}
      </Typography>
      <MAvailability
        visible={modal}
        handleModal={() => setModal(false)}
        loader={loader}
        onSave={(data) => {
          userJobType(data);
        }}
      />
    </Grid>
  );
}
export default Availability;
