import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paperstyle: {
    borderRadius: 5,
    padding: 20,
    boxShadow: "0px 3px 15px #00000029",
    marginBottom: "20px !important",
  },
  editBtnStyle: {
    height: "30px",
    width: "30px",
    borderRadius: "15px",
    background:
      "transparent linear-gradient(228deg, #3A5B7E 0%, #1F4771 100%) 0% 0% no-repeat padding-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    boxShadow: "0px 3px 6px #00000052",
  },
}));

export default useStyles;
