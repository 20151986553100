import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  OutlinedInput,
  useMediaQuery,
  Grid,
  Checkbox,
} from "@mui/material";
import useStyles from "../../styles";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import Images from "../../../../Config/Images";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

function MLocation(props) {
  const {
    visible = false,
    handleModal = () => null,
    onSave = () => null,
    editData = {},
  } = props;

  const [postCode, setPostCode] = useState("");
  const [remoteOnly, setRemoteOnly] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (visible) {
      setPostCode(editData?.postCode || "");
      setRemoteOnly(editData?.remoteOnly || false);
    }

    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  function checkValidation() {
    let valid = true;

    if (!remoteOnly && isEmpty(postCode)) {
      valid = false;
      toast.error("Please enter location or Remote only");
    }

    if (valid) {
      const locationData = {
        postCode: postCode,
        remoteOnly: remoteOnly,
      };
      onSave(locationData);
    }
  }

  function resetFormData(type = "") {
    setPostCode("");
    setRemoteOnly(false);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle1"
              component="h2"
              className={classes.modalTitle}
            >
              Add location
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <OutlinedInput
            placeholder="Enter postcode or loaction"
            value={postCode}
            disabled={remoteOnly}
            onChange={(v) => {
              setPostCode(v.target.value);
            }}
            style={{ marginBottom: 10, width: "100%" }}
          />
          <Typography
            className="primarycolor mediumfont"
            style={{ marginBottom: 8, marginTop: 8 }}
          >
            Remote only
          </Typography>
          <div className="checkboxmain">
            <Checkbox
              checked={remoteOnly}
              onChange={() => {
                setRemoteOnly(!remoteOnly);
              }}
            />
            <Typography className="lightsilvercolor regulerfont">
              This option will connect you with candidates from all over the UK
            </Typography>
          </div>
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid xs={5.8} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                startIcon={<ArrowBack />}
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8}>
              <Button
                variant="contained"
                endIcon={<ArrowForward />}
                style={{ width: "100%" }}
                onClick={() => {
                  checkValidation();
                }}
              >
                SAVE
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MLocation;
