import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  TextField,
  InputAdornment,
  Grid,
} from "@mui/material";
import useStyles from "../../styles";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import Images from "../../../../Config/Images";
import CDropDown from "../../../CDropDown";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { isDate, isEmpty } from "lodash";

const errorObj = {
  sectorErr: false,
  jobRoleErr: false,
  vacanciesErr: false,
  jobTitleErr: false,
  jobCloseDateErr: false,
  sectorMsg: "",
  jobRoleMsg: "",
  vacanciesMsg: "",
  jobTitleMsg: "",
  jobCloseDateMsg: "",
};

function MAboutJob(props) {
  const {
    visible = false,
    handleModal = () => null,
    onSave = () => null,
    editData = {},
  } = props;

  const { dropdowndata } = useSelector((state) => state.auth);
  const [sector, setSector] = useState("");
  const [role, setRole] = useState("");
  const [vacancies, setVacancies] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [closeDate, setCloseDate] = useState(null);
  const [openPicker, setOpenPicker] = useState(false);
  const [errObj, setErrObj] = useState(errorObj);
  const [jobRoleArr, setJobRoleArr] = useState([]);

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 360,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (visible) {
      setSector(editData?.sector || "");
      jobRoleData(editData?.sector);
      setRole(editData?.job_role_id || "");
      setVacancies(editData?.vacancies || "");
      setJobTitle(editData?.jobTitle || "");
      setCloseDate(new Date(editData?.closeDate) || null);
    }
    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  function jobRoleData(value) {
    dropdowndata?.sectors.map((ii) => {
      if (ii?.value === value) {
        setJobRoleArr(ii?.job_roles);
      }
    });
  }

  function resetFormData(type = "") {
    setSector("");
    setRole("");
    setVacancies("");
    setJobTitle("");
    setCloseDate(null);
    setOpenPicker(false);
    setErrObj(errorObj);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(sector.toString())) {
      valid = false;
      error.sectorErr = true;
      error.sectorMsg = "Please select sector";
    }
    if (isEmpty(role.toString())) {
      valid = false;
      error.jobRoleErr = true;
      error.jobRoleMsg = "Please select job role";
    }
    if (isEmpty(vacancies.toString())) {
      valid = false;
      error.vacanciesErr = true;
      error.vacanciesMsg = "Please enter vacancies";
    }
    if (isEmpty(jobTitle)) {
      valid = false;
      error.jobTitleErr = true;
      error.jobTitleMsg = "Please enter job title";
    }
    if (!isDate(closeDate)) {
      valid = false;
      error.jobCloseDateErr = true;
      error.jobCloseDateMsg = "Please select job close date";
    }

    setErrObj(error);

    if (valid) {
      const jobData = {
        sector: sector,
        job_role_id: role,
        vacancies: vacancies,
        jobTitle: jobTitle,
        closeDate: closeDate,
      };
      onSave(jobData);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle1"
              component="h2"
              className={classes.modalTitle}
            >
              Add about your job
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <CDropDown
            data={dropdowndata?.sectors}
            value={sector}
            error={errObj.sectorErr}
            helperText={errObj.sectorMsg}
            onChange={(v) => {
              setSector(v.target.value);
              jobRoleData(v.target.value);
              setRole("");
              setErrObj({ ...errObj, sectorErr: false, sectorMsg: "" });
            }}
            placeholder="Select sector"
            label="Select Sector"
            style={{ width: "100%" }}
          />
          <CDropDown
            data={jobRoleArr}
            value={role}
            error={errObj.jobRoleErr}
            helperText={errObj.jobRoleMsg}
            onChange={(v) => {
              setRole(v.target.value);
              setErrObj({ ...errObj, jobRoleErr: false, jobRoleMsg: "" });
            }}
            placeholder="Select role"
            label="Select Role"
            style={{ width: "100%" }}
          />
          <Typography
            className="primarycolor mediumfont"
            style={{ marginBottom: 8, marginTop: 8 }}
          >
            Vacancies
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Vacancies"
            value={vacancies}
            type="number"
            error={errObj.vacanciesErr}
            helperText={errObj.vacanciesMsg}
            onChange={(v) => {
              setVacancies(v.target.value);
              setErrObj({ ...errObj, vacanciesErr: false, vacanciesMsg: "" });
            }}
            style={{ width: "100%", marginBottom: 8 }}
          />
          <Typography
            className="primarycolor mediumfont"
            style={{ marginBottom: 8, marginTop: 8 }}
          >
            Title for job posting
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Job title"
            value={jobTitle}
            error={errObj.jobTitleErr}
            helperText={errObj.jobTitleMsg}
            onChange={(v) => {
              setJobTitle(v.target.value);
              setErrObj({ ...errObj, jobTitleErr: false, jobTitleMsg: "" });
            }}
            style={{ width: "100%", marginBottom: 8 }}
          />
          <Typography
            className="primarycolor mediumfont"
            style={{ marginBottom: 8, marginTop: 8 }}
          >
            Job close date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={closeDate}
              onChange={(newValue) => {
                setCloseDate(newValue);
                setOpenPicker(false);
                setErrObj({
                  ...errObj,
                  jobCloseDateErr: false,
                  jobCloseDateMsg: "",
                });
              }}
              open={openPicker}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  error={errObj.jobCloseDateErr}
                  helperText={errObj.jobCloseDateMsg}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "Select Date",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          className="datepicker pickerStyle"
                          onClick={() => {
                            setOpenPicker(!openPicker);
                          }}
                        >
                          <img
                            alt="error"
                            src={Images.picker}
                            style={{ width: 24 }}
                          />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid xs={5.8} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                startIcon={<ArrowBack />}
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8}>
              <Button
                variant="contained"
                endIcon={<ArrowForward />}
                style={{ width: "100%" }}
                onClick={() => {
                  checkValidation();
                }}
              >
                SAVE
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MAboutJob;
