import React, { useState } from "react";
import { Typography, Button, Grid } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SendMessageModal from "../../SendMessageModal";
import Images from "../../../Config/Images";
import { color } from "../../../Config/theme";
import useStyles from "../styles";

function OfferDetail(props) {
  const { data = {} } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  // this function for redirect to profile page
  function viewProfile() {
    navigate("/company/profile", { state: { jobId: data?.job_post_id } });
    window.scrollTo(0, 0);
  }

  return (
    <>
      <Grid container className={classes.container}>
        <Grid xs={12} sm={3} md={2} className={classes.offerImgResp}>
          <img
            src={data?.company_logo}
            className={classes.offerImgStyle}
            onError={(e) => {
              e.target.src = Images.heroimg;
            }}
          />
        </Grid>
        <Grid xs={12} sm={9} md={10}>
          <Grid container>
            <Grid xs={12} style={{ marginBottom: 15 }}>
              <Typography className={classes.sectionTitle}>
                {data?.title || ""}
              </Typography>
              <Typography style={{ fontFamily: "PoppinsMedium" }}>
                {data?.sector || ""}
              </Typography>
            </Grid>
            <Grid xs={12} sm={4} md={3} className={classes.singleDetail}>
              <Typography className={classes.sectionDesc}>
                {data?.rate ? "Rate" : "Salary"}
              </Typography>
              <Typography className={classes.sectionSubDetail}>
                {data?.charge_per_hour || "0"}
              </Typography>
            </Grid>
            <Grid xs={12} sm={3} className={classes.singleDetail}>
              <Typography className={classes.sectionDesc}>Location</Typography>
              <Typography className={classes.sectionSubDetail}>
                {data?.location || ""}
              </Typography>
            </Grid>
            <Grid xs={12} sm={2} md={3} className={classes.singleDetail}>
              <Typography className={classes.sectionDesc}>Start</Typography>
              <Typography className={classes.sectionSubDetail}>
                {data?.start_date || "-"}
              </Typography>
            </Grid>
            <Grid xs={12} sm={3} className={classes.singleDetail}>
              <Typography className={classes.sectionDesc}>Duration</Typography>
              <Typography className={classes.sectionSubDetail}>
                {data?.job_role_type === "Part-Time"
                  ? "Part Time"
                  : "Permanent" || ""}
              </Typography>
            </Grid>
            <Grid
              container
              className={classes.profileBtnDivStyle}
              alignItems="flex-end"
            >
              <Grid xs={12} sm={5} md={4}>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForward />}
                  fullWidth
                  onClick={viewProfile}
                >
                  View Profile
                </Button>
              </Grid>
              <div style={{ width: 30 }} className={classes.hideDateView} />
              <Grid xs={12} sm={5} md={4}>
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<ArrowForward />}
                  fullWidth
                  onClick={() => setVisible(true)}
                >
                  Message
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SendMessageModal
        visible={visible}
        handleModal={() => setVisible(false)}
        receiverId={data?.employer_id || null}
      />
    </>
  );
}

export default OfferDetail;
