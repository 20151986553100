import React from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import Images from "../../../../Config/Images";

function MWelcome(props) {
  const { visible = false, handleModal = () => null } = props;

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: md ? 300 : 900,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={visible}
      // onClose={() => handleModal()}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <Grid container>
            <Grid
              container
              sm={12}
              md={5}
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={Images?.welcome}
                alt="error"
                style={{ height: md ? 280 : 400, width: "100%" }}
              />
            </Grid>
            <Grid
              container
              sm={12}
              md={7}
              justifyContent="center"
              direction="column"
              style={{ paddingLeft: md ? 0 : 20 }}
            >
              <Typography
                className="welcomefont primarycolor"
                style={{ marginBottom: 20 }}
              >
                Welcome to Direct Expertise
              </Typography>
              <Typography
                className="primarycolor regulerlargefont"
                style={{ marginBottom: 20 }}
              >
                We have a number of Account Management positions available.
                Let's finish your profile so you can connect with some of the
                best firms in the UK.
              </Typography>
              <Button
                variant="contained"
                endIcon={<ArrowForward />}
                style={{ width: "100%", marginBottom: 20 }}
                onClick={() => {
                  handleModal();
                }}
              >
                set your profile
              </Button>
              <span
                className="primarycolor mediumlargefont"
                style={{ cursor: "pointer", textAlign: "center" }}
                onClick={() => {
                  handleModal();
                }}
              >
                Skip for now
              </span>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MWelcome;
