import React, { useState, useEffect } from "react";
import { Grid, Typography, CircularProgress, Button } from "@mui/material";
import useStyles from "./styles";
import success_json from "../../Assets/Lottie/right.json";
import fail_json from "../../Assets/Lottie/close.json";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import { ArrowForward } from "@mui/icons-material";
import { Setting } from "../../Utils/Setting";
import { getApiData } from "../../Utils/APIHelper";

function Verification() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [pageLoader, setPageLoader] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const { useruuid } = useSelector((state) => state.auth);

  useEffect(() => {
    document.title = Setting.page_name.VERIFICATION;
    verifyEmail();
  }, []);

  const token = location?.search.substring(1);

  async function verifyEmail() {
    setPageLoader(true);
    try {
      let endPoint = `${Setting.endpoints.email_Verification}?token=${token}&uuid=${useruuid}`;
      const response = await getApiData(endPoint, "GET", null);
      if (response && response.status && response.status === true) {
        setIsVerify(response.status);
        setPageLoader(false);
      } else {
        console.log("err==>>");
        toast.error(response?.message);
        setPageLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      toast.error("Something_went_wrong");
      setPageLoader(false);
    }
  }

  function renderSuccessInfo() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: isVerify ? success_json : fail_json,
          }}
          style={{
            padding: "0px !important",
          }}
          height={120}
          width={120}
        />
        {isVerify ? (
          <>
            <Typography className="primarycolor fontsize2">
              YOU ARE VERIFIED!
            </Typography>
            <Typography className="primarycolor regulerlargefont">
              Your account has been successfully verified.
            </Typography>
            <Button
              endIcon={<ArrowForward />}
              variant="contained"
              style={{ width: 300, marginTop: 20 }}
              onClick={() => {
                const isEmployerHome = false;
                const link_url = isEmployerHome ? "/employer/home" : "/";
                navigate(link_url);
              }}
            >
              Go
            </Button>
          </>
        ) : (
          <>
            <Typography
              style={{ marginTop: 20 }}
              className="fontsize2 primarycolor"
            >
              YOU ARE NOT VERIFIED!
            </Typography>
            <Typography className="primarycolor regulerlargefont">
              Your account has been declined.
            </Typography>
          </>
        )}
      </div>
    );
  }

  function renderMain() {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        {pageLoader ? (
          <CircularProgress style={{ color: "#34567b" }} size={40} />
        ) : (
          renderSuccessInfo()
        )}
      </Grid>
    );
  }

  return <div className={classes.mainDiv}>{renderMain()}</div>;
}
export default Verification;
