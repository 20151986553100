import useMediaQuery from "@mui/material/useMediaQuery";
import { NavLink, useLocation } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import useStyles from "./styles";
import { CMS_List } from "../../Config/Static_Data";
function Footer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();

  const isAddSpacing = useMediaQuery(theme.breakpoints.down(1270));
  const isSmallMobileDevice = useMediaQuery(theme.breakpoints.down(500));

  const [is_Sign_Up_Screen, set_is_Sign_Up_Screen] = useState(false);

  useEffect(() => {
    const current_url = location?.pathname;
    const isBool =
      current_url.includes("signup") ||
      current_url.includes("quiz") ||
      current_url.includes("candidate-result") ||
      current_url.includes("verification") ||
      current_url.includes("reset-password") ||
      current_url.includes("about-us") ||
      current_url.includes("contact-us") ||
      current_url.includes("terms-and-condition") ||
      current_url.includes("privacy-policy");
    set_is_Sign_Up_Screen(isBool);
  }, [location]);

  if (is_Sign_Up_Screen) {
    return null;
  }

  if (isSmallMobileDevice) {
    return (
      <div
        className={classes.footer_main_con}
        style={{
          padding: "35px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container style={{ width: "auto", textAlign: "center" }}>
          <Grid item>
            <Typography className={classes.footer_header_title}>
              Company
            </Typography>
            {CMS_List.map((obj, index) => {
              return (
                <NavLink
                  key={index}
                  to={obj?.link}
                  className={classes.anchor_titles}
                >
                  <Typography className={classes.footer_sub_title}>
                    {obj?.title}
                  </Typography>
                </NavLink>
              );
            })}
          </Grid>
        </Grid>

        <Grid
          container
          style={{ width: "auto", textAlign: "center", marginTop: "25px" }}
        >
          <Grid item>
            <Typography className={classes.footer_header_title}>
              Address
            </Typography>
            <Typography className={classes.footer_sub_title}>
              5th Floor
            </Typography>
            <Typography className={classes.footer_sub_title}>
              111 Buckingham Palace
            </Typography>
            <Typography className={classes.footer_sub_title}>
              Road, London - SW1W OSR
            </Typography>
            <Typography className={classes.footer_sub_title}>
              info@directexpertise.com
            </Typography>
            <Typography className={classes.footer_sub_title}>
              +44 (0)20 3868 3358
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            width: "auto",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Typography className={classes.footer_header_title}>
            Stay Tuned
          </Typography>
          <Grid
            container
            spacing={1}
            className={classes.MT10}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <Grid item className={classes.icons_con}>
              <i class="bi bi-facebook"></i>
            </Grid>
            <Grid item className={[classes.icons_con, classes.ML10]}>
              <i class="bi bi-instagram"></i>
            </Grid>
            <Grid item className={[classes.icons_con, classes.ML10]}>
              <i class="bi bi-linkedin"></i>
            </Grid>
            <Grid item className={[classes.icons_con, classes.ML10]}>
              <i class="bi bi-twitter"></i>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            width: "auto",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Typography
            className={[classes.footer_header_title, classes.footer_copy_right]}
          >
            Copyright ⓒ 2005 - 2022 Direct Expertise.
          </Typography>
        </Grid>

        <Grid
          container
          style={{
            width: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            className={[classes.footer_header_title, classes.footer_copy_right]}
          >
            All Rights Reserved
          </Typography>
        </Grid>
      </div>
    );
  }

  return (
    <div
      className={classes.footer_main_con}
      style={isAddSpacing ? { padding: "25px" } : null}
    >
      <Grid container>
        <Grid item lg={5} md={5} sm={4} xs={4}>
          <Typography className={classes.footer_header_title}>
            Company
          </Typography>
          {CMS_List.map((obj, index) => {
            return (
              <NavLink
                key={index}
                to={obj?.link}
                className={classes.anchor_titles}
              >
                <Typography className={classes.footer_sub_title}>
                  {obj?.title}
                </Typography>
              </NavLink>
            );
          })}
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={5}>
          <Typography className={classes.footer_header_title}>
            Address
          </Typography>
          <Typography className={classes.footer_sub_title}>
            5th Floor
          </Typography>
          <Typography className={classes.footer_sub_title}>
            111 Buckingham Palace
          </Typography>
          <Typography className={classes.footer_sub_title}>
            Road, London - SW1W OSR
          </Typography>
          <Typography className={classes.footer_sub_title}>
            info@directexpertise.com
          </Typography>
          <Typography className={classes.footer_sub_title}>
            +44 (0)20 3868 3358
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={3} xs={3}>
          <Grid container>
            <Typography className={classes.footer_header_title}>
              Stay Tuned
            </Typography>
            <Grid container spacing={1} className={classes.MT10}>
              <Grid item className={[classes.icons_con, classes.ML10]}>
                <i class="bi bi-facebook"></i>
              </Grid>
              <Grid item className={[classes.icons_con, classes.ML10]}>
                <i class="bi bi-instagram"></i>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid
                item
                className={[classes.icons_con, classes.ML10, classes.MT10]}
              >
                <i class="bi bi-linkedin"></i>
              </Grid>
              <Grid
                item
                className={[classes.icons_con, classes.ML10, classes.MT10]}
              >
                <i class="bi bi-twitter"></i>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.MT20}>
        <Typography
          className={[classes.footer_header_title, classes.footer_copy_right]}
        >
          Copyright ⓒ 2005 - 2022 Direct Expertise. All Rights Reserved
        </Typography>
      </Grid>
    </div>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
