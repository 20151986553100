import React, { useState, useEffect } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import useStyles from "../../styles";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import Images from "../../../../Config/Images";
import { Editor } from "@tinymce/tinymce-react";
import CDropDown from "../../../CDropDown";
import { isEmpty } from "lodash";

const errorObj = {
  perYearErr: false,
  perHourErr: false,
  endPerYearErr: false,
  endPerHourErr: false,
  bothYearErr: false,
  bothHourErr: false,
  endBothPerYearErr: false,
  endPerBothHourErr: false,
  perYearMsg: "",
  perHourMsg: "",
  endPerYearMsg: "",
  endPerHourMsg: "",
  bothPerYearMsg: "",
  bothPerHourMsg: "",
  endBothPerYearMsg: "",
  endPerBothHourMsg: "",
};

function MEmployment(props) {
  const {
    visible = false,
    handleModal = () => null,
    onSave = () => {},
    editData = {},
  } = props;

  const [errObj, setErrObj] = useState(errorObj);
  const arr = [
    { value: 1, label: "Full-Time" },
    { value: 2, label: "Part-Time" },
    { value: 3, label: "Both" },
  ];
  const [data, setData] = useState("");
  const [jobType, setJobType] = useState(1);
  const [perYear, setPerYear] = useState("");
  const [endPerYear, setEndPerYear] = useState("");
  const [perHour, setPerHour] = useState("");
  const [endPerHour, setEndPerHour] = useState("");

  const [bothPerYear, setBothPerYear] = useState("");
  const [endBothPerYear, setEndBothPerYear] = useState("");
  const [bothPerHour, setBothPerHour] = useState("");
  const [endPerBothHour, setEndBothPerHour] = useState("");

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : md ? 500 : 800,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: 700,
  };
  useEffect(() => {
    if (visible) {
      setJobType(
        editData?.jobType === "Full-Time"
          ? 1
          : editData?.jobType === "Part-Time"
          ? 2
          : editData?.jobType === "Both"
          ? 3
          : 1
      );
      if (!isEmpty(editData?.perYear)) {
        const perYrArr = editData?.perYear.split("-");
        setPerYear(perYrArr[0] || "");
        setEndPerYear(perYrArr[1] || "");
      }
      if (!isEmpty(editData?.perHour)) {
        const perHrArr = editData?.perHour.split("-");
        setPerHour(perHrArr[0] || "");
        setEndPerHour(perHrArr[1] || "");
      }
      if (!isEmpty(editData?.bothPerYear)) {
        const bothYrArr = editData?.bothPerYear.split("-");
        setBothPerYear(bothYrArr[0] || "");
        setEndBothPerYear(bothYrArr[1] || "");
      }
      if (!isEmpty(editData?.bothPerHour)) {
        const bothHrArr = editData?.bothPerHour.split("-");
        setBothPerHour(bothHrArr[0] || "");
        setEndBothPerHour(bothHrArr[1] || "");
      }
      setData(editData?.data || "");
    }
    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  function resetFormData(type = "") {
    setData("");
    setJobType(1);
    setPerHour("");
    setPerYear("");
    setEndPerYear("");
    setEndPerHour("");
    setBothPerHour("");
    setBothPerYear("");
    setEndBothPerYear("");
    setEndBothPerHour("");
    setErrObj(errorObj);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    let valid = true;
    const error = { ...errObj };

    if (
      jobType === 1 &&
      (isEmpty(perYear.toString()) ||
        isEmpty(endPerYear.toString()) ||
        Number(endPerYear) <= Number(perYear))
    ) {
      if (isEmpty(perYear.toString())) {
        valid = false;
        error.perYearErr = true;
        error.perYearMsg = "Please enter yearly salary";
      } else if (isEmpty(endPerYear.toString())) {
        valid = false;
        error.endPerYearErr = true;
        error.endPerYearMsg = "Please enter yearly salary";
      } else if (Number(endPerYear) <= Number(perYear)) {
        valid = false;
        error.endPerYearErr = true;
        error.endPerYearMsg = "salary is must be greater than first salary";
      }
    } else if (
      jobType === 2 &&
      (isEmpty(perHour.toString()) ||
        isEmpty(endPerHour.toString()) ||
        Number(endPerHour) <= Number(perHour))
    ) {
      if (isEmpty(perHour.toString())) {
        valid = false;
        error.perHourErr = true;
        error.perHourMsg = "Please enter hourly salary";
      } else if (isEmpty(endPerHour.toString())) {
        valid = false;
        error.endPerHourErr = true;
        error.endPerHourMsg = "Please enter hourly salary";
      } else if (Number(endPerHour) <= Number(perHour)) {
        valid = false;
        error.endPerHourErr = true;
        error.endPerHourMsg = "salary is must be greater than first salary";
      }
    } else if (
      jobType === 3 &&
      (isEmpty(bothPerYear.toString()) ||
        isEmpty(bothPerHour.toString()) ||
        isEmpty(endBothPerYear.toString()) ||
        isEmpty(endPerBothHour.toString()) ||
        Number(endBothPerYear) <= Number(bothPerYear) ||
        Number(endPerBothHour) <= Number(bothPerHour))
    ) {
      if (isEmpty(bothPerYear.toString())) {
        valid = false;
        error.bothYearErr = true;
        error.bothPerYearMsg = "Please enter yearly salary";
      } else if (isEmpty(endBothPerYear.toString())) {
        valid = false;
        error.endBothPerYearErr = true;
        error.endBothPerYearMsg = "Please enter hourly salary";
      } else if (Number(endBothPerYear) <= Number(bothPerYear)) {
        valid = false;
        error.endBothPerYearErr = true;
        error.endBothPerYearMsg = "salary is must be greater than first salary";
      } else if (isEmpty(bothPerHour.toString())) {
        valid = false;
        error.bothHourErr = true;
        error.bothPerHourMsg = "Please enter hourly salary";
      } else if (isEmpty(endPerBothHour.toString())) {
        valid = false;
        error.endPerBothHourErr = true;
        error.endPerBothHourMsg = "Please enter hourly salary";
      } else if (Number(endPerBothHour) <= Number(bothPerHour)) {
        valid = false;
        error.endPerBothHourErr = true;
        error.endPerBothHourMsg = "salary is must be greater than first salary";
      }
    }

    setErrObj(error);

    if (valid) {
      const employeTypeData = {
        jobType:
          jobType === 1 ? "Full-Time" : jobType === 2 ? "Part-Time" : "Both",
        perYear: `${perYear}-${endPerYear}`,
        perHour: `${perHour}-${endPerHour}`,
        bothPerYear: `${bothPerYear}-${endBothPerYear}`,
        bothPerHour: `${bothPerHour}-${endPerBothHour}`,
        data: data,
      };
      onSave(employeTypeData);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle1"
              component="h2"
              className={classes.modalTitle}
            >
              Add employment type
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className="sliderscroll" style={{ maxHeight: 500 }}>
            <Grid container>
              <Grid
                xs={12}
                sm={5.8}
                md={3.8}
                style={{ marginRight: sm ? 0 : 10 }}
              >
                {jobType === 3 ? (
                  <Typography className="regulerfont primarycolor">
                    For both type
                  </Typography>
                ) : null}
                <CDropDown
                  data={arr}
                  value={jobType}
                  onChange={(v) => {
                    setJobType(v.target.value);
                  }}
                  placeholder="Select sector"
                  style={{ width: "100%" }}
                />
              </Grid>
              {jobType === 3 ? (
                <>
                  <Grid
                    xs={12}
                    sm={12}
                    md={8}
                    style={{
                      marginTop: sm ? 0 : 7,
                      marginBottom: 10,
                    }}
                  >
                    <Typography className="regulerfont primarycolor">
                      Full-Time
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: sm ? "column" : "row",
                      }}
                    >
                      <TextField
                        style={{
                          marginRight: sm ? 0 : 10,
                          marginBottom: sm ? 10 : 0,
                        }}
                        fullWidth
                        variant="outlined"
                        placeholder="From salary"
                        value={bothPerYear}
                        error={errObj.bothYearErr}
                        helperText={
                          errObj.bothYearErr ? errObj.bothPerYearMsg : null
                        }
                        onChange={(e) => {
                          setBothPerYear(e.target.value);
                          setErrObj({
                            ...errObj,
                            bothYearErr: false,
                            bothPerYearMsg: "",
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <div className="inputside">Start</div>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="To salary"
                        value={endBothPerYear}
                        error={errObj.endBothPerYearErr}
                        helperText={
                          errObj.endBothPerYearErr
                            ? errObj.endBothPerYearMsg
                            : null
                        }
                        onChange={(e) => {
                          setEndBothPerYear(e.target.value);
                          setErrObj({
                            ...errObj,
                            endBothPerYearErr: false,
                            endBothPerYearMsg: "",
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <div className="inputside">End</div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={5.8}
                    md={3.8}
                    style={{ marginRight: sm ? 0 : 10 }}
                  />
                  <Grid xs={12} sm={12} md={8} style={{ marginBottom: 10 }}>
                    <Typography className="regulerfont primarycolor">
                      Part-Time
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: sm ? "column" : "row",
                      }}
                    >
                      <TextField
                        style={{
                          marginRight: sm ? 0 : 10,
                          marginBottom: sm ? 10 : 0,
                        }}
                        fullWidth
                        variant="outlined"
                        placeholder="From salary"
                        value={bothPerHour}
                        error={errObj.bothHourErr}
                        helperText={
                          errObj.bothHourErr ? errObj.bothPerHourMsg : null
                        }
                        onChange={(e) => {
                          setBothPerHour(e.target.value);
                          setErrObj({
                            ...errObj,
                            bothHourErr: false,
                            bothPerHourMsg: "",
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <div className="inputside">Start</div>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="To salary"
                        value={endPerBothHour}
                        error={errObj.endPerBothHourErr}
                        helperText={
                          errObj.endPerBothHourErr
                            ? errObj.endPerBothHourMsg
                            : null
                        }
                        onChange={(e) => {
                          setEndBothPerHour(e.target.value);
                          setErrObj({
                            ...errObj,
                            endPerBothHourErr: false,
                            endPerBothHourMsg: "",
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <div className="inputside">End</div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    xs={12}
                    sm={5.8}
                    md={3.8}
                    style={{
                      marginTop: 7,
                      marginBottom: 7,
                      marginRight: sm ? 0 : 10,
                    }}
                  >
                    {jobType === 1 ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="From salary"
                        value={perYear}
                        error={errObj.perYearErr}
                        helperText={
                          errObj.perYearErr ? errObj.perYearMsg : null
                        }
                        onChange={(e) => {
                          setPerYear(e.target.value);
                          setErrObj({
                            ...errObj,
                            perYearErr: false,
                            perYearMsg: "",
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <div className="inputside">per year</div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : jobType === 2 ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="From salary"
                        value={perHour}
                        error={errObj.perHourErr}
                        helperText={
                          errObj.perHourErr ? errObj.perHourMsg : null
                        }
                        onChange={(e) => {
                          setPerHour(e.target.value);
                          setErrObj({
                            ...errObj,
                            perHourErr: false,
                            perHourMsg: "",
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <div className="inputside">per hour</div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : null}
                  </Grid>
                  <Grid
                    xs={12}
                    sm={5.8}
                    md={3.9}
                    style={{ marginTop: 7, marginBottom: 10 }}
                  >
                    {jobType === 1 ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="To salary"
                        value={endPerYear}
                        error={errObj.endPerYearErr}
                        helperText={
                          errObj.endPerYearErr ? errObj.endPerYearMsg : null
                        }
                        onChange={(e) => {
                          setEndPerYear(e.target.value);
                          setErrObj({
                            ...errObj,
                            endPerYearErr: false,
                            endPerYearMsg: "",
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <div className="inputside">per year</div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : jobType === 2 ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="To salary"
                        value={endPerHour}
                        error={errObj.endPerHourErr}
                        helperText={
                          errObj.endPerHourErr ? errObj.endPerHourMsg : null
                        }
                        onChange={(e) => {
                          setEndPerHour(e.target.value);
                          setErrObj({
                            ...errObj,
                            endPerHourErr: false,
                            endPerHourMsg: "",
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <div className="inputside">per hour</div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : null}
                  </Grid>
                </>
              )}
            </Grid>
            <Editor
              onEditorChange={(e) => {
                setData(e);
              }}
              value={data}
              apiKey="fc04elxcr618mmqpqlz178ljh3fnxlmk4yduvz64c9yfgsqk"
              init={{
                height: 300,
                plugins: "lists advlist emoticons",
                // menubar: false,
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor  | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help emoticons",
              }}
            />
          </div>
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: sm ? "space-between" : "unset",
            }}
          >
            <Grid xs={5.8} sm={4} md={3} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                startIcon={<ArrowBack />}
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8} sm={4} md={3}>
              <Button
                variant="contained"
                endIcon={<ArrowForward />}
                style={{ width: "100%" }}
                onClick={() => {
                  checkValidation();
                }}
              >
                SAVE
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MEmployment;
