import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { HighlightOff, Menu } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React, { useState } from "react";
import _, { isEmpty } from "lodash";
import useStyles from "./styles";
import { Setting } from "../../Utils/Setting";
import Images from "../../Config/Images";
import { color } from "../../Config/theme";
import { isUserLogin } from "../../Utils/CommonFunctions";
import authActions from "../../Redux/reducers/auth/actions";
import MLogin from "../Modal/LoginModal";
import MForgot from "../Modal/ForgotModal";
import { getAPIProgressData, getApiData } from "../../Utils/APIHelper";
import { toast } from "react-toastify";
import {
  employer_dashboard_menu_link,
  candidate_dashboard_menu_link,
} from "../../Config/Static_Data";

const { setUserData, clearAllData, setToken } = authActions;

function Header(props) {
  const { isCandidate } = props;
  const Current_Url = window.location.href;

  const classes = useStyles();
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = isUserLogin();
  const { userdata, useruuid, token } = useSelector((state) => state.auth);

  const header = { Authorization: `Bearer ${token}` };

  const userRole = (!isEmpty(userdata) && userdata?.role) || "";

  const [loginModal, setLoginModal] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);
  const [loginLoader, setLoaginLoader] = useState(false);
  const [forgotLoader, setForgotLoader] = useState(false);

  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down(500));
  const isAddPadding = useMediaQuery(theme.breakpoints.down(1260));

  const [open, setOpen] = React.useState(false);

  const isEmployerHome = Current_Url.includes("employer/home");

  function err(message) {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      progress: undefined,
    });
  }

  async function userLogin(data) {
    setLoaginLoader(true);
    const loginData = {
      "LoginForm[email]": data?.email,
      "LoginForm[password]": data?.password,
      "LoginForm[user_type]": isEmployerHome ? "employer" : "user",
      "LoginForm[uuid]": useruuid,
    };

    try {
      let endPoint = Setting.endpoints.login;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        loginData,
        true
      );
      console.log("response ====>>>>>", response);
      if (response?.status) {
        const dd = response?.data;
        const link_url = isEmployerHome
          ? "/employer/company"
          : "/candidate/profile";
        navigate(link_url);
        dispatch(setUserData(dd));
        setLoginModal(false);
        setLoaginLoader(false);
        dispatch(setToken(dd?.access_token || ""));
      } else {
        //display error msg here
        err(response?.message);
        setLoaginLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setLoaginLoader(false);
    }
  }

  async function forgotPass(data) {
    setForgotLoader(true);
    const forgotEmail = {
      "PasswordResetRequestForm[email]": data?.email,
    };

    try {
      let endPoint = Setting.endpoints.forgot_pass;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        forgotEmail,
        true
      );
      if (response?.status) {
        toast.success(response?.message);
        setForgotModal(false);
        setForgotLoader(false);
      } else {
        //display error msg here
        err(response?.message);
        setForgotLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setForgotLoader(false);
    }
  }

  async function userLogout() {
    try {
      let endPoint = `${Setting.endpoints.logout}?uuid=${useruuid}`;
      const response = await getApiData(endPoint, "GET", null, header);
      console.log("response ====>>>>>", response);
      if (response && response.status && response.status === true) {
        dispatch(clearAllData());
        navigate("/");
        // toast.success(response?.message);
      } else {
        console.log("err==>>");
        toast.error(response?.message);
      }
    } catch (err) {
      console.log("Catch Part", err);
      toast.error("Something_went_wrong");
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  function renderLoginModal() {
    return (
      <MLogin
        visible={loginModal}
        handleModal={() => {
          setLoginModal(false);
        }}
        forgotClick={() => {
          setLoginModal(false);
          setForgotModal(true);
        }}
        loginClick={(data) => {
          userLogin(data);
        }}
        isEmployerHome={isEmployerHome}
        loader={loginLoader}
      />
    );
  }

  function renderForgotModal() {
    return (
      <MForgot
        visible={forgotModal}
        handleModal={() => {
          setForgotModal(false);
        }}
        resetPass={(data) => {
          forgotPass(data);
        }}
        loader={forgotLoader}
      />
    );
  }
  // Render Toggle Menu Only for Mobile Devices
  function renderToggleMenu(isDashboard = false) {
    let ary = [
      {
        id: 1,
        title: "Sign Up",
        link: isCandidate ? "/candidate/signup" : "/",
      },
      {
        id: 2,
        title: "Login",
        link: "",
      },
      {
        id: 3,
        title: isEmployerHome ? "FOR CANDIDATE" : "FOR EMPLOYER",
        link: isEmployerHome ? "/" : "/employer/home",
      },
    ];

    if (isDashboard) {
      if (isCandidate) {
        ary = candidate_dashboard_menu_link;
      } else {
        ary = employer_dashboard_menu_link;
      }
    }

    return (
      <React.Fragment key={"top"}>
        <Menu onClick={toggleDrawer(true)} />
        <SwipeableDrawer
          anchor={"top"}
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box
            sx={{ width: "auto" }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {ary.map((obj, index) => {
                if (isDashboard) {
                  return (
                    <ListItem key={index}>
                      <NavLink to={obj?.link} className={classes.link_style}>
                        <Typography className={classes.menu_title_style}>
                          {_.toUpper(obj?.title)}
                        </Typography>
                      </NavLink>
                    </ListItem>
                  );
                } else {
                  if (index === 0 || index === 2) {
                    if (index === 0 && isEmployerHome) {
                      return null;
                    }
                    return (
                      <ListItem key={index}>
                        <NavLink
                          key={index}
                          to={obj?.link}
                          className={classes.link_style}
                        >
                          <Typography className={classes.menu_title_style}>
                            {_.toUpper(obj?.title)}
                          </Typography>
                        </NavLink>
                      </ListItem>
                    );
                  }
                  return (
                    <ListItem
                      key={index}
                      button
                      onClick={() => {
                        setLoginModal(true);
                      }}
                    >
                      <Typography className={classes.menu_title_style}>
                        {_.toUpper(obj?.title)}
                      </Typography>
                    </ListItem>
                  );
                }
              })}
              {isDashboard ? (
                <ListItem
                  button
                  onClick={() => {
                    userLogout();
                  }}
                >
                  <Typography className={classes.menu_title_style}>
                    LOGOUT
                  </Typography>
                </ListItem>
              ) : null}
            </List>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    );
  }

  function LoginSignUpMenu() {
    const is_Sign_Up_Screen =
      Current_Url.includes("signup") ||
      Current_Url.includes("verification") ||
      Current_Url.includes("reset-password") ||
      Current_Url.includes("about-us") ||
      Current_Url.includes("contact-us") ||
      Current_Url.includes("terms-and-condition") ||
      Current_Url.includes("privacy-policy");

    if (is_Sign_Up_Screen) {
      return (
        <Grid container className={classes.list_menu_con}>
          <HighlightOff
            fontSize={"medium"}
            style={{ color: color.secondary, cursor: "pointer", fontSize: 34 }}
            onClick={() => {
              navigate(-1);
            }}
          />
        </Grid>
      );
    }

    if (isMobileDevice) {
      return renderToggleMenu();
    } else {
      return (
        <Grid container className={classes.list_menu_con}>
          {isEmployerHome ? null : (
            <>
              <Grid item>
                <NavLink
                  to={isCandidate ? "/candidate/signup" : "/"}
                  className={classes.link_style}
                >
                  <Typography className={classes.menu_title_style}>
                    SIGN UP
                  </Typography>
                </NavLink>
              </Grid>
              <div className={classes.verticle_line} />
            </>
          )}

          <Grid
            item
            onClick={() => {
              setLoginModal(true);
            }}
            className={classes.poniter_text}
          >
            <Typography className={classes.menu_title_style}>LOG IN</Typography>
          </Grid>

          <div className={classes.verticle_line} />

          <Grid item>
            <NavLink
              to={isEmployerHome ? "/" : "/employer/home"}
              className={classes.link_style}
            >
              <Typography className={classes.menu_title_style}>
                {isEmployerHome ? "FOR CANDIDATE" : "FOR EMPLOYER"}
              </Typography>
            </NavLink>
          </Grid>
        </Grid>
      );
    }
  }

  function renderDashboardMenu() {
    const ary = isCandidate
      ? candidate_dashboard_menu_link
      : employer_dashboard_menu_link;

    const is_Sign_Up_Screen =
      Current_Url.includes("quiz") || Current_Url.includes("candidate-result");
    if (is_Sign_Up_Screen) {
      return (
        <Grid container className={classes.list_menu_con}>
          <HighlightOff
            fontSize={"medium"}
            style={{ color: color.secondary, cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          />
        </Grid>
      );
    }

    if (isMobileDevice) {
      return renderToggleMenu(true);
    } else {
      return (
        <Grid container className={classes.list_menu_con}>
          {ary.map((obj, index) => {
            const activeKey = obj?.key?.includes(location.pathname);
            return (
              <Grid item key={index} className={classes.PR25}>
                <div className={classes.linkContainer}>
                  <NavLink to={obj?.link} className={classes.link_style}>
                    <Typography className={classes.menu_title_style}>
                      {_.toUpper(obj?.title)}
                    </Typography>
                  </NavLink>
                  {activeKey ? (
                    <div className={classes.activeLinkStyle} />
                  ) : null}
                </div>
              </Grid>
            );
          })}
          <Grid
            item
            onClick={() => {
              userLogout();
            }}
            className={classes.poniter_text}
          >
            <Typography className={classes.menu_title_style}>LOGOUT</Typography>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <div
      className={classes.header_main_con}
      style={
        isAddPadding ? { paddingLeft: "15px", paddingRight: "15px" } : null
      }
    >
      <Grid container>
        <Grid
          item
          container
          lg={3}
          md={3}
          sm={3}
          xs={4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              height: isMobileDevice ? "30px" : "40px",
            }}
            onClick={() =>
              navigate(
                userRole === "employer"
                  ? "/employer/company"
                  : "/candidate/profile"
              )
            }
          >
            <img
              alt="logo"
              src={Images.header_logo}
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
            />
          </div>
        </Grid>

        <Grid
          item
          container
          lg={9}
          md={9}
          sm={9}
          xs={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {!isLogin ? LoginSignUpMenu() : renderDashboardMenu()}
        </Grid>
      </Grid>
      {renderLoginModal()}
      {renderForgotModal()}
    </div>
  );
}

Header.propTypes = {
  isCandidate: PropTypes.bool,
};

Header.defaultProps = {
  isCandidate: false,
};

export default Header;
