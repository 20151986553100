import { makeStyles } from "@mui/styles";
import { color } from "../../Config/theme";

const useStyles = makeStyles((theme) => ({
  button_main_con: {
    padding: "5px",
    borderRadius: 4,
    height: 50,
    cursor: "pointer",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${color.primary}`,
  },
  button_name: {
    color: color.primary,
    fontSize: "15px !important",
    fontFamily: "PoppinsRegular !important",
  },
  fullWidthDiv: {
    maxWidth: "100% !important",
  },
}));

export default useStyles;
