import React, { useEffect, useState } from "react";
import _, { isDate, isEmpty, isArray } from "lodash";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Box,
  Radio,
  Button,
  Checkbox,
  useTheme,
  TextField,
  Typography,
  useMediaQuery,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { ArrowForward, Close, AddLocationAlt } from "@mui/icons-material";
import useStyles from "./styles";
import { Setting } from "../../../Utils/Setting";
import CDropDown from "../../../Components/CDropDown";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { toast } from "react-toastify";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import authActions from "../../../Redux/reducers/auth/actions";
import Images from "../../../Config/Images";

const errorObj = {
  sectorErr: false,
  jobRoleErr: false,
  perYearErr: false,
  perHourErr: false,
  bothPerYearErr: false,
  bothPerHourErr: false,
  fromDateErr: false,
  regionErr: false,
  postCodeErr: false,
  cultureErr: false,
  companySizeErr: false,
  sectorMsg: "",
  jobRoleMsg: "",
  perYearMsg: "",
  perHourMsg: "",
  bothPerYearMsg: "",
  bothPerHourMsg: "",
  fromDateMsg: "",
  regionMsg: "",
  postCodeMsg: "",
  cultureMsg: "",
  companySizeMsg: "",
};

const { setCandidateProfile } = authActions;

export default function Preference() {
  const classes = useStyles();
  const { dropdowndata, candidateProfile } = useSelector((state) => state.auth);
  const [errObj, setErrObj] = useState(errorObj);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const header = { Authorization: `Bearer ${token}` };

  //1st card
  const [sector, setSector] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [jobRoleArr, setJobRoleArr] = useState([]);

  //2nd card
  const [selectedValue, setSelectedValue] = useState("Full-Time");
  const [perYear, setPerYear] = useState("");
  const [perHour, setPerHour] = useState("");
  const [bothPerYear, setBothPerYear] = useState("");
  const [bothPerHour, setBothPerHour] = useState("");

  //3rd card
  const [availability, setAvailability] = useState("now");
  const [availDate, SetAvailDate] = useState(null);
  const [openPicker, setOpenPicker] = useState(false);

  //4th card
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [remoteOnly, setRemoteOnly] = useState(false);
  const isSelected = !_.isEmpty(selectedLocations);

  //5th card
  const [postCode, setPostCode] = useState("");

  //6th card
  const [culture, setCulture] = useState("");
  const [companySize, setCompanySize] = useState("");

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    document.title = Setting.page_name.CANDIDATE_PREFERENCE;
    window.scrollTo(0, 0);

    setSector(candidateProfile?.sector_id || "");
    setJobRole(candidateProfile?.job_role_id || "");
    setSelectedValue(candidateProfile?.job_role_type || "Full-Time");
    setPerYear(candidateProfile?.charge_per_year || "");
    setPerHour(candidateProfile?.charge_per_hour || "");
    setBothPerYear(candidateProfile?.charge_per_year || "");
    setBothPerHour(candidateProfile?.charge_per_hour || "");
    setAvailability(candidateProfile?.availablity_type || "now");
    SetAvailDate(new Date(candidateProfile?.availablity_date) || null);
    setRemoteOnly(candidateProfile?.is_remote === 1 ? true : false || false);
    setPostCode("");
    setCulture(candidateProfile?.culture_type || "");
    setCompanySize(candidateProfile?.company_size || "");

    if (!isEmpty(candidateProfile)) {
      jobRoleData(candidateProfile?.sector_id);
    }
  }, []);

  async function candidatePreferenceData() {
    setLoader(true);
    const preferenceData = {
      "UserPreferenceForm[sector_id]": sector,
      "UserPreferenceForm[job_role_id]": jobRole,
      "UserPreferenceForm[job_role_type]": selectedValue,
      "UserPreferenceForm[availablity_type]": availability,
      "UserPreferenceForm[culture_type]": culture,
      "UserPreferenceForm[company_size]": companySize,
      "UserPreferenceForm[is_remote]": remoteOnly ? 1 : 0,
      "UserPreferenceForm[lat]": "asd123",
      "UserPreferenceForm[long]": "ads456",
    };

    if (selectedValue === "Full-Time") {
      preferenceData["UserPreferenceForm[charge_per_year]"] = perYear;
    } else if (selectedValue === "Part-Time") {
      preferenceData["UserPreferenceForm[charge_per_hour]"] = perHour;
    } else if (selectedValue === "Both") {
      preferenceData["UserPreferenceForm[charge_per_year]"] = bothPerYear;
      preferenceData["UserPreferenceForm[charge_per_hour]"] = bothPerHour;
    }

    if (availability === "from") {
      const formateDate = moment(availDate).format("yyyy-MM-DD");
      preferenceData["UserPreferenceForm[availablity_date]"] = formateDate;
    }

    if (!remoteOnly) {
      if (isArray(selectedLocations) && !isEmpty(selectedLocations)) {
        selectedLocations.map((ii, index) => {
          preferenceData[`UserPreferenceForm[regions][${index}]`] = ii?.value;
        });
      }
    }

    try {
      let endPoint = Setting.endpoints.employeePreference;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        preferenceData,
        header
      );
      if (response?.status) {
        setLoader(false);
        dispatch(setCandidateProfile(response?.data));
        toast.success(response?.message);
      } else {
        //display error msg here
        toast.error(response?.message);
        setLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setLoader(false);
    }
  }

  useEffect(() => {
    const arr = [];
    if (
      isArray(candidateProfile?.regions) &&
      candidateProfile?.regions.length > 0
    ) {
      candidateProfile?.regions.map((item) => {
        if (isArray(dropdowndata.region) && dropdowndata.region.length > 0) {
          dropdowndata.region.map((key) => {
            if (Number(item) === Number(key.value)) {
              arr.push(key);
            }
          });
        }
      });
    }
    setSelectedLocations(arr);
  }, []);

  function jobRoleData(value) {
    dropdowndata?.sectors.map((ii) => {
      if (ii?.value.toString() === value.toString()) {
        setJobRoleArr(ii?.job_roles);
      }
    });
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(sector.toString())) {
      valid = false;
      error.sectorErr = true;
      error.sectorMsg = "Please select sector";
    } else if (isEmpty(jobRole.toString())) {
      valid = false;
      error.jobRoleErr = true;
      error.jobRoleMsg = "Please select jobrole";
    } else if (selectedValue === "Full-Time" && _.isEmpty(perYear.toString())) {
      valid = false;
      error.perYearErr = true;
      error.perYearMsg = "Please enter yearly salary";
    } else if (selectedValue === "Part-Time" && _.isEmpty(perHour.toString())) {
      valid = false;
      error.perHourErr = true;
      error.perHourMsg = "Please enter hourly salary";
    } else if (
      selectedValue === "Both" &&
      (isEmpty(bothPerYear.toString()) || isEmpty(bothPerHour.toString()))
    ) {
      if (isEmpty(bothPerYear.toString())) {
        valid = false;
        error.bothPerYearErr = true;
        error.bothPerYearMsg = "Please enter yearly salary";
      } else if (isEmpty(bothPerHour.toString())) {
        valid = false;
        error.bothPerHourErr = true;
        error.bothPerHourMsg = "Please enter hourly salary";
      }
    } else if (availability === "from" && !isDate(availDate)) {
      valid = false;
      error.fromDateErr = true;
      error.fromDateMsg = "Selcet available date";
    } else if (isEmpty(selectedLocations) && !remoteOnly) {
      valid = false;
      error.regionErr = true;
      error.regionMsg = "Please select region or remote only";
    } else if (isEmpty(postCode)) {
      valid = false;
      error.postCodeErr = true;
      error.postCodeMsg = "Enter postcode or location";
    } else if (isEmpty(culture.toString())) {
      valid = false;
      error.cultureErr = true;
      error.cultureMsg = "Please select culture";
    } else if (isEmpty(companySize.toString())) {
      valid = false;
      error.companySizeErr = true;
      error.companySizeMsg = "Please select company size";
    }

    setErrObj(error);

    if (valid) {
      candidatePreferenceData();
    }
  }

  function removeLocation(index) {
    const arr = [...selectedLocations];
    arr.map((i, ii) => {
      if (ii === index) {
        arr.splice(ii, 1);
      }
      setSelectedLocations(arr);
    });
  }

  function renderFirstCard() {
    return (
      <Grid container className={classes.paperstyle}>
        <Grid item xs={12}>
          <Typography className="primarycolor mediumsemiboldfont">
            Select the sector and job role.
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 20 }}>
          <Grid
            item
            xs={12}
            sm={5.8}
            md={3.8}
            style={{ marginRight: sm ? 0 : 20 }}
          >
            <CDropDown
              data={dropdowndata.sectors}
              value={sector}
              onChange={(v) => {
                setSector(v.target.value);
                jobRoleData(v.target.value);
                setJobRole("");
                setErrObj({ ...errObj, sectorErr: false, sectorMsg: "" });
              }}
              error={errObj.sectorErr}
              helperText={errObj.sectorErr ? errObj.sectorMsg : null}
              placeholder="Select sector"
              label="Select the sector"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8}>
            <CDropDown
              data={jobRoleArr}
              value={jobRole}
              onChange={(v) => {
                setJobRole(v.target.value);
                setErrObj({ ...errObj, jobRoleErr: false, jobRoleMsg: "" });
              }}
              error={errObj.jobRoleErr}
              helperText={errObj.jobRoleErr ? errObj.jobRoleMsg : null}
              placeholder="Select sector"
              label="Select the job"
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderSecondCard() {
    return (
      <Grid container className={classes.paperstyle}>
        <Grid item xs={12}>
          <Typography className="primarycolor mediumsemiboldfont">
            Tell us what kind of role you're looking for?
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 20 }}>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={selectedValue === "Full-Time"}
                  onChange={(v) => {
                    setSelectedValue(v.target.value);
                  }}
                  value="Full-Time"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  Full-time
                </Typography>
              </div>
            </Grid>
            {selectedValue === "Full-Time" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={3.9}
                  style={{ display: "flex", marginTop: sm ? 16 : 0 }}
                >
                  <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    placeholder="Salary"
                    value={perYear}
                    error={errObj.perYearErr}
                    helperText={errObj.perYearErr ? errObj.perYearMsg : null}
                    onChange={(e) => {
                      setPerYear(e.target.value);
                      setErrObj({
                        ...errObj,
                        perYearErr: false,
                        perYearMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per year</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={selectedValue === "Part-Time"}
                  onChange={(v) => {
                    setSelectedValue(v.target.value);
                  }}
                  value="Part-Time"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  Part-time
                </Typography>
              </div>
            </Grid>
            {selectedValue === "Part-Time" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={3.9}
                  style={{ display: "flex", marginTop: sm ? 16 : 0 }}
                >
                  <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    placeholder="Salary"
                    value={perHour}
                    error={errObj.perHourErr}
                    helperText={errObj.perHourErr ? errObj.perHourMsg : null}
                    onChange={(e) => {
                      setPerHour(e.target.value);
                      setErrObj({
                        ...errObj,
                        perHourErr: false,
                        perHourMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per hour</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={selectedValue === "Both"}
                  onChange={(v) => {
                    setSelectedValue(v.target.value);
                  }}
                  value="Both"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  Both
                </Typography>
              </div>
            </Grid>
            {selectedValue === "Both" ? (
              <Grid
                container
                xs={12}
                sm={5.8}
                md={7.8}
                justifyContent={md ? "flex-end" : "space-between"}
              >
                <Grid
                  item
                  xs={12}
                  sm={11.6}
                  md={5.9}
                  className="lastfirst"
                  style={{ marginTop: sm ? 16 : 0 }}
                >
                  <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    placeholder="Salary"
                    value={bothPerYear}
                    error={errObj.bothPerYearErr}
                    helperText={
                      errObj.bothPerYearErr ? errObj.bothPerYearMsg : null
                    }
                    onChange={(e) => {
                      setBothPerYear(e.target.value);
                      setErrObj({
                        ...errObj,
                        bothPerYearErr: false,
                        bothPerYearMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per year</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={11.6} md={5.9} className="lastfirst">
                  <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    placeholder="Salary"
                    value={bothPerHour}
                    error={errObj.bothPerHourErr}
                    helperText={
                      errObj.bothPerHourErr ? errObj.bothPerHourMsg : null
                    }
                    onChange={(e) => {
                      setBothPerHour(e.target.value);
                      setErrObj({
                        ...errObj,
                        bothPerHourErr: false,
                        bothPerHourMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>£</span>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per hour</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderThirdCard() {
    return (
      <Grid container className={classes.paperstyle}>
        <Grid item xs={12}>
          <Typography className="primarycolor mediumsemiboldfont">
            When can you start?
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 20 }}>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={availability === "now"}
                  onChange={(v) => {
                    setAvailability(v.target.value);
                  }}
                  value="now"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  I’m available now
                </Typography>
              </div>
            </Grid>
            {availability === "now" ? (
              <>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={availability === "from"}
                  onChange={(v) => {
                    setAvailability(v.target.value);
                  }}
                  value="from"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  I’m available from
                </Typography>
              </div>
            </Grid>
            {availability === "from" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={3.9}
                  style={{ display: "flex", marginTop: sm ? 16 : 0 }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={availDate}
                      onChange={(newValue) => {
                        SetAvailDate(newValue);
                        setOpenPicker(false);
                        setErrObj({
                          ...errObj,
                          fromDateErr: false,
                          fromDateMsg: "",
                        });
                      }}
                      open={openPicker}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          // className={classes.inputFieldStyle}
                          {...params}
                          error={errObj.fromDateErr}
                          helperText={
                            errObj.fromDateErr ? errObj.fromDateMsg : null
                          }
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Select Date",
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  className="datepicker pickerStyle"
                                  onClick={() => {
                                    setOpenPicker(!openPicker);
                                  }}
                                >
                                  <img
                                    alt="error"
                                    src={Images.picker}
                                    style={{ width: 24 }}
                                  />
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={availability === "not-available"}
                  onChange={(v) => {
                    setAvailability(v.target.value);
                  }}
                  value="not-available"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  I’m not available
                </Typography>
              </div>
            </Grid>
            {availability === "not-available" ? (
              <>
                <Grid item xs={3.9}></Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderFourthCard() {
    return (
      <Grid container className={classes.paperstyle}>
        <Grid item xs={12}>
          <Typography className="primarycolor mediumsemiboldfont">
            Which region(s) would you like to find work in?
          </Typography>
        </Grid>
        <Grid container direction="column" style={{ marginTop: 20 }}>
          <CDropDown
            disable={remoteOnly ? true : false}
            data={dropdowndata.region}
            value={selectedLocations}
            error={errObj.regionErr}
            helperText={errObj.regionErr ? errObj.regionMsg : null}
            onChange={(v) => {
              setSelectedLocations(v.target.value);
              setErrObj({ ...errObj, regionErr: false, regionMsg: "" });
            }}
            multiple={true}
            placeholder="Select or enter a location for the UK only"
            style={{ width: sm ? "100%" : 500 }}
          />
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Typography className="mediumfont">
              {isSelected ? "Selected locations" : "Remote only"}
            </Typography>
          </div>
          {isSelected ? (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {selectedLocations.map((item, index) => {
                return (
                  <div key={index} className="selextedlocationmain">
                    <Typography className="regulerlargefont lightsilvercolor">
                      {item.label}
                    </Typography>
                    <Button
                      variant="contained"
                      className="closebtn"
                      onClick={() => {
                        removeLocation(index);
                      }}
                    >
                      <Close style={{ fontSize: 16 }} />
                    </Button>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="checkboxmain">
              <Checkbox
                checked={remoteOnly}
                onChange={() => {
                  setRemoteOnly(!remoteOnly);
                  setErrObj({ ...errObj, regionErr: false, regionMsg: "" });
                }}
              />
              <Typography className="lightsilvercolor regulerfont">
                This option will connect you with candidates from all over the
                UK
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    );
  }

  function renderFifthCard() {
    return (
      <Grid container className={classes.paperstyle}>
        <Grid item xs={12}>
          <Typography className="primarycolor mediumsemiboldfont">
            Enter your Postcode or Location.
          </Typography>
        </Grid>
        <Grid container direction="column" style={{ marginTop: 20 }}>
          <TextField
            variant="outlined"
            id="outlined-select-currency"
            placeholder="Enter your Postcode or Location"
            value={postCode}
            error={errObj.postCodeErr}
            helperText={errObj.postCodeErr ? errObj.postCodeMsg : null}
            onChange={(v) => {
              setPostCode(v.target.value);
              setErrObj({ ...errObj, postCodeErr: false, postCodeMsg: "" });
            }}
            InputProps={{
              endAdornment: <AddLocationAlt style={{ color: "#1F4771" }} />,
            }}
            style={{ maxWidth: 500 }}
          />
        </Grid>
      </Grid>
    );
  }

  function renderSixthCard() {
    return (
      <Grid container className={classes.paperstyle}>
        <Grid item xs={12}>
          <Typography className="primarycolor mediumsemiboldfont">
            What kind of company would you like to work for?
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 20 }}>
          <Grid
            item
            xs={12}
            sm={5.8}
            md={3.8}
            style={{ marginRight: sm ? 0 : 20 }}
          >
            <CDropDown
              data={dropdowndata?.company_culture}
              placeholder="Culture"
              label="Select the culture"
              value={culture}
              error={errObj.cultureErr}
              helperText={errObj.cultureErr ? errObj.cultureMsg : null}
              onChange={(v) => {
                setCulture(v.target.value);
                setErrObj({ ...errObj, cultureErr: false, cultureMsg: "" });
              }}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8}>
            <CDropDown
              data={dropdowndata?.company_size}
              placeholder="Company size"
              label="Select the company size"
              value={companySize}
              error={errObj.companySizeErr}
              helperText={errObj.companySizeErr ? errObj.companySizeMsg : null}
              onChange={(v) => {
                setCompanySize(v.target.value);
                setErrObj({
                  ...errObj,
                  companySizeErr: false,
                  companySizeMsg: "",
                });
              }}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderButtun() {
    return (
      <Grid container justifyContent={sm ? "center" : "unset"}>
        <Grid item xs={11} sm={3}>
          <Button
            onClick={() => {
              checkValidation();
            }}
            endIcon={loader ? null : <ArrowForward />}
            variant="contained"
            style={{ width: "100%" }}
          >
            {loader ? (
              <CircularProgress style={{ color: "#fff" }} size={26} />
            ) : (
              "save"
            )}
          </Button>
        </Grid>
      </Grid>
    );
  }

  function renderMain() {
    return (
      <Box sx={{ flexGrow: 1, paddingTop: 4 }}>
        <Grid container>
          {renderFirstCard()}
          {renderSecondCard()}
          {renderThirdCard()}
          {renderFourthCard()}
          {renderFifthCard()}
          {renderSixthCard()}
          {renderButtun()}
        </Grid>
      </Box>
    );
  }

  return <div>{renderMain()}</div>;
}
