import React, { useEffect, useState } from "react";
import FBLoginButton from "../../../Components/Social_Login/FBLoginButton";
import {
  Grid,
  Box,
  Radio,
  Button,
  Checkbox,
  useTheme,
  TextField,
  Typography,
  OutlinedInput,
  useMediaQuery,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import "./styles.css";
import _, { isArray, isEmpty, isNull } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  ArrowForward,
  ArrowBack,
  Close,
  AddLocationAlt,
} from "@mui/icons-material";
import moment from "moment";
import CDropDown from "../../../Components/CDropDown";
import { Setting } from "../../../Utils/Setting";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Images from "../../../Config/Images";
import CStepper from "../../../Components/CStepper";
import { toast } from "react-toastify";
import GoogleLoginButton from "../../../Components/Social_Login/GoogleLoginButton";
import LinkedinLoginButton from "../../../Components/Social_Login/LinkedinLoginButton";
import MUserDetail from "../../../Components/Profile/Modal/MUserDetail";
import authActions from "../../../Redux/reducers/auth/actions";
import { getAPIProgressData } from "../../../Utils/APIHelper";

const errorObj = {
  sectorErr: false,
  jobRoleErr: false,
  perYearErr: false,
  perHourErr: false,
  bothPerYearErr: false,
  bothPerHourErr: false,
  fromDateErr: false,
  regionErr: false,
  postCodeErr: false,
  cultureErr: false,
  companySizeErr: false,
  sectorMsg: "",
  jobRoleMsg: "",
  perYearMsg: "",
  perHourMsg: "",
  bothPerYearMsg: "",
  bothPerHourMsg: "",
  fromDateMsg: "",
  regionMsg: "",
  postCodeMsg: "",
  cultureMsg: "",
  companySizeMsg: "",
};
const { setUserData, setToken } = authActions;

export default function SignUp() {
  const { dropdowndata, useruuid } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isFirst = !isNull(location.state);
  const [errObj, setErrObj] = useState(errorObj);

  const steps = [1, 2, 3, 4, 5, 6, 7];
  const [activeStep, setActiveStep] = useState(isFirst ? 1 : 0);
  const firstStep = activeStep === 0;
  const lastStep = activeStep === steps.length - 1;

  const [openModal, setOpenModal] = useState(false);
  const [jobRoleArr, setJobRoleArr] = useState([]);

  const [loader, setLoader] = useState(false);
  const [socialUserInfo, setSocialUserInfo] = useState({});

  //Step 1st
  const [sector, setSector] = useState(isFirst ? location.state.sector : "");
  const [jobRole, setJobRole] = useState(isFirst ? location.state.jobRole : "");

  //Step 2nd
  const [selectedValue, setSelectedValue] = useState("Full-Time");
  const [perYear, setPerYear] = useState("");
  const [perHour, setPerHour] = useState("");
  const [bothPerYear, setBothPerYear] = useState("");
  const [bothPerHour, setBothPerHour] = useState("");

  //Step 3rd
  const [availability, setAvailability] = useState("now");
  const [availDate, SetAvailDate] = useState(null);
  const [openPicker, setOpenPicker] = useState(false);

  //Step 4th
  const [selectedLocations, setSelextedLocations] = useState([]);
  const [remoteOnly, setRemoteOnly] = useState(false);
  const isSelected = !_.isEmpty(selectedLocations);

  //Step 5th
  const [postCode, setPostCode] = useState("");

  //step 6th
  const [culture, setCulture] = useState("");
  const [companySize, setCompanySize] = useState("");

  //Step 7th
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");

  const [modalSaveBtnLoader, setModalSaveBtnLoader] = useState(false);

  const [agree, setAgree] = useState(false);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    document.title = Setting.page_name.CANDIDATE_SIGN_UP;
  }, []);

  useEffect(() => {
    if (isFirst) {
      jobRoleData(sector);
    }
  }, []);

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function removeLocation(index) {
    const arr = [...selectedLocations];
    arr.map((i, ii) => {
      if (ii === index) {
        arr.splice(ii, 1);
      }
      setSelextedLocations(arr);
    });
  }

  function err(message) {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      progress: undefined,
    });
  }

  function jobRoleData(value) {
    dropdowndata?.sectors.map((ii) => {
      if (ii?.value === value) {
        setJobRoleArr(ii?.job_roles);
      }
    });
  }

  function checkStepsValidation() {
    const error = { ...errObj };
    if (activeStep === 0) {
      if (_.isEmpty(sector.toString())) {
        error.sectorErr = true;
        error.sectorMsg = "Please select sector";
      } else if (_.isEmpty(jobRole.toString())) {
        error.jobRoleErr = true;
        error.jobRoleMsg = "Please select job role";
      } else {
        handleNext();
      }
    } else if (activeStep === 1) {
      if (selectedValue === "Full-Time") {
        if (_.isEmpty(perYear)) {
          error.perYearErr = true;
          error.perYearMsg = "Please enter yealy amount";
        } else {
          handleNext();
        }
      } else if (selectedValue === "Part-Time") {
        if (_.isEmpty(perHour)) {
          error.perHourErr = true;
          error.perHourMsg = "Please enter hourly amount";
        } else {
          handleNext();
        }
      } else if (selectedValue === "Both") {
        if (_.isEmpty(bothPerYear)) {
          error.bothPerYearErr = true;
          error.bothPerYearMsg = "Please enter yealy amount";
        } else if (_.isEmpty(bothPerHour)) {
          error.bothPerHourErr = true;
          error.bothPerHourMsg = "Please enter yealy amount";
        } else {
          handleNext();
        }
      } else {
        handleNext();
      }
    } else if (activeStep === 2) {
      if (availability === "from") {
        if (!_.isDate(availDate)) {
          error.fromDateErr = true;
          error.fromDateMsg = "Please select date";
        } else {
          handleNext();
        }
      } else {
        handleNext();
      }
    } else if (activeStep === 3) {
      if (_.isEmpty(selectedLocations) && !remoteOnly) {
        error.regionErr = true;
        error.regionMsg = "Please select region(s) or Remote only";
      } else {
        handleNext();
      }
    } else if (activeStep === 4) {
      if (_.isEmpty(postCode)) {
        error.postCodeErr = true;
        error.postCodeMsg = "Please enter post code or location";
      } else {
        handleNext();
      }
    } else if (activeStep === 5) {
      if (_.isEmpty(culture.toString())) {
        error.cultureErr = true;
        error.cultureMsg = "Please select culture";
      } else if (_.isEmpty(companySize.toString())) {
        error.companySizeErr = true;
        error.companySizeMsg = "Please select company size";
      } else {
        handleNext();
      }
    } else if (activeStep === 6) {
      if (
        _.isEmpty(firstName) ||
        _.isEmpty(lastName) ||
        _.isEmpty(email) ||
        _.isEmpty(phone) ||
        _.isEmpty(password)
      ) {
        return err("Please fill all field or sign up with social");
      } else if (!agree) {
        err("please select terms & condition");
      } else {
        camdidateSignupProcess();
        setLoader(true);
      }
    }

    setErrObj(error);
  }

  async function camdidateSignupProcess(data = {}) {
    const isSocialSignUp = _.isObject(data) && !_.isEmpty(data);

    const signUpData = {
      "SignupForm[sector_id]": sector,
      "SignupForm[job_role_id]": jobRole,
      "SignupForm[job_role_type]": selectedValue,
      "SignupForm[availablity_type]": availability,
      "SignupForm[location]": postCode,
      "SignupForm[culture_type]": culture,
      "SignupForm[company_size]": companySize,
      "SignupForm[terms_condition]": agree ? 1 : 0,
      "SignupForm[first_name]": isSocialSignUp ? data?.firstName : firstName,
      "SignupForm[last_name]": isSocialSignUp ? data?.lastName : lastName,
      "SignupForm[email]": isSocialSignUp ? data?.email : email,
      "SignupForm[phone]": isSocialSignUp ? data?.phone : phone,
      "SignupForm[is_remote]": remoteOnly ? 1 : 0,
      "SignupForm[uuid]": useruuid,
    };

    if (!isSocialSignUp) {
      signUpData["SignupForm[password]"] = password;
    } else {
      signUpData["SignupForm[provider_type]"] = socialUserInfo?.provider_type;
      signUpData["SignupForm[provider_key]"] = socialUserInfo?.provider_key;
    }

    if (selectedValue === "Full-Time") {
      signUpData["SignupForm[charge_per_year]"] = perYear;
    } else if (selectedValue === "Part-Time") {
      signUpData["SignupForm[charge_per_hour]"] = perHour;
    } else if (selectedValue === "Both") {
      signUpData["SignupForm[charge_per_year]"] = bothPerYear;
      signUpData["SignupForm[charge_per_hour]"] = bothPerHour;
    }

    if (availability === "from") {
      const formateDate = moment(availDate).format("yyyy-MM-DD");
      signUpData["SignupForm[availablity_date]"] = formateDate;
    }

    if (!remoteOnly) {
      if (isArray(selectedLocations) && !isEmpty(selectedLocations)) {
        selectedLocations.map((ii, index) => {
          signUpData[`SignupForm[regions][${index}]`] = ii?.value;
        });
      }
    }

    try {
      let endPoint = Setting.endpoints.candidate_signup;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        signUpData,
        false
      );
      if (response?.status) {
        if (isSocialSignUp) {
          dispatch(setUserData(response?.data));
          dispatch(setToken(response?.data?.access_token || ""));
          setModalSaveBtnLoader(false);
          navigate("/candidate/profile");
        } else {
          navigate(-1);
          setLoader(false);
          toast.success(response?.message);
        }
      } else {
        //display error msg here
        err(response?.message);
        setLoader(false);
        setModalSaveBtnLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setLoader(false);
      setModalSaveBtnLoader(false);
    }
  }

  function renderfirst() {
    const isSector = isEmpty(sector.toString());
    return (
      <Grid direction="column" container style={{ minHeight: sm ? 320 : 500 }}>
        <Typography
          className="primarycolor fontsize"
          style={{ textAlign: sm ? "center" : "unset" }}
        >
          Select the sector and job role.
        </Typography>
        <CDropDown
          data={dropdowndata?.sectors}
          value={sector}
          error={errObj.sectorErr}
          helperText={errObj.sectorErr ? errObj.sectorMsg : null}
          onChange={(v) => {
            setSector(v.target.value);
            jobRoleData(v.target.value);
            setJobRole("");
            setErrObj({ ...errObj, sectorErr: false, sectorMsg: "" });
          }}
          placeholder="Select sector"
          label="Select the sector"
          style={{ maxWidth: sm ? "unset" : 300 }}
        />
        <CDropDown
          data={jobRoleArr}
          value={jobRole}
          disable={isSector}
          error={errObj.jobRoleErr}
          helperText={errObj.jobRoleErr ? errObj.jobRoleMsg : null}
          onChange={(v) => {
            setJobRole(v.target.value);
            setErrObj({ ...errObj, jobRoleErr: false, jobRoleMsg: "" });
          }}
          placeholder="Select job"
          label="Select the job role"
          style={{ maxWidth: sm ? "unset" : 300 }}
        />
      </Grid>
    );
  }

  function renderSecond() {
    return (
      <Grid container direction="column" style={{ minHeight: sm ? 320 : 500 }}>
        <Typography
          className="primarycolor fontsize"
          style={{ textAlign: sm ? "center" : "unset", marginBottom: 10 }}
        >
          Tell us what kind of role you're looking for?
        </Typography>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={selectedValue === "Full-Time"}
                  onChange={(v) => {
                    setSelectedValue(v.target.value);
                  }}
                  value="Full-Time"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  Full-time
                </Typography>
              </div>
            </Grid>
            {selectedValue === "Full-Time" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={3.9}
                  style={{ display: "flex", marginTop: sm ? 16 : 0 }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={perYear}
                    error={errObj.perYearErr}
                    helperText={errObj.perYearErr ? errObj.perYearMsg : null}
                    onChange={(e) => {
                      setPerYear(e.target.value);
                      setErrObj({
                        ...errObj,
                        perYearErr: false,
                        perYearMsg: "",
                      });
                    }}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per year</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={selectedValue === "Part-Time"}
                  onChange={(v) => {
                    setSelectedValue(v.target.value);
                  }}
                  value="Part-Time"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  Part-time
                </Typography>
              </div>
            </Grid>
            {selectedValue === "Part-Time" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={3.9}
                  style={{ display: "flex", marginTop: sm ? 16 : 0 }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={perHour}
                    error={errObj.perHourErr}
                    helperText={errObj.perHourErr ? errObj.perHourMsg : null}
                    type="number"
                    onChange={(e) => {
                      setPerHour(e.target.value);
                      setErrObj({
                        ...errObj,
                        perHourErr: false,
                        perHourMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per hour</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={selectedValue === "Both"}
                  onChange={(v) => {
                    setSelectedValue(v.target.value);
                  }}
                  value="Both"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  Both
                </Typography>
              </div>
            </Grid>
            {selectedValue === "Both" ? (
              <Grid
                container
                xs={12}
                sm={5.8}
                md={7.8}
                justifyContent={md ? "flex-end" : "space-between"}
              >
                <Grid
                  item
                  xs={12}
                  sm={11.6}
                  md={5.9}
                  className="lastfirst"
                  style={{ marginTop: sm ? 16 : 0 }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={bothPerYear}
                    error={errObj.bothPerYearErr}
                    helperText={
                      errObj.bothPerYearErr ? errObj.bothPerYearMsg : null
                    }
                    type="number"
                    onChange={(e) => {
                      setBothPerYear(e.target.value);
                      setErrObj({
                        ...errObj,
                        bothPerYearErr: false,
                        bothPerYearMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per year</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={11.6} md={5.9} className="lastfirst">
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={bothPerHour}
                    error={errObj.bothPerHourErr}
                    helperText={
                      errObj.bothPerHourErr ? errObj.bothPerHourMsg : null
                    }
                    type="number"
                    onChange={(e) => {
                      setBothPerHour(e.target.value);
                      setErrObj({
                        ...errObj,
                        bothPerHourErr: false,
                        bothPerHourMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>£</span>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per hour</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </Grid>
    );
  }

  function renderThird() {
    return (
      <Grid container direction="column" style={{ minHeight: sm ? 320 : 500 }}>
        <Typography
          className="primarycolor fontsize"
          style={{ marginBottom: 10, textAlign: sm ? "center" : "unset" }}
        >
          When can you start?
        </Typography>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={availability === "now"}
                  onChange={(v) => {
                    setAvailability(v.target.value);
                  }}
                  value="now"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  I’m available now
                </Typography>
              </div>
            </Grid>
            {availability === "now" ? (
              <>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={availability === "from"}
                  onChange={(v) => {
                    setAvailability(v.target.value);
                  }}
                  value="from"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  I’m available from
                </Typography>
              </div>
            </Grid>
            {availability === "from" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={3.9}
                  style={{ display: "flex", marginTop: sm ? 16 : 0 }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={availDate}
                      onChange={(newValue) => {
                        SetAvailDate(newValue);
                        setOpenPicker(false);
                        setErrObj({
                          ...errObj,
                          fromDateErr: false,
                          fromDateMsg: "",
                        });
                      }}
                      open={openPicker}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          // className={classes.inputFieldStyle}
                          {...params}
                          error={errObj.fromDateErr}
                          helperText={
                            errObj.fromDateErr ? errObj.fromDateMsg : null
                          }
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Select Date",
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  className="datepicker pickerStyle"
                                  onClick={() => {
                                    setOpenPicker(!openPicker);
                                  }}
                                >
                                  <img
                                    alt="error"
                                    src={Images.picker}
                                    style={{ width: 24 }}
                                  />
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={availability === "not-available"}
                  onChange={(v) => {
                    setAvailability(v.target.value);
                  }}
                  value="not-available"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  I’m not available
                </Typography>
              </div>
            </Grid>
            {availability === "not-available" ? (
              <>
                <Grid item xs={3.9}></Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>
        </div>
      </Grid>
    );
  }

  function renderFourth() {
    return (
      <Grid container direction="column" style={{ minHeight: sm ? 320 : 500 }}>
        <Typography
          className="primarycolor fontsize"
          style={{ marginBottom: 10, textAlign: sm ? "center" : "unset" }}
        >
          Which region(s) would you like to find work in?
        </Typography>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CDropDown
            disable={remoteOnly ? true : false}
            data={dropdowndata?.region}
            value={selectedLocations}
            error={errObj.regionErr}
            helperText={errObj.regionErr ? errObj.regionMsg : null}
            onChange={(v) => {
              setSelextedLocations(v.target.value);
              setErrObj({ ...errObj, regionErr: false, regionMsg: "" });
            }}
            multiple={true}
            placeholder="Select or enter a location for the UK only"
            style={{ maxWidth: 500 }}
          />
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Typography className="mediumfont">
              {isSelected ? "Selected locations" : "Remote only"}
            </Typography>
          </div>
          {isSelected ? (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {selectedLocations.map((item, index) => {
                return (
                  <div key={index} className="selextedlocationmain">
                    <Typography className="regulerlargefont lightsilvercolor">
                      {item?.label}
                    </Typography>
                    <Button
                      variant="contained"
                      className="closebtn"
                      onClick={() => {
                        removeLocation(index);
                      }}
                    >
                      <Close style={{ fontSize: 16 }} />
                    </Button>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="checkboxmain">
              <Checkbox
                checked={remoteOnly}
                onChange={() => {
                  setRemoteOnly(!remoteOnly);
                  setErrObj({ ...errObj, regionErr: false, regionMsg: "" });
                }}
              />
              <Typography
                className="lightsilvercolor regulerfont"
                style={{ lineHeight: sm ? 1.5 : 0 }}
              >
                This option will connect you with candidates from all over the
                UK
              </Typography>
            </div>
          )}
        </div>
      </Grid>
    );
  }

  function renderFifth() {
    return (
      <Grid container direction="column" style={{ minHeight: sm ? 320 : 500 }}>
        <Typography
          className="primarycolor fontsize"
          style={{ marginBottom: 10, textAlign: sm ? "center" : "unset" }}
        >
          Enter your Postcode or Location.
        </Typography>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            placeholder="Enter your Postcode or Location"
            value={postCode}
            error={errObj.postCodeErr}
            helperText={errObj.postCodeErr ? errObj.postCodeMsg : null}
            onChange={(v) => {
              setPostCode(v.target.value);
              setErrObj({ ...errObj, postCodeErr: false, postCodeMsg: "" });
            }}
            InputProps={{
              endAdornment: <AddLocationAlt style={{ color: "#1F4771" }} />,
            }}
            style={{ maxWidth: 500 }}
          />
        </div>
      </Grid>
    );
  }

  function renderSixth() {
    return (
      <Grid container direction="column" style={{ minHeight: sm ? 320 : 500 }}>
        <Typography
          className="primarycolor fontsize"
          style={{ marginBottom: 10, textAlign: sm ? "center" : "unset" }}
        >
          What kind of company would you like to work for?
        </Typography>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CDropDown
            data={dropdowndata?.company_culture}
            placeholder="Culture"
            label="Select the culture"
            value={culture}
            error={errObj.cultureErr}
            helperText={errObj.cultureErr ? errObj.cultureMsg : null}
            onChange={(v) => {
              setCulture(v.target.value);
              setErrObj({
                ...errObj,
                cultureErr: false,
                cultureMsg: "",
              });
            }}
            style={{ maxWidth: sm ? "unset" : 300 }}
          />
          <CDropDown
            data={dropdowndata?.company_size}
            placeholder="Company size"
            label="Select the company size"
            value={companySize}
            error={errObj.companySizeErr}
            helperText={errObj.companySizeErr ? errObj.companySizeMsg : null}
            onChange={(v) => {
              setCompanySize(v.target.value);
              setErrObj({
                ...errObj,
                companySizeErr: false,
                companySizeMsg: "",
              });
            }}
            style={{ maxWidth: sm ? "unset" : 300 }}
          />
        </div>
      </Grid>
    );
  }

  function renderSeventh() {
    return (
      <Grid container direction="column" style={{ minHeight: sm ? 320 : 500 }}>
        <Typography
          className="primarycolor fontsize"
          style={{ marginBottom: 10 }}
        >
          Candidate Sign up
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Grid container>
            <Grid item xs={12} sm={5} className="detailmain">
              <OutlinedInput
                placeholder="First name*"
                value={firstName}
                onChange={(v) => {
                  setFirstName(v.target.value);
                }}
                style={{ width: md ? "100%" : "70%", marginBottom: 16 }}
              />
              <OutlinedInput
                placeholder="Last name*"
                value={lastName}
                onChange={(v) => {
                  setLastName(v.target.value);
                }}
                style={{ width: md ? "100%" : "70%", marginBottom: 16 }}
              />
              <OutlinedInput
                placeholder="Email*"
                value={email}
                onChange={(v) => {
                  setEmail(v.target.value);
                }}
                style={{ width: md ? "100%" : "70%", marginBottom: 16 }}
              />
              <OutlinedInput
                placeholder="Phone*"
                value={phone}
                onChange={(v) => {
                  setPhone(v.target.value);
                }}
                style={{ width: md ? "100%" : "70%", marginBottom: 16 }}
              />
              <OutlinedInput
                placeholder="Password*"
                autoComplete="new-password"
                type="password"
                value={password}
                onChange={(v) => {
                  setPassword(v.target.value);
                }}
                style={{ width: md ? "100%" : "70%", marginBottom: 16 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              className="dividermain"
              justifyContent="space-between"
            >
              <div className="divider" />
              <Typography>OR</Typography>
              <div className="divider" />
            </Grid>
            <Grid item xs={12} sm={5} direction="column" className="socialmain">
              <GoogleLoginButton
                from="SignUp"
                onGetUserInfo={(v) => {
                  setSocialUserInfo(v);
                  setOpenModal(true);
                }}
              />
              <FBLoginButton
                from="SignUp"
                onGetUserInfo={(v) => {
                  setSocialUserInfo(v);
                  setOpenModal(true);
                }}
              />
              <LinkedinLoginButton />
            </Grid>
          </Grid>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={agree}
            onChange={() => {
              setAgree(!agree);
            }}
            style={{ width: 40, marginLeft: -9 }}
          />
          <Typography
            className="lightsilvercolor"
            style={{ color: "#1F47714D" }}
          >
            I agree to the{" "}
            <span className="primarycolor" style={{ cursor: "pointer" }}>
              <u>T&Cs</u>
            </span>{" "}
            and{" "}
            <span className="primarycolor" style={{ cursor: "pointer" }}>
              <u>Privacy Policy</u>
            </span>
          </Typography>
        </div>
      </Grid>
    );
  }

  function renderForm() {
    if (activeStep === 0) {
      return renderfirst();
    } else if (activeStep === 1) {
      return renderSecond();
    } else if (activeStep === 2) {
      return renderThird();
    } else if (activeStep === 3) {
      return renderFourth();
    } else if (activeStep === 4) {
      return renderFifth();
    } else if (activeStep === 5) {
      return renderSixth();
    } else if (activeStep === 6) {
      return renderSeventh();
    }
  }

  function renderMain() {
    return (
      <Box sx={{ flexGrow: 1, paddingTop: 4 }}>
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: 30 }}>
            <CStepper data={steps} activeStep={activeStep} />
          </Grid>
          <Grid item container xs={12} style={{ marginTop: 30 }}>
            {renderForm()}
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={firstStep ? "flex-end" : "space-between"}
            style={{ marginTop: 30 }}
          >
            {firstStep || lastStep ? null : (
              <Button
                onClick={() => {
                  handleBack();
                }}
                startIcon={<ArrowBack />}
                variant={"outlined"}
                style={{ width: sm ? 130 : md ? 200 : 300 }}
              >
                {"Previous"}
              </Button>
            )}
            <Button
              onClick={() => {
                if (loader) {
                  return null;
                }
                checkStepsValidation();
              }}
              endIcon={loader ? null : <ArrowForward />}
              variant="contained"
              style={{ width: sm ? 130 : md ? 200 : 300 }}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={28} />
              ) : lastStep ? (
                "Sign up"
              ) : (
                "next"
              )}
            </Button>
          </Grid>
        </Grid>
        <MUserDetail
          visible={openModal}
          handleModal={() => {
            setOpenModal(false);
            setModalSaveBtnLoader(false);
          }}
          socialUserInfo={socialUserInfo}
          modalSaveBtnLoader={modalSaveBtnLoader}
          onSignUpAction={(v) => {
            camdidateSignupProcess(v);
            setModalSaveBtnLoader(true);
          }}
        />
      </Box>
    );
  }

  return <div>{renderMain()}</div>;
}
