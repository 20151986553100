import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { AddCircle } from "@mui/icons-material";
import { find, isArray, isEmpty } from "lodash";
import MLanguage from "../Modal/MLanguage";
import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import useStyles from "../styles";

function Languages(props) {
  const {
    from = "",
    data = {},
    data2 = {},
    list = [],
    employer = false,
    setData = () => null,
    updateProfileState = () => null,
  } = props;
  const { dropdowndata } = useSelector((state) => state.auth);
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const isEmplyee = from === "employee";

  const [editData, setEditData] = useState({});
  const isComplete = !isEmpty(editData);

  const isDataEmpty = !isEmpty(data2);

  useEffect(() => {
    if (isDataEmpty) {
      setEditData(data2.languages || []);
    }
  }, [data2]);

  useEffect(() => {
    setData(editData);
  }, [editData]);

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="language" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>
          {from === "postjob" ? "Language required" : "Languages"}
        </Typography>
        {from === "employee" ? null : (isArray(data?.candidate_language) &&
            data?.candidate_language.length > 0) ||
          isComplete ? (
          <div className={classes.editBtnStyle} onClick={() => setModal(true)}>
            <img src={Images.edit} style={{ width: 14 }} />
          </div>
        ) : (
          <AddCircle
            color={color.primary}
            className={classes.addIcon}
            onClick={() => setModal(true)}
          />
        )}
      </div>
      <Typography className={classes.sectionDesc}>
        {from === "employee"
          ? "-"
          : from === "postjob"
          ? "Put your hard-won qualifications to good use."
          : "If you speak any other languages, make sure to mention it to potential employers."}
      </Typography>
      <div className={classes.contentDetailContainer}>
        <div className={classes.skillDivStyle}>
          {employer && isComplete
            ? editData.map((item, index) => {
                const obj = find(
                  dropdowndata?.language,
                  (key) => key.value === item.value
                );
                return (
                  <div key={index} className={classes.tagViewStyle}>
                    <Typography variant="body2" style={{ color: "#5A5A5A" }}>
                      {`${obj?.label} - ${item.preference}` || ""}
                    </Typography>
                  </div>
                );
              })
            : isArray(data?.candidate_language) &&
              data?.candidate_language.length > 0 &&
              data?.candidate_language.map((item, index) => {
                const obj = find(list, (key) => key.value === item.language_id);
                return (
                  <div key={index} className={classes.tagViewStyle}>
                    <Typography variant="body2" style={{ color: "#5A5A5A" }}>
                      {`${isEmplyee ? item?.language : obj?.label} - ${
                        item.preference
                      }` || ""}
                    </Typography>
                  </div>
                );
              })}
        </div>
      </div>
      <MLanguage
        visible={modal}
        list={dropdowndata?.language}
        data={
          employer && isComplete ? editData : data?.candidate_language || []
        }
        handleModal={(detail, type) => {
          if (type === "update" && updateProfileState) {
            updateProfileState(detail, "candidate_language");
          }
          setModal(false);
        }}
        onSave={(data) => {
          setEditData(data);
          setModal(false);
        }}
        employer={employer}
      />
    </Grid>
  );
}
export default Languages;
