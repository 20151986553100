import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { ArrowForward, ArrowBackOutlined } from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Images from "../../../../Config/Images";
import useStyles from "../../styles";
import { isDate, isEmpty } from "lodash";

const errorObj = {
  nameErr: false,
  websiteErr: false,
  fromDateErr: false,
  toDateErr: false,
  descriptionErr: false,
  nameMsg: "",
  websiteMsg: "",
  fromdateMsg: "",
  toDateMsg: "",
  descriptionMsg: "",
};

function MExperience(props) {
  const {
    visible = false,
    handleModal = () => null,
    onSave = () => null,
    loader = false,
    itemData = {},
  } = props;
  const classes = useStyles();
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [currentlyWork, setCurrentlyWork] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setDescription] = useState("");
  const [picker, setPicker] = useState("");
  const [errObj, setErrObj] = useState(errorObj);

  useEffect(() => {
    if (visible) {
      setCompanyName(itemData?.company_name || "");
      setWebsite(itemData?.company_website || "");
      setCurrentlyWork(
        itemData?.currently_working === 1 ? true : false || false
      );
      setStartDate(new Date(itemData?.from_date) || null);
      setEndDate(new Date(itemData?.to_date) || null);
      setDescription(itemData?.description || "");
    }
    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  // this function for reset form data
  function resetFormData(type = "") {
    setCompanyName("");
    setWebsite("");
    setCurrentlyWork(false);
    setStartDate(null);
    setEndDate(null);
    setDescription("");
    setErrObj(errorObj);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(companyName)) {
      valid = false;
      error.nameErr = true;
      error.nameMsg = "Please enter company name";
    } else if (isEmpty(website)) {
      valid = false;
      error.websiteErr = true;
      error.websiteMsg = "Please enter company website";
    } else if (!isDate(startDate)) {
      valid = false;
      error.fromDateErr = true;
      error.fromdateMsg = "Select start date";
    } else if (!isDate(endDate)) {
      valid = false;
      error.toDateErr = true;
      error.toDateMsg = "Select end date";
    } else if (isEmpty(description)) {
      valid = false;
      error.descriptionErr = true;
      error.descriptionMsg = "please enter description";
    }

    setErrObj(error);

    const experience_data = {
      companyName: companyName,
      website: website,
      startDate: startDate,
      endDate: endDate,
      description: description,
      currentlyWork: currentlyWork,
    };
    if (valid) {
      onSave(experience_data);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle}>
            <Typography variant="subtitle1" component="h2">
              Add Experience
            </Typography>
            <img
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className={classes.formDivStyle}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Company name*"
              className={classes.inputFieldStyle}
              value={companyName}
              error={errObj.nameErr}
              onChange={(e) => {
                setCompanyName(e.target.value);
                setErrObj({ ...errObj, nameErr: false, nameMsg: "" });
              }}
              helperText={errObj.nameErr ? errObj.nameMsg : null}
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Company website*"
              className={classes.inputFieldStyle}
              value={website}
              error={errObj.websiteErr}
              helperText={errObj.websiteErr ? errObj.websiteMsg : null}
              onChange={(e) => {
                setWebsite(e.target.value);
                setErrObj({ ...errObj, websiteErr: false, websiteMsg: "" });
              }}
            />
            <FormControlLabel
              style={{ marginBottom: 15 }}
              control={
                <Checkbox
                  checked={currentlyWork}
                  onChange={() => setCurrentlyWork(!currentlyWork)}
                  name="work"
                />
              }
              label={
                <Typography variant="body2" className={classes.checkBoxText}>
                  I currently work here
                </Typography>
              }
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                  setPicker("");
                  setErrObj({ ...errObj, fromDateErr: false, fromdateMsg: "" });
                }}
                open={picker === "from"}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    className={classes.inputFieldStyle}
                    {...params}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            className={`${classes.cViewStyle} ${classes.pickerStyle}`}
                            onClick={() => setPicker("from")}
                          >
                            <img src={Images.picker} style={{ width: 24 }} />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    error={errObj.fromDateErr}
                    helperText={errObj.fromDateErr ? errObj.fromdateMsg : null}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                  setPicker("");
                  setErrObj({ ...errObj, toDateErr: false, toDateMsg: "" });
                }}
                open={picker === "to"}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    className={classes.inputFieldStyle}
                    {...params}
                    error={errObj.toDateErr}
                    helperText={errObj.toDateErr ? errObj.toDateMsg : null}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            className={`${classes.cViewStyle} ${classes.pickerStyle}`}
                            onClick={() => setPicker("to")}
                          >
                            <img src={Images.picker} style={{ width: 24 }} />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Description"
              className={classes.inputFieldStyle}
              multiline
              minRows={6}
              maxRows={6}
              style={{ padding: "0px !important" }}
              value={description}
              error={errObj.descriptionErr}
              helperText={errObj.descriptionErr ? errObj.descriptionMsg : null}
              onChange={(e) => {
                setDescription(e.target.value);
                setErrObj({
                  ...errObj,
                  descriptionErr: false,
                  descriptionMsg: "",
                });
              }}
            />
          </div>
          <div className={classes.splitViewStyle}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ArrowBackOutlined />}
              className={classes.modalBtnStyle}
              onClick={resetFormData}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              endIcon={loader ? null : <ArrowForward />}
              className={classes.modalBtnStyle}
              onClick={() => {
                checkValidation();
              }}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={26} />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default MExperience;
