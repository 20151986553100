import React, { useEffect, useState } from "react";
import "./styles.css";
import { Setting } from "../../../Utils/Setting";
import { Typography, Grid, CircularProgress } from "@mui/material";
import { getApiData } from "../../../Utils/APIHelper";
import { useSelector } from "react-redux";

function Privacy_Policy() {
  useEffect(() => {
    document.title = Setting.page_name.PRIVACY;
    getPrivacyList();
  }, []);

  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState({});
  const [pageLoader, setPageLoader] = useState(false);

  // this function for get privacy policy data
  async function getPrivacyList() {
    setPageLoader(true);
    let endpoint = `${Setting.endpoints.cmsdetails}?slug=privacy_policy`;
    try {
      const response = await getApiData(
        endpoint,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setData(response?.data);
        setPageLoader(false);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoader(false);
    }
  }

  return (
    <div>
      <Grid container>
        {pageLoader ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              height: 500,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Typography className="primarycolor fontsize2">
              Privacy Policy
            </Typography>
            <div
              style={{ marginTop: 10 }}
              dangerouslySetInnerHTML={{
                __html: data?.app_body || "",
              }}
            />
          </>
        )}
      </Grid>
    </div>
  );
}

export default Privacy_Policy;
