import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "3% !important",
    marginBottom: "50px !important",
    "@media (max-width:1024px)": {
      marginTop: "3% !important",
    },
    "@media (max-width:425px)": {
      marginTop: "6% !important",
    },
  },
  shadowViewStyle: {
    boxShadow: "rgb(48 48 48 / 14%) 0px 3px 6px 2px",
    borderRadius: 3,
  },
  paddingStyle: {
    padding: "20px 25px",
  },
  marginDiv: {
    marginBottom: "24px !important",
  },
  sectionTitle: {
    color: color.primary,
    fontFamily: "PoppinsSemiBold !important",
    fontSize: "20px !important",
  },
  sectionDesc: {
    color: color.black,
    fontFamily: "PoppinsRegular !important",
    fontSize: "14px !important",
    margin: "5px 0px !important",
  },
  skillDivStyle: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 15,
    "@media (max-width:425px)": {
      marginLeft: "0px !important",
    },
  },
  tagViewStyle: {
    position: "relative",
    border: "0.5px solid #1F4771",
    background: "#F5F9FF 0% 0% no-repeat padding-box",
    borderRadius: 4,
    padding: "10px 15px",
    marginRight: 25,
  },
  closeIconDivStyle: {
    position: "absolute",
    backgroundColor: color.primary,
    height: 20,
    width: 20,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: -10,
    right: -10,
    cursor: "pointer",
  },
  closeIconStyle: { fontSize: "15px !important", color: color.white },
  cViewStyle: {
    backgroundColor: color.primary,
    padding: "10px 16px",
    color: color.white,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    fontSize: 14,
    marginRight: -14,
    cursor: "pointer",
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 40,
  },
  searchTagDiv: {
    margin: "12px 0px",
    display: "flex",
    alignItems: "center",
    "@media (max-width:425px)": {
      display: "unset",
    },
  },
  inputDivStyle: {
    "@media (max-width:425px)": {
      margin: "20px 0px !important",
    },
  },
  loaderView: {
    width: "100%",
    minHeight: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noDataView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 220,
    width: "100%",
  },
  sectionTitle: {
    color: color.primary,
    fontFamily: "PoppinsSemiBold !important",
    fontSize: "20px !important",
  },
}));

export default useStyles;
