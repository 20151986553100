import React, { useEffect, useState } from "react";
import {
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  Button,
  LinearProgress,
  CircularProgress,
  Link,
} from "@mui/material";
import { styled } from "@mui/styles";
import { Add, ArrowForward } from "@mui/icons-material";
import { find, isArray, isEmpty, isObject } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Availability from "../../../Components/Profile/Availability";
import JobType from "../../../Components/Profile/JobType";
import Experience from "../../../Components/Profile/Experience";
import Skills from "../../../Components/Profile/Skills";
import Education from "../../../Components/Profile/Education";
import Languages from "../../../Components/Profile/Languages";
import Bio from "../../../Components/Profile/Bio";
import SkillQuiz from "../../../Components/Profile/SkillQuiz";
import SendMessageModal from "../../../Components/SendMessageModal";
import BasicInfo from "../../../Components/Profile/Modal/BasicInfo";
import MWelcome from "../../../Components/Profile/Modal/MWelcome";
import Images from "../../../Config/Images";
import MPurchase from "../../../Components/Profile/Modal/MPurchase";
import MOffers from "../../../Components/Profile/Modal/MOffers";
import authActions from "../../../Redux/reducers/auth/actions";
import { color } from "../../../Config/theme";
import {
  profileSideBarArray,
  profileSideBarArray1,
} from "../../../Config/Static_Data";
import { Setting } from "../../../Utils/Setting";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import useStyles from "./styles";

const { setCandidateProfile, setCommunication } = authActions;
let completedProfile = 0;
export default function Profile() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { token, dropdowndata } = useSelector((state) => state.auth);
  const from = location?.state?.from || "";
  const jobId = location?.state?.jobId || null;
  const userId = location?.state?.userId || null;
  const [openImg, setOpenImg] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoLoad, setVideoLoad] = useState(false);
  const [unlockProfile, setUnlockProfile] = useState(false);
  const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
  const [msgModalVisible, setMsgModalVisible] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const [profileDetail, setProfileDetail] = useState({});
  const [languageList, setLanguageList] = useState(false);
  const [profileVisiblity, setProfileVisibility] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadLoader, setUploadLoader] = useState("");
  const [pageLoad, setPageLoad] = useState(true);

  const header = { Authorization: `Bearer ${token}` };

  const CustomSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: "58px !important",
    height: "36px !important",
    padding: "6px !important",
    "& .MuiSwitch-switchBase": {
      padding: 1,
      left: 5,
      top: 4,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(21px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: color.primary,
          opacity: 1,
          border: "none",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: color.primary,
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      color: "#d4dbe5",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      border: "1px solid #d4dbe5",
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
  }));

  useEffect(() => {
    document.title = Setting.page_name.CANDIDATE_PROFILE;
    window.scrollTo(0, 0);
    if (from !== "employee") {
      setWelcomeModal(true);
    }
  }, []);

  useEffect(() => {
    if (from === "employee" && jobId && userId) {
      getUserDetail();
    } else if (token !== "") {
      getProfileDetail();
      getLanguageList();
    }
  }, [token]);

  useEffect(() => {
    if (!isEmpty(profileDetail)) {
      completedProfile = 0;
      setTimeout(() => {
        calculateProfilePercent();
      }, 200);
    }
  }, [profileDetail]);

  // this function for update visibility status
  async function updateVisibility(bool) {
    try {
      const response = await getApiData(
        `${Setting.endpoints.employeeVisibility}?visibility=${bool}`,
        "GET",
        null,
        header
      );

      if (response.status) {
        toast.success(response?.message);
        setProfileVisibility(response?.data?.visibility === "1" ? true : false);
      } else {
        toast.error(response.message || "");
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  // this function for update profile detail state
  function updateProfileState(data, type = "") {
    const obj = { ...profileDetail };
    obj[type] = data;
    setProfileDetail(obj);
  }

  // this function for get profile detail
  async function getProfileDetail() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        Setting.endpoints.employeeProfile,
        "GET",
        null,
        header
      );

      if (response.status) {
        setProfileDetail(response.data);
        setProfileVisibility(response?.data?.visibility === 1 ? true : false);
        setVideoUrl(response?.data?.user_video || "");
        dispatch(setCandidateProfile(response?.data));
        dispatch(setCommunication(response?.data?.communication_preference));
      } else {
        toast.error(response.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>", error);
      setPageLoad(false);
    }
  }

  // this function for get user detail
  async function getUserDetail() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.employerUserDetails}?user_id=${userId}&job_post_id=${jobId}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setProfileDetail(response.data);
        setVideoUrl(response?.data?.user_video || "");
        setUnlockProfile(response?.data?.is_payment || false);
      } else {
        toast.error(response.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  }

  // this function for calculate profile percentage
  function calculateProfilePercent() {
    const {
      user_bio = {},
      user_video = "",
      user_image = "",
      candidate_language = [],
      user_education = [],
      skills = [],
      user_experience = [],
      job_role_type = "",
      availablity_type = "",
    } = profileDetail;

    if (profileDetail?.firstname) {
      completedProfile += 1;
    }
    if (profileDetail?.lastname) {
      completedProfile += 1;
    }
    if (profileDetail?.email) {
      completedProfile += 1;
    }
    if (profileDetail?.phone) {
      completedProfile += 1;
    }
    if (profileDetail?.city_id) {
      completedProfile += 1;
    }
    if (user_bio?.birth_date) {
      completedProfile += 1;
    }
    if (user_bio?.permanent_address) {
      completedProfile += 1;
    }
    if (user_bio?.gender) {
      completedProfile += 1;
    }
    if (user_bio?.hometown) {
      completedProfile += 1;
    }
    if (user_bio?.bio_description) {
      completedProfile += 1;
    }
    if (user_image) {
      completedProfile += 5;
    }
    if (user_video) {
      completedProfile += 5;
    }
    if (isArray(candidate_language) && candidate_language.length > 0) {
      completedProfile += 15;
    }
    if (isArray(user_education) && user_education.length > 0) {
      completedProfile += 15;
    }
    if (isArray(skills) && skills.length > 0) {
      completedProfile += 20;
    }
    if (isArray(user_experience) && user_experience.length > 0) {
      completedProfile += 20;
    }
    if (job_role_type !== "") {
      completedProfile += 5;
    }
    if (availablity_type !== "") {
      completedProfile += 5;
    }
  }

  // this function for get language list
  async function getLanguageList() {
    try {
      const response = await getApiData(
        Setting.endpoints.dropdown,
        "GET",
        null,
        header
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response.data.language) &&
          response.data.language.length > 0
        ) {
          setLanguageList(response.data.language);
        }
      }
    } catch (error) {
      console.log("error ===>>", error);
    }
  }

  // this function for edit profile
  async function editProfileDetail(data1) {
    setLoader(true);
    const data = {
      "EditProfileForm[first_name]": data1?.firstName,
      "EditProfileForm[last_name]": data1?.lastName,
      "EditProfileForm[email]": data1?.email,
      "EditProfileForm[phone]": data1?.number,
      "EditProfileForm[city_id]": data1?.city,
    };
    try {
      const response = await getAPIProgressData(
        Setting.endpoints.editEmployeeProfile,
        "POST",
        data,
        true
      );

      if (response.status) {
        setLoader(false);
        toast.success(response.message);
        setBasicModal(false);
        getProfileDetail();
      } else {
        toast.error(response.message);
        setLoader(false);
      }
    } catch (error) {
      console.log("error ===>>", error);
      setLoader(false);
    }
  }

  // this function handles user selected image for upload
  function uploadItem(file, type = "") {
    const displayUrl = URL.createObjectURL(file);

    if (type === "video") {
      setVideoLoad(true);
      setVideoUrl("");
    }

    setTimeout(() => {
      if (type === "image") {
        setOpenImg(displayUrl);
        uploadData(file, "img");
      } else {
        setVideoUrl(displayUrl);
        setVideoLoad(false);
        uploadData(file, "video");
      }
    }, 1000);
  }

  // this function for upload profile picture
  async function uploadData(url, type) {
    let endpoint = Setting.endpoints.employeeEditPhoto;
    let obj = {
      "ImageForm[photo]": url,
    };

    if (type === "video") {
      endpoint = Setting.endpoints.employeeAddProfileVideo;
      obj = {
        "ProfileVideoForm[video]": url,
      };
    }

    setUploadLoader(type);
    try {
      const response = await getAPIProgressData(endpoint, "POST", obj, header);

      if (response.status) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
        if (type === "img") {
          setOpenImg("");
        } else {
          setVideoUrl("");
        }
      }
      setUploadLoader("");
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setUploadLoader("");
      if (type === "img") {
        setOpenImg("");
      } else {
        setVideoUrl("");
      }
    }
  }

  // this function for scroll
  function redirect(type) {
    if (document) {
      let section = null;

      if (type === "availability") {
        section = document.querySelector("#availability");
      } else if (type === "jobtype") {
        section = document.querySelector("#jobtype");
      } else if (type === "experience") {
        section = document.querySelector("#experience");
      } else if (type === "skills") {
        section = document.querySelector("#skills");
      } else if (type === "education") {
        section = document.querySelector("#education");
      } else if (type === "language") {
        section = document.querySelector("#language");
      } else if (type === "bio") {
        section = document.querySelector("#bio");
      }

      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }

  // this function renders profile from employee
  function renderProfile() {
    return (
      <Grid container className={classes.employeeContainer}>
        <Grid item xs={12} sm={3} className={classes.profileImgSection}>
          <img
            src={profileDetail?.user_image}
            className={classes.profileImgStyle}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Container className={classes.detailContainer}>
            <Grid xs={12}>
              <Typography className={classes.userTitleStyle}>{`${
                profileDetail?.firstname || ""
              } ${profileDetail?.lastname || ""}`}</Typography>
              {profileDetail?.user_bio?.bio_description && (
                <>
                  <Typography
                    className={classes.sectionDesc}
                    style={{ color: "#000" }}
                  >
                    Bio
                  </Typography>
                  <Typography className={classes.sectionDesc}>
                    {profileDetail?.user_bio?.bio_description || "-"}
                  </Typography>
                </>
              )}
              {!unlockProfile ? (
                <div className={classes.unlockProfileSection}>
                  <Typography className={classes.sectionDesc}>
                    To view{" "}
                    {`${profileDetail?.firstname || ""} ${
                      profileDetail?.lastname || ""
                    }`}{" "}
                    contact details click on{" "}
                    <span className={classes.unlockText}>"Unlock Profile"</span>
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForward />}
                    className={classes.unlockBtnStyle}
                    onClick={() => {
                      setOpenPurchaseModal(true);
                    }}
                  >
                    Unlock Profile
                  </Button>
                </div>
              ) : (
                <div style={{ marginTop: 20 }}>
                  <Typography className={classes.sectionDesc}>
                    {`${profileDetail?.firstname || ""} ${
                      profileDetail?.lastname || ""
                    }`}{" "}
                    contact details are{" "}
                    <span className={classes.unlockText}>"Unlocked"</span>
                  </Typography>
                  <Grid container style={{ marginTop: 15 }}>
                    <Grid item xs={12} sm={5} md={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        endIcon={<ArrowForward />}
                        fullWidth
                        className={classes.makeOfferBtnStyle}
                        onClick={() => setOfferModal(true)}
                      >
                        Make Offer
                      </Button>
                    </Grid>
                    <div style={{ width: 20 }} />
                    <Grid item xs={12} sm={5} md={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        endIcon={<ArrowForward />}
                        fullWidth
                        onClick={() => setMsgModalVisible(true)}
                      >
                        Message
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Container>
        </Grid>
      </Grid>
    );
  }

  // this function renders profile section
  function renderProfileView() {
    const bioDetail = profileDetail?.user_bio || {};
    const cityData = find(
      dropdowndata?.city,
      (item) => item.value === profileDetail?.city_id
    );
    return (
      <Container className={classes.profileContainerStyle}>
        <Grid item xs={12} sm={8} md={9}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              className={classes.deviceContentResponsive}
            >
              <span style={{ position: "relative" }}>
                <img
                  src={
                    openImg || profileDetail?.user_image || Images.placeholder
                  }
                  className={classes.userImgStyle}
                  onError={(e) => {
                    e.target.src = Images.placeHolder;
                  }}
                  alt="profile"
                />
                {uploadLoader === "img" && (
                  <div className={classes.imgLoader}>
                    <CircularProgress size={28} />
                  </div>
                )}
                <Button component="label" className={classes.cameraIcon}>
                  <img src={Images.camera} className={classes.cameraImgStyle} />
                  <input
                    type="file"
                    onChange={(e) => uploadItem(e.target.files[0], "image")}
                    accept="image/jpeg, image/png, image/jpg"
                    hidden
                  />
                </Button>
              </span>
              <div
                className={`${classes.splitViewStyle} ${classes.fullWidthStyle} ${classes.hideWebView}`}
                style={{ paddingLeft: 20 }}
              >
                <Typography className={classes.userTitleStyle}>
                  {`${profileDetail?.firstname || ""} ${
                    profileDetail?.lastname || ""
                  }`}
                </Typography>
                <div
                  className={classes.editBtnStyle}
                  onClick={() => setBasicModal(true)}
                >
                  <img src={Images.edit} style={{ width: 14 }} />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={8} className={classes.ml30}>
              <div
                className={`${classes.splitViewStyle} ${classes.hideMobileView}`}
              >
                <Typography className={classes.userTitleStyle}>
                  {`${profileDetail?.firstname || ""} ${
                    profileDetail?.lastname || ""
                  }`}
                </Typography>
                <div
                  className={classes.editBtnStyle}
                  onClick={() => setBasicModal(true)}
                >
                  <img src={Images.edit} style={{ width: 14 }} />
                </div>
              </div>
              <div className={classes.userContentStyle}>
                {profileDetail?.email && (
                  <div
                    className={classes.userDetailTextStyle}
                    style={{ marginRight: 60 }}
                  >
                    <img src={Images.email} className={classes.emailImgStyle} />
                    <Typography className={classes.profileDetailText}>
                      {profileDetail?.email || ""}
                    </Typography>
                  </div>
                )}
                {profileDetail?.phone && (
                  <div className={classes.userDetailTextStyle}>
                    <img
                      src={Images.phone}
                      className={classes.userDetailIconStyle}
                    />
                    <Typography className={classes.profileDetailText}>
                      {profileDetail?.phone || ""}
                    </Typography>
                  </div>
                )}
                {!isEmpty(cityData) && (
                  <div className={classes.userDetailTextStyle}>
                    <img
                      src={Images.location}
                      className={classes.userDetailIconStyle}
                    />
                    <Typography className={classes.profileDetailText}>
                      {(!isEmpty(cityData) && cityData?.label) || "-"}
                    </Typography>
                  </div>
                )}
              </div>
              <div className={classes.profilePercentDiv}>
                <div className={classes.splitViewStyle}>
                  <Typography className={classes.progessTextStyle}>
                    Profile Strength (Average)
                  </Typography>
                  <Typography className={classes.progessTextStyle}>
                    {completedProfile >= 100 ? 100 : completedProfile}%
                  </Typography>
                </div>
                <LinearProgress
                  variant="determinate"
                  value={completedProfile >= 100 ? 100 : completedProfile}
                  color="secondary"
                  style={{ marginTop: 8 }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        {completedProfile >= 100 ? null : (
          <Grid item xs={12} sm={4} md={3} className={classes.gradientDivStyle}>
            <Typography
              className={classes.sectionTitle}
              style={{ color: "#fff" }}
            >
              Pending Action
            </Typography>
            <div className={classes.actionWraper}>
              {profileDetail?.availablity_type === "" && (
                <div className={classes.actionContainer}>
                  <div className={classes.actionDot} />
                  <Typography className={classes.listTextStyle}>
                    Availability
                  </Typography>
                </div>
              )}
              {profileDetail?.job_role_type === "" && (
                <div className={classes.actionContainer}>
                  <div className={classes.actionDot} />
                  <Typography className={classes.listTextStyle}>
                    Job Type
                  </Typography>
                </div>
              )}
              {isArray(profileDetail?.user_experience) &&
                profileDetail?.user_experience.length === 0 && (
                  <div className={classes.actionContainer}>
                    <div className={classes.actionDot} />
                    <Typography className={classes.listTextStyle}>
                      Experience
                    </Typography>
                  </div>
                )}
              {isArray(profileDetail?.skills) &&
                profileDetail?.skills.length === 0 && (
                  <div className={classes.actionContainer}>
                    <div className={classes.actionDot} />
                    <Typography className={classes.listTextStyle}>
                      Skills
                    </Typography>
                  </div>
                )}
              {isArray(profileDetail?.user_education) &&
                profileDetail?.user_education.length === 0 && (
                  <div className={classes.actionContainer}>
                    <div className={classes.actionDot} />
                    <Typography className={classes.listTextStyle}>
                      Education
                    </Typography>
                  </div>
                )}
              {isArray(profileDetail?.candidate_language) &&
                profileDetail?.candidate_language.length === 0 && (
                  <div className={classes.actionContainer}>
                    <div className={classes.actionDot} />
                    <Typography className={classes.listTextStyle}>
                      Languages
                    </Typography>
                  </div>
                )}
              {bioDetail?.birth_date &&
              bioDetail?.permanent_address &&
              bioDetail?.gender &&
              bioDetail?.hometown &&
              bioDetail?.bio_description ? null : (
                <div className={classes.actionContainer}>
                  <div className={classes.actionDot} />
                  <Typography className={classes.listTextStyle}>Bio</Typography>
                </div>
              )}
              {profileDetail?.user_video === "" && (
                <div className={classes.actionContainer}>
                  <div className={classes.actionDot} />
                  <Typography className={classes.listTextStyle}>
                    Add Video
                  </Typography>
                </div>
              )}
            </div>
          </Grid>
        )}
      </Container>
    );
  }

  const sArray =
    from === "employee" ? profileSideBarArray1 : profileSideBarArray;

  if (pageLoad) {
    return (
      <Grid container>
        <div className={classes.loaderView}>
          <CircularProgress size={50} />
        </div>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.container}>
      {from === "employee" ? renderProfile() : renderProfileView()}
      <Grid item xs={12} sm={4} md={3}>
        <div className={classes.shadowViewStyle}>
          {isArray(sArray) &&
            sArray.length > 0 &&
            sArray.map((item, index) => {
              const lIndex = sArray.length - 1;
              return (
                <div
                  key={`${item.title}_index`}
                  className={classes.singleViewStyle}
                  onClick={() => redirect(item.key)}
                  style={
                    item?.type === "switch"
                      ? {
                          borderBottom:
                            lIndex === index ? "none" : "1px solid #34567b",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }
                      : {
                          borderBottom:
                            lIndex === index ? "none" : "1px solid #34567b",
                          alignItems: "center",
                        }
                  }
                >
                  {item?.icon && (
                    <img
                      src={item.icon}
                      alt="logo"
                      style={{
                        height: 25,
                        width: 25,
                        marginRight: 15,
                      }}
                    />
                  )}
                  <Typography
                    style={{
                      color: color.primary,
                      fontFamily: "PoppinsRegular",
                    }}
                  >
                    {item?.title || ""}
                  </Typography>
                  {item.type === "switch" && (
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          checked={profileVisiblity}
                          onChange={() => {
                            updateVisibility(profileVisiblity ? 0 : 1);
                            setProfileVisibility(!profileVisiblity);
                          }}
                        />
                      }
                      label=""
                      style={{ marginRight: 0 }}
                    />
                  )}
                </div>
              );
            })}
        </div>
        <div className={classes.videoContainer}>
          <Typography className={classes.videoTitle}>Your Video</Typography>
          <div className={classes.videoSectionStyle}>
            {videoUrl ? (
              <div>
                <video width="100%" className={classes.videoStyle} controls>
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </div>
            ) : (
              <Typography>
                {from === "employee" ? "No video" : "Add video"}
              </Typography>
            )}
            {from === "employee" ? null : (
              <div className={classes.buttonAbsoluteDiv}>
                <Button component="label" className={classes.videoIcon}>
                  <Add style={{ color: "#FFF" }} />
                  <input
                    type="file"
                    onChange={(e) => uploadItem(e.target.files[0], "video")}
                    accept="video/mp4, video/webm"
                    hidden
                  />
                </Button>
              </div>
            )}
            {(videoLoad || uploadLoader === "video") && (
              <CircularProgress style={{ position: "absolute" }} />
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={9}>
        <Container className={classes.detailContainer}>
          {from === "employee" ? (
            <SkillQuiz
              candidateResult={() => {
                navigate("/employer/jobs/candidate-result", {
                  state: { jobId, userId },
                });
              }}
              percent={profileDetail?.candidate_quiz_result || 0}
            />
          ) : (
            <>
              <Grid
                item
                xs={12}
                className={[
                  classes.shadowViewStyle,
                  classes.paddingStyle,
                  classes.marginDiv,
                ]}
              >
                <Typography className={classes.sectionTitle}>
                  Import CV/Resume to auto complete Direct Expertise profile
                </Typography>
                <Typography className={classes.sectionDesc}>
                  You may make an editable online resume in two ways. When you
                  use import CV/ Resume it will overwrite your profile also, it
                  can be editable.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForward />}
                  style={{ marginTop: 10 }}
                >
                  IMPORT CV/RESUME
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                className={[
                  classes.shadowViewStyle,
                  classes.paddingStyle,
                  classes.marginDiv,
                ]}
              >
                <Typography className={classes.sectionTitle}>
                  Boost your profile by taking skill quiz{" "}
                  <span style={{ color: "#ff0000" }}>*</span>
                </Typography>
                <Typography className={classes.sectionDesc}>
                  To show your profile in employer TOP 10 list you need to
                  complete the skills quiz.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForward />}
                  style={{ marginTop: 10 }}
                >
                  <Link
                    href="/candidate/quiz"
                    prefetch={false}
                    style={{ color: color.white, textDecoration: "none" }}
                  >
                    START SKILL QUIZ
                  </Link>
                </Button>
              </Grid>
            </>
          )}
          <Availability
            from={from}
            data={profileDetail}
            getProfileData={() => {
              getProfileDetail();
            }}
          />
          <JobType
            from={from}
            data={profileDetail}
            getProfileData={() => {
              getProfileDetail();
            }}
          />
          <Experience
            from={from}
            data={profileDetail}
            getProfileData={() => {
              getProfileDetail();
            }}
          />
          <Skills
            from={from}
            data={profileDetail}
            updateProfileState={updateProfileState}
          />
          <Education
            from={from}
            data={profileDetail}
            getProfileData={() => {
              getProfileDetail();
            }}
          />
          <Languages
            from={from}
            data={profileDetail}
            list={languageList}
            updateProfileState={updateProfileState}
          />
          <Bio
            from={from}
            data={profileDetail}
            updateProfileState={updateProfileState}
          />
        </Container>
      </Grid>
      <BasicInfo
        visible={basicModal}
        profileDetail={profileDetail}
        DataSave={(data) => {
          editProfileDetail(data);
        }}
        loader={loader}
        handleModal={() => setBasicModal(false)}
      />
      <MWelcome
        visible={welcomeModal}
        handleModal={() => {
          setWelcomeModal(false);
        }}
      />
      <SendMessageModal
        visible={msgModalVisible}
        handleModal={() => setMsgModalVisible(false)}
      />
      <MPurchase
        visible={openPurchaseModal}
        data={profileDetail || {}}
        jobId={jobId}
        candidateId={userId}
        handleModal={() => {
          setOpenPurchaseModal(false);
        }}
        puchaseSuccess={() => {
          setUnlockProfile(true);
        }}
      />
      <MOffers visible={offerModal} handleModal={() => setOfferModal(false)} />
    </Grid>
  );
}
