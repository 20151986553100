import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { isArray, isEmpty } from "lodash";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Link,
} from "@mui/material";
import { Add, ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Images from "../../../Config/Images";
import { Setting } from "../../../Utils/Setting";
import { getApiData } from "../../../Utils/APIHelper";
import MDetail from "../../../Components/Profile/Modal/MDetail";

export default function Company() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoLoad, setVideoLoad] = useState(false);
  const [companyDetail, setCompanyDetail] = useState({});
  const [uploadLoader, setUploadLoader] = useState("");
  const [detailModal, setDetailModal] = useState(false);
  const [activeJob, setActiveJob] = useState({
    data: [],
    pageData: {},
  });
  const [pageLoad, setPageLoad] = useState(true);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const header = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    document.title = Setting.page_name.EMPLOYER_COMPANY;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getCompanyDetails();
    getActiveJobList();
  }, []);

  // this function for get company details
  async function getCompanyDetails() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        Setting.endpoints.employerCompanyDetails,
        "GET",
        {},
        header
      );

      if (response.status) {
        if (!isEmpty(response.data)) {
          setCompanyDetail(response.data);
          setVideoUrl(response?.data?.company_video);
        }
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  }

  // this function for get active job list
  async function getActiveJobList() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.employerJobList}?job_status=1`,
        "GET",
        {},
        header
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data.rows)) {
          setActiveJob({
            data: response?.data?.rows || [],
            pageData: response?.data?.pagination,
          });
        }
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  }

  // this function for redirect to profile page as preview
  function previewProfile() {
    navigate("/company/profile", { state: { from: "preview" } });
  }

  // this function for redirect to candidates list page
  function viewCandidates() {
    navigate("/employer/jobs/candidates");
  }

  // this function redirects to post new job screen
  function postJob() {
    navigate("/employer/jobs/post-job");
  }

  function renderLeft() {
    return (
      <Grid item xs={12} sm={8} md={9}>
        <Container className={classes.detailContainer}>
          <Grid
            item
            xs={12}
            className={[classes.detailmain, classes.paddingmain]}
          >
            <Grid container>
              <Grid sm={12} md={3} className={classes.topimgmain}>
                <img
                  src={companyDetail?.company_logo}
                  className={classes.companyimgmain}
                  alt="error"
                />
              </Grid>
              <Grid
                sm={12}
                md={9}
                direction="column"
                className={classes.editprofilemain}
              >
                <Typography
                  className="primarycolor mediumsemiboldfont"
                  style={{ marginTop: 10 }}
                >
                  {companyDetail?.company_name || "-"}
                </Typography>
                <Typography
                  className="lightsilvercolor regulerfont"
                  style={{ marginBottom: 10 }}
                >
                  {companyDetail?.tag_line || "-"}
                </Typography>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={5.6}
                    style={{ marginRight: sm ? 0 : 10, marginBottom: 10 }}
                  >
                    <Link
                      href="/company/profile"
                      prefetch={false}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        variant="outlined"
                        endIcon={<ArrowForward />}
                        style={{ width: "100%" }}
                      >
                        EDIT PROFILE
                      </Button>
                    </Link>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5.6}
                    md={5.6}
                    style={{ marginBottom: 10 }}
                  >
                    <Button
                      variant="outlined"
                      endIcon={<ArrowForward />}
                      style={{ width: "100%" }}
                      onClick={previewProfile}
                    >
                      PREVIEW PROFILE
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5.6}>
                    <Button
                      variant="outlined"
                      endIcon={<ArrowForward />}
                      style={{ width: "100%" }}
                      onClick={viewCandidates}
                    >
                      VIEW CANDIDATES
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={[classes.detailmain, classes.paddingmain]}
          >
            <Typography
              className="primarycolor mediumsemiboldfont"
              style={{ marginBottom: 20 }}
            >
              Active jobs
            </Typography>
            <Grid container justifyContent={"space-between"}>
              {isArray(activeJob?.data) && activeJob?.data.length === 0 ? (
                <div>
                  <Typography className="lightsilvercolor regulerlargefont">
                    Post your new job
                  </Typography>
                  <Button
                    variant="contained"
                    endIcon={<ArrowForward />}
                    style={{ width: 200, marginTop: 14 }}
                    onClick={postJob}
                  >
                    POST NEW JOB
                  </Button>
                </div>
              ) : (
                <>
                  {isArray(activeJob?.data) &&
                    activeJob?.data.length > 0 &&
                    activeJob?.data.map((item, index) => {
                      if (index > 4) {
                        return null;
                      }
                      return (
                        <Grid
                          key={`${item?.sector || ""}_${index}`}
                          sm={12}
                          md={5.8}
                          className={[classes.detailmain, classes.paddingmain]}
                        >
                          <Typography
                            className="primarycolor semibold18"
                            style={{ marginBottom: 16 }}
                          >
                            {item?.title || ""}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 16,
                            }}
                          >
                            <div>
                              <Typography className="regulerlargefont lightsilvercolor">
                                Sector
                              </Typography>
                              <Typography className="reguler20 primarycolor">
                                {item?.sector || "-"}
                              </Typography>
                            </div>
                            <div>
                              <Typography className="regulerlargefont lightsilvercolor">
                                Role
                              </Typography>
                              <Typography className="reguler20 primarycolor">
                                {item?.job_role_name || "-"}
                              </Typography>
                            </div>
                          </div>
                          <Button
                            variant="outlined"
                            endIcon={<ArrowForward />}
                            style={{ width: "100%" }}
                          >
                            VIEW JOB
                          </Button>
                        </Grid>
                      );
                    })}
                  {isArray(activeJob?.data) && activeJob?.data.length > 0 && (
                    <Button
                      variant="contained"
                      endIcon={<ArrowForward />}
                      style={{ width: "100%" }}
                      onClick={() => {
                        navigate("/employer/jobs");
                      }}
                    >
                      VIEW ALL JOB POSTS
                    </Button>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    );
  }

  function renderRight() {
    return (
      <Grid item xs={12} sm={4} md={3}>
        <div className={classes.detailmain}>
          <div className={classes.detailfirst}>
            <Typography className="primarycolor mediumlargefont">
              Details
            </Typography>
          </div>
          <div className={classes.detaildivider} />
          <div className={classes.detailsecond}>
            <div className={classes.cDetailSection}>
              <Typography
                className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
              >
                Company size
              </Typography>
              <Typography className={classes.sectionDesc}>
                {companyDetail?.company_size || 0}
              </Typography>
            </div>
            {/* <div className={classes.cDetailSection}>
              <Typography
                className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
              >
                Culture
              </Typography>
              <Typography className={classes.sectionDesc}>Startup</Typography>
            </div> */}
            <div className={classes.cDetailSection}>
              <Typography
                className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
              >
                Industry
              </Typography>
              <Typography className={classes.sectionDesc}>
                {companyDetail?.sector_name || "-"}
              </Typography>
            </div>
            <div className={classes.cDetailSection}>
              <Typography
                className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
              >
                Website
              </Typography>
              <Typography className={classes.sectionDesc}>
                {companyDetail?.company_website || "-"}
              </Typography>
            </div>
            {(companyDetail?.facebook_url ||
              companyDetail?.instagram_url ||
              companyDetail?.linkedin_url ||
              companyDetail?.twitter_url) && (
              <div
                className={classes.cDetailSection}
                style={{ marginBottom: 0 }}
              >
                <Typography
                  className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
                >
                  Follow us
                </Typography>
                <div className={classes.socialContainer}>
                  {companyDetail?.facebook_url && (
                    <div className={classes.socialDiv}>
                      <img
                        alt="img"
                        src={Images.faceBook}
                        className={classes.socialImgStyle}
                      />
                    </div>
                  )}
                  {companyDetail?.instagram_url && (
                    <div className={classes.socialDiv}>
                      <img
                        alt="img"
                        src={Images.insta}
                        className={classes.socialImgStyle}
                      />
                    </div>
                  )}
                  {companyDetail?.linkedin_url && (
                    <div className={classes.socialDiv}>
                      <img
                        alt="img"
                        src={Images.linkedin}
                        className={classes.socialImgStyle}
                      />
                    </div>
                  )}
                  {companyDetail?.twitter_url && (
                    <div className={classes.socialDiv}>
                      <img
                        alt="img"
                        src={Images.twitter}
                        className={classes.socialImgStyle}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* <Typography className="regulerlargefont lightsilvercolor">
              Add information
            </Typography> */}
          </div>
        </div>
        <div className={classes.videoContainer}>
          <Typography className={classes.videoTitle}>Company video</Typography>
          <div className={classes.videoSectionStyle}>
            {videoUrl ? (
              <div>
                <video width="100%" className={classes.videoStyle} controls>
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </div>
            ) : (
              <Typography>No Video</Typography>
            )}
            {(videoLoad || uploadLoader === "video") && (
              <CircularProgress style={{ position: "absolute" }} />
            )}
          </div>
        </div>
      </Grid>
    );
  }

  if (pageLoad) {
    return (
      <Grid container>
        <div className={classes.loaderView}>
          <CircularProgress size={50} />
        </div>
      </Grid>
    );
  }

  function renderMain() {
    return (
      <Box sx={{ flexGrow: 1, paddingTop: 4 }}>
        <Grid container>
          {renderLeft()}
          {renderRight()}
        </Grid>
        <MDetail
          visible={detailModal}
          handleModal={() => {
            setDetailModal(false);
          }}
        />
      </Box>
    );
  }

  return <div>{renderMain()}</div>;
}
