import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import useStyles from "../styles";
import MOverview from "../../Profile/Modal/MOverview";
import { isEmpty } from "lodash";
import Images from "../../../Config/Images";

function DescriptionRole(props) {
  const { setData = () => null, data = {} } = props;
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const [editData, setEditData] = useState({});
  const isComplete = !isEmpty(editData);
  const isDataEmpty = !isEmpty(data);

  useEffect(() => {
    if (isDataEmpty) {
      setEditData(data?.role_description || "");
    }
  }, [data]);

  useEffect(() => {
    setData(editData);
  }, [editData]);

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="descrole" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>
          Description of role
        </Typography>
        {isComplete ? (
          <div
            className={classes.editBtnStyle}
            onClick={() => setOpenModal(true)}
          >
            <img src={Images.edit} style={{ width: 14 }} />
          </div>
        ) : (
          <AddCircle
            className={classes.addIcon}
            onClick={() => {
              setOpenModal(true);
            }}
          />
        )}
      </div>
      {isComplete ? (
        <div
          style={{ marginTop: 10 }}
          dangerouslySetInnerHTML={{
            __html: editData || "",
          }}
        />
      ) : (
        <Typography className={classes.sectionDesc}>Add description</Typography>
      )}
      <MOverview
        visible={openModal}
        handleModal={() => {
          setOpenModal(false);
        }}
        onSave={(data) => {
          setEditData(data);
          setOpenModal(false);
        }}
        overviewDetail={editData}
        description
      />
    </Grid>
  );
}

export default DescriptionRole;
