import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import Slider from "react-slick";
import {
  Grid,
  Box,
  Button,
  Typography,
  Avatar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ArrowForward,
  ArrowDownward,
  KeyboardArrowRight,
  KeyboardArrowLeft,
} from "@mui/icons-material";
import "./styles.css";
import Images from "../../../Config/Images";
import { Setting } from "../../../Utils/Setting";
import CDropDown from "../../../Components/CDropDown";
import { getApiData } from "../../../Utils/APIHelper";
import authActions from "../../../Redux/reducers/auth/actions";
import {
  askForPermissionToReceiveNotifications,
  onMessageListener,
} from "../../../push-notification";

const { setDropDownData, setReview } = authActions;

const errorObj = {
  sectorErr: false,
  jobRoleErr: false,
  sectorMsg: "",
  jobRoleMsg: "",
};

export default function Home() {
  const { dropdowndata } = useSelector((state) => state.auth);
  const [sector, setSector] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [jobRoleArr, setJobRoleArr] = useState([]);

  const [candidateReview, setCandidateReview] = useState([]);
  const [employerReview, setEmployerReview] = useState([]);
  const [errObj, setErrObj] = useState(errorObj);

  const isCandidateEmpty = isEmpty(candidateReview);
  const isEmployerEmpty = isEmpty(employerReview);

  const isOne = employerReview.length === 1;

  const navigate = useNavigate();
  const sliderRef = useRef();
  const firstSliderRef = useRef();
  const firstSliderImgRef = useRef();

  const dispatch = useDispatch();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    document.title = Setting.page_name.CANDIDATE_HOME;
    getDropDownData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    askForPermissionToReceiveNotifications();
    onMessageListener();
  }, []);

  function err(message) {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      progress: undefined,
    });
  }

  function jobRoleData(value) {
    setJobRole("");
    dropdowndata?.sectors.map((ii) => {
      if (ii?.value === value) {
        setJobRoleArr(ii?.job_roles);
      }
    });
  }

  async function getDropDownData() {
    try {
      let endPoint = `${Setting.endpoints.dropdown}`;
      const response = await getApiData(endPoint, "GET", null);
      if (response && response.status && response.status === true) {
        dispatch(setDropDownData(response?.data));
        getReviewData();
      } else {
        console.log("err==>>");
        err(response?.message);
      }
    } catch (err) {
      console.log("Catch Part", err);
      err("Something_went_wrong");
    }
  }

  async function getReviewData() {
    try {
      let endPoint = `${Setting.endpoints.userReviewList}`;
      const response = await getApiData(endPoint, "GET", null);
      if (response && response.status && response.status === true) {
        dispatch(setReview(response?.data));
        setCandidateReview(response?.data?.user_review);
        setEmployerReview(response?.data?.employer_review);
      } else {
        console.log("err==>>");
        err(response?.message);
      }
    } catch (err) {
      console.log("Catch Part", err);
      err("Something_went_wrong");
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(sector.toString())) {
      valid = false;
      error.sectorErr = true;
      error.sectorMsg = "Please select sector";
    } else if (isEmpty(jobRole.toString())) {
      valid = false;
      error.jobRoleErr = true;
      error.jobRoleMsg = "Please select jobRole";
    }

    setErrObj(error);
    if (valid) {
      navigate("/candidate/signup", {
        state: { sector: sector, jobRole: jobRole },
      });
    }
  }

  const setting = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: isOne ? 1 : 2,
    slidesToScroll: 1,
    swipe: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
          dots: false,
          arrows: false,
          swipe: false,
        },
      },
    ],
  };

  const firstSetting = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    arrows: false,
  };

  function renderFirst() {
    const isSector = isEmpty(sector.toString());
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={6}
            direction="column"
            justifyContent="center"
          >
            <Typography className="fontsize primarycolor">
              Find your next job, fast.
            </Typography>
            <Typography className="primarycolor" style={{ marginBottom: 16 }}>
              Match Candidate or Employer by matching skills, salaries etc...
            </Typography>
            <CDropDown
              data={dropdowndata?.sectors}
              value={sector}
              error={errObj.sectorErr}
              helperText={errObj.sectorErr ? errObj.sectorMsg : null}
              onChange={(v) => {
                setSector(v.target.value);
                jobRoleData(v.target.value);
                setErrObj({ ...errObj, sectorErr: false, sectorMsg: "" });
              }}
              placeholder="Select sector"
              label="Select the sector"
              style={{ maxWidth: sm ? "unset" : 300 }}
            />
            <CDropDown
              data={jobRoleArr}
              value={jobRole}
              error={errObj.jobRoleErr}
              helperText={errObj.jobRoleErr ? errObj.jobRoleMsg : null}
              disable={isSector}
              onChange={(v) => {
                setJobRole(v.target.value);
                setErrObj({ ...errObj, jobRoleErr: false, jobRoleMsg: "" });
              }}
              placeholder="Select job"
              label="Select the job role"
              style={{ maxWidth: sm ? "unset" : 300 }}
            />

            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForward />}
              style={{ width: sm ? "unset" : 300, marginTop: 10 }}
              onClick={() => {
                checkValidation();
              }}
            >
              Next step
            </Button>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={6}
            alignItems="center"
            justifyContent="center"
          >
            {sm ? null : (
              <img
                alt="Error"
                src={Images?.heroimg}
                style={{ height: "100%", width: "100%" }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderSecond() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: sm ? 5 : 1 }}>
        <Grid
          container
          style={{
            padding: 20,
            backgroundColor: "#f5f9ff",
            borderRadius: 8,
            paddingBottom: 30,
          }}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              How our service works
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 30 }}
          >
            <Grid item xs={12} sm={3.6}>
              <div className="secondGrid">
                <Typography
                  style={{
                    marginBottom: 6,
                  }}
                  className="primarycolor mediumsemiboldfont"
                >
                  Create a job for free
                </Typography>
                <Typography className="mediumfont lightsilvercolor">
                  Tell us what you need
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={0.5}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {sm ? (
                  <ArrowDownward
                    style={{ color: "#D6AC85", marginTop: 4, marginBottom: 4 }}
                  />
                ) : (
                  <ArrowForward style={{ color: "#D6AC85" }} />
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={3.6}>
              <div className="secondGrid">
                <Typography
                  style={{ marginBottom: 6 }}
                  className="primarycolor mediumsemiboldfont"
                >
                  Get a quote
                </Typography>
                <Typography className="mediumfont lightsilvercolor">
                  Tradespeople get in touch
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={0.5} justifyContent="center">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {sm ? (
                  <ArrowDownward
                    style={{ color: "#D6AC85", marginTop: 4, marginBottom: 4 }}
                  />
                ) : (
                  <ArrowForward style={{ color: "#D6AC85" }} />
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={3.6}>
              <div className="secondGrid">
                <Typography
                  style={{ marginBottom: 6 }}
                  className="primarycolor mediumsemiboldfont"
                >
                  Rate and review
                </Typography>
                <Typography className="mediumfont lightsilvercolor">
                  Job done - leave feedback
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderThird() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10 }}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: 20 }}
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              What candidates says about us
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={7.5}>
              <Slider ref={firstSliderImgRef} {...firstSetting}>
                {candidateReview.map((ii, index) => {
                  return (
                    <>
                      <div key={index} style={{ display: "flex" }}>
                        <img
                          alt="Error"
                          src={ii?.photo}
                          className="sliderimg"
                        />
                      </div>
                    </>
                  );
                })}
              </Slider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4.5}
              style={{ position: "relative", marginTop: sm ? 20 : 0 }}
            >
              <div className="maindiv">
                {sm ? null : <div className="divdesign" />}
                <div className="remaindiv">
                  <div className="arrowmaindiv">
                    <div
                      className="arrowdiv"
                      onClick={() => {
                        firstSliderRef.current.slickPrev();
                        firstSliderImgRef.current.slickPrev();
                      }}
                    >
                      <KeyboardArrowLeft
                        style={{ color: "#fff", fontSize: sm ? 30 : 40 }}
                      />
                      <div
                        className="arrowdiv arrowdiv2"
                        onClick={() => {
                          firstSliderRef.current.slickNext();
                          firstSliderImgRef.current.slickNext();
                        }}
                      >
                        <KeyboardArrowRight
                          style={{ color: "#fff", fontSize: sm ? 30 : 40 }}
                        />
                      </div>
                    </div>
                  </div>
                  <Slider ref={firstSliderRef} {...firstSetting}>
                    {candidateReview.map((ii, index) => {
                      return (
                        <>
                          <div key={index} className="messsagefromclien">
                            <div className="messageheder">
                              <Typography className="primarycolor mediumlargefont">
                                {ii.name}
                              </Typography>
                              <div className="underline" />
                            </div>
                            <div
                              className="sliderscroll"
                              style={{
                                margin: 20,
                                maxHeight: md ? 240 : 320,
                              }}
                            >
                              <Typography className="regulerfont">
                                {ii.description}
                              </Typography>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderFourth() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: sm ? 10 : 20 }}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ padding: 20 }}
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              3 steps to start your work adventure
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              direction="column"
              justifyContent="center"
            >
              <Typography
                className="primarycolor fontsize2"
                style={{ marginBottom: 6 }}
              >
                <span className="shadow">1.</span> Create profile
              </Typography>
              <Typography
                className="lightsilvercolor"
                style={{ lineHeight: 2 }}
              >
                You are more than your CV and Tempo helps you show exactly that.
                Add or upload your experience alongside key skills and what you
                are looking for. Show employers what you’re all about by
                recording a short intro video – this makes you 7x more likely to
                get hired.
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              alignItems="center"
              justifyContent="center"
            >
              <img
                alt="Error"
                src={Images?.step1}
                style={{ height: "100%", width: "100%" }}
              />
            </Grid>
            <Grid container xs={12} direction="row-reverse">
              <Grid
                container
                xs={12}
                sm={6}
                md={6}
                direction="column"
                justifyContent="center"
              >
                <Typography
                  className="primarycolor fontsize2"
                  style={{ marginBottom: 6 }}
                >
                  <span className="shadow">2.</span> Get matched
                </Typography>
                <Typography
                  className="lightsilvercolor"
                  style={{ lineHeight: 2 }}
                >
                  This is where the magic happens. Once your profile is
                  complete, you will instantly be matched to relevant jobs and
                  can also be discovered by companies that are looking for
                  people like you. Simply hit the ‘I’m interested’ button to
                  apply to a role, and message companies directly to truly stand
                  out from the crowd.
                </Typography>
              </Grid>
              <Grid
                container
                xs={12}
                sm={6}
                md={6}
                alignItems="center"
                justifyContent="center"
              >
                <img
                  alt="Error"
                  src={Images?.step2}
                  style={{ height: "100%", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              direction="column"
              justifyContent="center"
            >
              <Typography
                className="primarycolor fontsize2"
                style={{ marginBottom: 6 }}
              >
                <span className="shadow">3.</span> Get hired
              </Typography>
              <Typography
                className="lightsilvercolor"
                style={{ lineHeight: 2 }}
              >
                Companies can message, invite you to interviews and make offers
                all within the Tempo platform. Most companies make their hire
                within just one week and you will always receive feedback after
                interviews. With everything in one place, it’s never been easier
                to find your next work adventure.
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              alignItems="center"
              justifyContent="center"
            >
              <img
                alt="Error"
                src={Images?.step3}
                style={{ height: "100%", width: "100%" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderFifth() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10, marginBottom: 10 }}>
        <Grid
          container
          style={{ padding: 20, backgroundColor: "#f5f9ff", borderRadius: 8 }}
        >
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            style={{ marginBottom: 20 }}
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              Words from our employers
            </Typography>
          </Grid>
          <Grid container xs={12} alignItems="center" justifyContent="center">
            <Grid item xs={1}>
              <Button
                variant="outlined"
                className="buttun"
                onClick={() => {
                  sliderRef.current.slickPrev();
                }}
              >
                <KeyboardArrowLeft style={{ fontSize: 20 }} />
              </Button>
            </Grid>
            <Grid item xs={10}>
              <Slider ref={sliderRef} {...setting} className="carousal">
                {employerReview.map((ii, index) => {
                  return (
                    <Grid item container xs={12} key={index}>
                      <div className="slider12">
                        <div className="msg-icn">
                          <Typography
                            className="lightsilvercolor regulersmallfont"
                            style={{ marginBottom: 16 }}
                          >
                            {ii?.description}
                          </Typography>
                          <Typography className="primarycolor smallsemiboldfont">
                            {ii?.name}
                          </Typography>
                        </div>
                      </div>
                      <div className="profilecmt">
                        <Avatar
                          className="avatarUI"
                          alt="error"
                          src={ii.photo}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Slider>
            </Grid>
            <Grid item xs={1} justifyContent="flex-end">
              <div
                style={{
                  display: sm ? "unset" : "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  className="buttun"
                  onClick={() => {
                    sliderRef.current.slickNext();
                  }}
                >
                  <KeyboardArrowRight style={{ fontSize: 20 }} />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderSixth() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10, marginBottom: 5 }}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            style={{ marginBottom: 26 }}
          >
            <Typography className="fontsize" style={{ textAlign: "center" }}>
              Get our PWA app on Android and iPhone and share on
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="iconstyle">
                <img
                  alt="Error"
                  src={Images?.install}
                  style={{ height: "60%", width: "60%" }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="iconstyle">
                <img
                  alt="Error"
                  src={Images?.faceBook}
                  style={{ height: "60%", width: "60%" }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="iconstyle">
                <img
                  alt="Error"
                  src={Images?.insta}
                  style={{ height: "60%", width: "60%" }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="iconstyle">
                <img
                  alt="Error"
                  src={Images?.linkedin}
                  style={{ height: "60%", width: "60%" }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="iconstyle">
                <img
                  alt="Error"
                  src={Images?.twitter}
                  style={{ height: "60%", width: "60%" }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <div>
      {renderFirst()}
      {renderSecond()}
      {isCandidateEmpty ? null : renderThird()}
      {renderFourth()}
      {isEmployerEmpty ? null : renderFifth()}
      {renderSixth()}
    </div>
  );
}
