import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
// import { AddCircle } from "@mui/icons-material";
import moment from "moment";
import MBio from "../Modal/MBio";
// import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import useStyles from "../styles";

function Bio(props) {
  const { from = "", data = {}, updateProfileState = () => null } = props;
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const detail = data?.user_bio || {};

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="bio" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>Bio</Typography>
        {/* <AddCircle
          color={color.primary}
          className={classes.addIcon}
          onClick={() => setModal(true)}
        /> */}
        {from === "employee" ? null : (
          <div className={classes.editBtnStyle} onClick={() => setModal(true)}>
            <img src={Images.edit} style={{ width: 14 }} />
          </div>
        )}
      </div>
      {from === "employee" ? null : (
        <Typography className={classes.sectionDesc}>
          Add your personal details
        </Typography>
      )}
      <div>
        <div
          className={`${classes.contentDetailContainer} ${classes.contentDiv}`}
        >
          <div className={classes.singleDetailDivStyle}>
            <Typography className={classes.contentTitleStyle}>
              Date of Birth
            </Typography>
            <Typography className={classes.sectionDesc}>
              {detail?.birth_date
                ? moment(detail?.birth_date).format("DD MMMM YYYY")
                : "-"}
            </Typography>
          </div>
          <div className={classes.singleDetailDivStyle}>
            <Typography className={classes.contentTitleStyle}>
              Permanent address
            </Typography>
            <Typography className={classes.sectionDesc}>
              {detail?.permanent_address || "-"}
            </Typography>
          </div>
        </div>
        <div
          className={`${classes.contentDetailContainer} ${classes.contentDiv}`}
        >
          <div className={classes.singleDetailDivStyle}>
            <Typography className={classes.contentTitleStyle}>
              Gender
            </Typography>
            <Typography
              className={classes.sectionDesc}
              style={{ textTransform: "capitalize" }}
            >
              {detail?.gender || "-"}
            </Typography>
          </div>
          <div className={classes.singleDetailDivStyle}>
            <Typography className={classes.contentTitleStyle}>
              Hometown
            </Typography>
            <Typography className={classes.sectionDesc}>
              {detail?.hometown || "-"}
            </Typography>
          </div>
        </div>
        <div
          className={classes.contentDetailContainer}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Typography className={classes.contentTitleStyle}>
              Description
            </Typography>
            <Typography className={classes.sectionDesc}>
              {detail?.bio_description || "-"}
            </Typography>
          </div>
        </div>
      </div>
      <MBio
        visible={modal}
        handleModal={(detail, type) => {
          if (type === "update" && updateProfileState) {
            updateProfileState(detail, "user_bio");
          }
          setModal(false);
        }}
        data={detail}
      />
    </Grid>
  );
}
export default Bio;
