import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  Checkbox,
  OutlinedInput,
  Grid,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import useStyles from "../../styles";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import Images from "../../../../Config/Images";
import CDropDown from "../../../CDropDown";
import { isDate, isEmpty } from "lodash";
import moment from "moment";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { Setting } from "../../../../Utils/Setting";

const errorObj = {
  jobErr: false,
  perYearErr: false,
  perHourErr: false,
  bothYearErr: false,
  bothHourErr: false,
  descriptionErr: false,
  startDateErr: false,
  startTimeErr: false,
  endDateErr: false,
  endTimeErr: false,
  locationErr: false,
  jobMsg: "",
  perYearMsg: "",
  perHourMsg: "",
  bothYearMsg: "",
  bothHourMsg: "",
  descriptionMsg: "",
  startDateMsg: "",
  startTimeMsg: "",
  endTimeMsg: "",
  endDateMsg: "",
  locationMsg: "",
};

function MOffers(props) {
  const {
    visible = false,
    handleModal = () => null,
    type = "",
    employerData = {},
    apiSussecc = () => null,
  } = props;
  const { dropdowndata } = useSelector((state) => state.auth);
  const [jobRole, setJobRole] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const [errObj, setErrObj] = useState(errorObj);
  const [btnLoader, setBtnLoader] = useState(false);

  const isEdit = !isEmpty(employerData);

  const [jobType, setJobType] = useState("");
  const [description, setDescription] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [startDatePicker, setStartDatePicker] = useState(false);

  const [endDate, setEndDate] = useState(null);
  const [endDatePicker, setEndDatePicker] = useState(false);

  const [startTime, setStartTime] = useState(null);
  const [startTimePicker, setStartTimePicker] = useState(false);

  const [endTime, setEndTime] = useState(null);
  const [endTimePicker, setEndTimePicker] = useState(false);

  const [postCode, setPostCode] = useState("");
  const [remoteOnly, setRemoteOnly] = useState(false);
  const [otherNote, setOtherNote] = useState("");

  const arr = [
    { value: 1, label: "Full-Time" },
    { value: 2, label: "Part-Time" },
    { value: 3, label: "Both" },
  ];

  const [basis, setBasis] = useState(1);
  const [perYear, setPerYear] = useState("");
  const [perHour, setPerHour] = useState("");

  const [bothPerYear, setBothPerYear] = useState("");
  const [bothPerHour, setBothPerHour] = useState("");

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: 700,
  };
  useEffect(() => {
    if (visible) {
      setJobType(employerData?.job_role_id ? employerData?.job_role_id : "");
      if (employerData?.job_role_type === "Full-Time") {
        setBasis(1);
      } else if (employerData?.job_role_type === "Part-Time") {
        setBasis(2);
      } else {
        setBasis(3);
      }
      setPerYear(employerData?.charge_per_year || "");
      setPerHour(employerData?.charge_per_hour || "");
      setBothPerYear(employerData?.charge_per_year || "");
      setBothPerHour(employerData?.charge_per_hour || "");
      setDescription(employerData?.description || "");
      setStartDate(new Date(employerData?.start_date) || null);
      setStartTime(new Date(`01-02-2022 ${employerData?.start_time}`) || null);
      setEndDate(new Date(employerData?.expired_date) || null);
      setEndTime(new Date(`01-02-2022 ${employerData?.end_time}`) || null);
      setPostCode(employerData?.location || "");
      setRemoteOnly(employerData?.is_remote === 1 ? true : false);
      setOtherNote(employerData?.note || "");

      //function call to set job role value
      jobRoleData();
    }
    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  function jobRoleData() {
    dropdowndata?.sectors.map((ii) => {
      if (ii?.value === employerData.sector_id) {
        setJobRole(ii?.job_roles);
      }
    });
  }

  function resetFormData(type = "") {
    setBasis(1);
    setPerYear("");
    setPerHour("");
    setBothPerYear("");
    setBothPerHour("");
    setJobType("");
    setDescription("");
    setStartDate(null);
    setStartDatePicker(false);
    setEndDate(null);
    setEndDatePicker(false);
    setStartTime(null);
    setEndTime(null);
    setPostCode("");
    setRemoteOnly("");
    setOtherNote("");
    setErrObj(errorObj);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(jobType.toString())) {
      valid = false;
      error.jobErr = true;
      error.jobMsg = "Please select Job Role";
    }
    if (basis === 1 && isEmpty(perYear)) {
      valid = false;
      error.perYearErr = true;
      error.perYearMsg = "Please enter yearly salary";
    }
    if (basis === 2 && isEmpty(perHour)) {
      valid = false;
      error.perHourErr = true;
      error.perHourMsg = "Please enter yearly salary";
    }
    if ((basis === 3 && isEmpty(bothPerYear)) || isEmpty(bothPerHour)) {
      if (isEmpty(bothPerYear)) {
        valid = false;
        error.bothYearErr = true;
        error.bothYearMsg = "Please enter yearly salary";
      } else if (isEmpty(bothPerHour)) {
        valid = false;
        error.bothHourErr = true;
        error.bothHourMsg = "Please enter yearly salary";
      }
    }
    if (isEmpty(description)) {
      valid = false;
      error.descriptionErr = true;
      error.descriptionMsg = "Please enter Description";
    }
    if (!isDate(startDate)) {
      valid = false;
      error.startDateErr = true;
      error.startDateMsg = "Please enter Start Date";
    }
    if (!isDate(endDate)) {
      valid = false;
      error.jobCloseDateErr = true;
      error.jobCloseDateMsg = "Please enter End Date";
    }
    if (!isDate(startTime)) {
      valid = false;
      error.startTimeErr = true;
      error.startTimeMsg = "Please Select Start Time";
    }
    if (!isDate(endTime)) {
      valid = false;
      error.endTimeErr = true;
      error.endTimeMsg = "Please Select End Time";
    }
    if (!remoteOnly && isEmpty(postCode)) {
      valid = false;
      error.locationErr = true;
      error.locationMsg = "Please Enter Location";
    }

    setErrObj(error);

    if (valid) {
      MakeOffer();
    }
  }

  // Make Offer API
  async function MakeOffer() {
    setBtnLoader(true);
    const data = {
      "JobOffer[job_post_id]": employerData?.job_post_id,
      "JobOffer[candidate_id]": employerData?.user_info?.candidate_id,
      "JobOffer[job_role_id]": jobType,
      "JobOffer[job_role_type]":
        basis === 1 ? "Full-Time" : basis === 2 ? "Part-Time" : "Both",
      "JobOffer[start_date]": moment(startDate).format("yyyy-MM-DD"),
      "JobOffer[end_date]": moment(endDate).format("yyyy-MM-DD"),
      "JobOffer[start_time]": moment(startTime).format("hh:mm A"),
      "JobOffer[end_time]": moment(endTime).format("hh:mm A"),
      "JobOffer[is_remote]": remoteOnly === true ? 1 : 0,
      "JobOffer[note]": otherNote,
      "JobOffer[description]": description,
    };

    if (basis === 1) {
      data["JobOffer[charge_per_year]"] = perYear;
    } else if (basis === 2) {
      data["JobOffer[charge_per_hour]"] = perHour;
    } else if (basis === 3) {
      data["JobOffer[charge_per_year]"] = bothPerYear;
      data["JobOffer[charge_per_hour]"] = bothPerHour;
    }

    if (isEdit) {
      data["JobOffer[id]"] = employerData?.id;
    }
    if (!remoteOnly) {
      data["JobOffer[location]"] = postCode || "";
    }
    console.log("set data =>>>>>>>>>>>>>.", data);
    try {
      const response = await getAPIProgressData(
        `${Setting.endpoints.employerMakeOffer}`,
        "POST",
        data,
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        apiSussecc();
        toast.success(response.message);
        setBtnLoader(false);
      }
    } catch (error) {
      console.log("error ===>>", error);
      setBtnLoader(false);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography variant="subtitle1" component="h2">
              {type === "edit" ? "Edit offers" : "Make offers"}
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className="sliderscroll" style={{ height: 500 }}>
            <CDropDown
              data={jobRole}
              value={jobType}
              error={errObj.jobErr}
              helperText={errObj.jobErr ? errObj.jobMsg : null}
              onChange={(v) => {
                setJobType(v.target.value);
                setErrObj({ ...errObj, jobErr: false, jobMsg: "" });
              }}
              placeholder="Select job type"
              label="Select job type"
              style={{ width: "100%" }}
            />
            <Grid container>
              <Grid xs={12}>
                <CDropDown
                  data={arr}
                  value={basis}
                  onChange={(v) => {
                    setBasis(v.target.value);
                  }}
                  placeholder="Select sector"
                  label="Basis"
                  style={{ width: "100%" }}
                />
              </Grid>
              {basis === 3 ? (
                <Grid
                  xs={12}
                  style={{ marginTop: sm ? 0 : 7, marginBottom: 10 }}
                >
                  <TextField
                    style={{ marginBottom: 10 }}
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={bothPerYear}
                    error={errObj.bothYearErr}
                    helperText={errObj.bothYearErr ? errObj.bothYearMsg : null}
                    onChange={(e) => {
                      setBothPerYear(e.target.value);
                      setErrObj({
                        ...errObj,
                        bothYearErr: false,
                        bothYearMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per year</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={bothPerHour}
                    error={errObj.bothHourErr}
                    helperText={errObj.bothHourErr ? errObj.bothHourMsg : null}
                    onChange={(e) => {
                      setBothPerHour(e.target.value);
                      setErrObj({
                        ...errObj,
                        bothHourErr: false,
                        bothHourMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per hour</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ) : (
                <Grid xs={12} style={{ marginTop: 7, marginBottom: 10 }}>
                  {basis === 1 ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Salary"
                      value={perYear}
                      error={errObj.perYearErr}
                      helperText={errObj.perYearErr ? errObj.perYearMsg : null}
                      onChange={(e) => {
                        setPerYear(e.target.value);
                        setErrObj({
                          ...errObj,
                          perYearErr: false,
                          perYearMsg: "",
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <div className="inputside">per year</div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : basis === 2 ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Salary"
                      value={perHour}
                      error={errObj.perHourErr}
                      helperText={errObj.perHourErr ? errObj.perHourMsg : null}
                      onChange={(e) => {
                        setPerHour(e.target.value);
                        setErrObj({
                          ...errObj,
                          perHourErr: false,
                          perHourMsg: "",
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <div className="inputside">per hour</div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : null}
                </Grid>
              )}
            </Grid>
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Description (240 character)
            </Typography>
            <TextField
              placeholder="Description"
              value={description}
              error={errObj.descriptionErr}
              helperText={errObj.descriptionErr ? errObj.descriptionMsg : null}
              onChange={(v) => {
                setDescription(v.target.value);
                setErrObj({
                  ...errObj,
                  descriptionErr: false,
                  descriptionMsg: "",
                });
              }}
              multiline
              rows={4}
              style={{ marginBottom: 10, width: "100%" }}
            />
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Select start date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                  setStartDatePicker(false);
                  setErrObj({
                    ...errObj,
                    startDateErr: false,
                    startDateMsg: "",
                  });
                }}
                open={startDatePicker}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    // className={classes.inputFieldStyle}
                    {...params}
                    error={errObj.startDateErr}
                    helperText={
                      errObj.startDateErr ? errObj.startDateMsg : null
                    }
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "Select start date",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            className="datepicker pickerStyle"
                            onClick={() => {
                              setStartDatePicker(!startDatePicker);
                            }}
                          >
                            <img
                              alt="error"
                              src={Images.picker}
                              style={{ width: 24 }}
                            />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Select end date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                  setEndDatePicker(false);
                  setErrObj({
                    ...errObj,
                    endDateErr: false,
                    endDateMsg: "",
                  });
                }}
                minDate={startDate}
                open={endDatePicker}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    // className={classes.inputFieldStyle}
                    {...params}
                    error={errObj.endDateErr}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "Select end date",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            className="datepicker pickerStyle"
                            onClick={() => {
                              setEndDatePicker(!endDatePicker);
                            }}
                          >
                            <img
                              alt="error"
                              src={Images.picker}
                              style={{ width: 24 }}
                            />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Select start time
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                value={startTime}
                onChange={(newValue) => {
                  setStartTime(newValue);
                }}
                onAccept={(val) => {
                  setStartTimePicker(false);
                  setErrObj({
                    ...errObj,
                    startTimeErr: false,
                    startTimeMsg: "",
                  });
                }}
                open={startTimePicker}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    // className={classes.inputFieldStyle}
                    {...params}
                    error={errObj.startTimeErr}
                    helperText={
                      errObj.startTimeErr ? errObj.startTimeMsg : null
                    }
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "Select start time",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            className="datepicker pickerStyle"
                            onClick={() => {
                              setStartTimePicker(!startTimePicker);
                            }}
                          >
                            <img
                              alt="error"
                              src={Images.timer}
                              style={{ width: 24 }}
                            />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Select end time
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                value={endTime}
                onChange={(newValue) => {
                  setEndTime(newValue);
                }}
                onAccept={(val) => {
                  setEndTimePicker(false);
                  setErrObj({
                    ...errObj,
                    endTimeErr: false,
                    endTimeMsg: "",
                  });
                }}
                minTime={startTime}
                open={endTimePicker}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    // className={classes.inputFieldStyle}
                    {...params}
                    error={errObj.endTimeErr}
                    helperText={errObj.endTimeErr ? errObj.endTimeMsg : null}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "Select end time",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            className="datepicker pickerStyle"
                            onClick={() => {
                              setEndTimePicker(!endTimePicker);
                            }}
                          >
                            <img
                              alt="error"
                              src={Images.timer}
                              style={{ width: 24 }}
                            />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Select postcode or location
            </Typography>
            <TextField
              placeholder="Enter postcode or loaction"
              value={postCode}
              error={errObj.locationErr}
              helperText={errObj.locationErr ? errObj.locationMsg : null}
              onChange={(v) => {
                setPostCode(v.target.value);
                setErrObj({
                  ...errObj,
                  locationErr: false,
                  locationMsg: "",
                });
              }}
              disabled={remoteOnly}
              style={{ marginBottom: 10, width: "100%" }}
            />
            <div style={{ display: "flex" }}>
              <Checkbox
                checked={remoteOnly}
                style={{ marginRight: 8, marginLeft: -9 }}
                onChange={() => {
                  setRemoteOnly(!remoteOnly);
                }}
              />
              <Typography
                className="lightsilvercolor mediumfont"
                style={{ marginBottom: 8, marginTop: 8 }}
              >
                Remote only
              </Typography>
            </div>
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Other notes (240 character)
            </Typography>
            <OutlinedInput
              placeholder="Other note"
              value={otherNote}
              onChange={(v) => {
                setOtherNote(v.target.value);
              }}
              multiline
              rows={4}
              style={{ marginBottom: 10, width: "100%" }}
            />
          </div>
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid xs={5.8} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                startIcon={<ArrowBack />}
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8}>
              <Button
                variant="contained"
                endIcon={btnLoader ? null : <ArrowForward />}
                style={{ width: "100%" }}
                onClick={() => {
                  checkValidation();
                }}
              >
                {btnLoader ? (
                  <CircularProgress style={{ color: "#fff" }} size={26} />
                ) : (
                  "MAKE OFFER"
                )}
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MOffers;
